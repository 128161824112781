export class AccountUserRolesModel {
  public management = false;
  public delivery = false;
  public picking = false;
  public shipping = false;

  constructor(roles: string | null) {
    if (!roles) {
      return;
    }
    if (roles.includes('Management')) this.management = true;
    if (roles.includes('Delivery')) this.delivery = true;
    if (roles.includes('Picking')) this.picking = true;
    if (roles.includes('Shipping')) this.shipping = true;
  }

  public getRoles(): string {
    const roles = new Array<string>();
    if (this.management) roles.push('Management');
    if (this.delivery) roles.push('Delivery');
    if (this.picking) roles.push('Picking');
    if (this.shipping) roles.push('Shipping');

    return roles.join(', ');
  }
}
