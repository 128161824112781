/**
 * Dodaje kolejne wyrażenia typu AND do filtra dla filtrowania danych
 * @param dxFilter
 * @param dxCondition
 */
export function dxFilterAddCondition(
  dxFilter: Array<any>,
  dxCondition: Array<any>
) {
  if (dxFilter.length !== 0) {
    dxFilter.push('and');
  }
  dxFilter.push(dxCondition);
}

/**
 * Dodaje kolejne wyrażenia typu OR do filtra dla filtrowania danych
 * @param dxFilter
 * @param dxCondition
 */

export function dxFilterOrCondition(
  dxFilter: Array<any>,
  dxCondition: Array<any>
) {
  if (dxFilter.length !== 0) {
    dxFilter.push('or');
  }
  dxFilter.push(dxCondition);
}

/**
 * Zwraca null jeżeli dxFilter jest pusty (konieczne dla odata)
 * @param dxFilter
 */
export function dxFilterPrepare(dxFilter: Array<any>) {
  if (dxFilter.length === 0) {
    return null;
  } else {
    return dxFilter;
  }
}
