<xlib-data-grid-wrapper
  #dataGridWrapper
  [saveLayout]="saveLayout"
  [viewMode]="viewMode"
>
  <dx-data-grid
    [dataSource]="dataSource"
    [showBorders]="true"
    [columnAutoWidth]="true"
    keyExpr="id"
  >
    <dxi-column
      dataField="getToProcessDate"
      dataType="datetime"
      caption="{{ 'start' | translate }}"
    ></dxi-column>
    <dxi-column
      dataField="finishedDate"
      dataType="datetime"
      caption="{{ 'finish' | translate }}"
    ></dxi-column>
    <dxi-column
      dataField="actionFrom"
      dataType="string"
      caption="{{ 'from' | translate }}"
      [customizeText]="customizeTextTranslate"
    ></dxi-column>
    <dxi-column
      dataField="actionTo"
      dataType="string"
      caption="{{ 'to' | translate }}"
      [customizeText]="customizeTextTranslate"
    ></dxi-column>

    <dxi-column
      dataField="productQuantity"
      dataType="number"
      caption="{{ 'quantity' | translate }}"
    ></dxi-column>
    <dxi-column
      dataField="productExpirationDate"
      dataType="date"
      caption="{{ 'expiration date' | translate }}"
    ></dxi-column>
    <dxi-column
      dataField="productSerialNumber"
      dataType="string"
      caption="{{ 'serial number' | translate }}"
    ></dxi-column>
    <dxi-column
      dataField="taskId"
      dataType="string"
      caption="{{ 'task id' | translate }}"
    ></dxi-column> </dx-data-grid
></xlib-data-grid-wrapper>
