import { FallbackItemModel } from './fallback-item.model';

export enum FallbackDocumentType {
  Delivery = 0,
  Shipping = 1,
}

export interface FallbackDocumentModel {
  type: FallbackDocumentType;
  description?: string;
  items: Array<FallbackItemModel>;
}
