<div class="container">
  <div class="login-page">
    <form autocomplete="off" (ngSubmit)="login()" [formGroup]="this.form">
      <input autocomplete="off" type="hidden" />
      <div class="login-form">
        <div class="title">
          {{ "ucanlo" | translate }}
        </div>
        <div class="form-item">
          <input
            #textEditUserName
            [ngClass]="{ invalid: form.controls.login?.invalid && afterSubmit }"
            class="form-control"
            formControlName="login"
            placeholder="{{ 'email login' | translate }}"
            type="text"
          />
        </div>

        <div class="form-item password">
          <input
            #inputPassword
            [ngClass]="{ invalid: form.controls.pass?.invalid && afterSubmit }"
            class="form-control password"
            formControlName="pass"
            placeholder="{{ 'password' | translate }}"
            type="password"
          />
          <div
            (mousedown)="inputPassword.type = 'text'"
            (touchstart)="inputPassword.type = 'text'"
            class="show-password"
          >
            <fa-icon [icon]="ico" size="2x"></fa-icon>
          </div>
        </div>
        <button #submitButton [disabled]="loginInProgress" type="submit">
          {{ "log in" | translate }}
        </button>
        <div [ngClass]="{ problem: loginProblem }" class="login-info">
          {{ loginInfo }}
        </div>
      </div>
    </form>
  </div>

  <div class="links">
    <a href="#">{{ "forgot password" | translate }}</a>
    <a href="#">{{ "create account" | translate }}</a>
    <a class="url" href="#">Ucanlo.com</a>
  </div>
</div>
