import { CONFIG } from 'projects/xlibs/src/config';
import { HttpClient } from '@angular/common/http';
import { LocatorService } from 'projects/xlibs/src/injectors/locator.service';

export abstract class SimpleBaseRepository {
  protected constructor(endpointName: string) {
    this.endpointName = endpointName;
    this.http = LocatorService.injector.get(HttpClient);
  }

  http: HttpClient;
  endpointName: string;

  get apiUrl(): string {
    return CONFIG.API_URL;
  }
  get endpointUrl(): string {
    return `${this.apiUrl}/${this.endpointName}`;
  }

  public methodUrl(methodName: string | null = null) {
    if (methodName) {
      return `${this.endpointUrl}/${methodName}`;
    } else {
      return this.endpointUrl;
    }
  }
}
