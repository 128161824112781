import {
  AfterContentInit,
  Component,
  HostListener,
  OnDestroy,
  OnInit,
  QueryList,
  Renderer2,
  ViewChild,
} from '@angular/core';

import { Subject, Subscription, timer } from 'rxjs';

import { PageItem } from 'projects/xlibs/src/gui/page-manager/page-item';
import {
  ContextMenuActionType,
  ContextMenuOptionModel,
} from '../../../../core/models/context-menu-model';
import { DxTabPanelComponent } from 'devextreme-angular';
import { TranslateService } from '@ngx-translate/core';
import { ConfigService } from '../../../../_config/config.service';
import { htmlTagRemove } from 'projects/xlibs/src/helpers/html-tags-helper';
import { PageManagerService } from 'projects/xlibs/src/gui/page-manager/page-manager.service';
import { TabPageWrapperComponent } from '../tab-page-wrapper/tab-page-wrapper.component';
import { ResizedEvent } from 'angular-resize-event';
import { debounce, throttleTime } from 'rxjs/operators';
import {
  faXmark,
  faAsterisk,
  faOutdent,
  faClone,
} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-tab',
  templateUrl: './tab.component.html',
  styleUrls: ['./tab.component.scss'],
})
export class TabComponent implements OnInit, OnDestroy, AfterContentInit {
  selectedIndex: number = 0;
  @ViewChild('dxTabPanel') dxTabPanel: DxTabPanelComponent | null = null;
  @ViewChild('appTabPageWrapperContainer')
  appTabPageWrapperContainer: TabPageWrapperComponent | null = null;

  contextMenu: ContextMenuOptionModel[] = [
    {
      text: this.translate.instant('close page'),
      faIcon: faXmark,
      action: ContextMenuActionType.Close,
    },
    {
      text: this.translate.instant('close all pages'),
      faIcon: faAsterisk,
      action: ContextMenuActionType.CloseAll,
    },
    {
      text: this.translate.instant('close other pages'),
      faIcon: faOutdent,
      action: ContextMenuActionType.CloseOthers,
    },
    {
      text: this.translate.instant('duplicate page'),
      faIcon: faClone,
      action: ContextMenuActionType.Duplicate,
    },
  ];
  subscriptionOnChangePageIndex?: Subscription;

  constructor(
    public pageManagerService: PageManagerService,
    private translate: TranslateService,
    private config: ConfigService,
    private renderer: Renderer2
  ) {}

  tabPanelSelectionChanged() {
    this.pageManagerService.selectedPageIndex = this.selectedIndex;
  }

  ngOnInit(): void {
    this.subscriptionOnChangePageIndex =
      this.pageManagerService.onChangeSelectedIndexPage.subscribe(
        (index) => (this.selectedIndex = index)
      );
  }

  ngAfterContentInit(): void {}

  setNoDataText() {
    this.dxTabPanel!.noDataText = this.tabPanelGetNoDataText();
  }

  ngOnDestroy(): void {
    this.subscriptionOnChangePageIndex?.unsubscribe();
  }

  closeAllHandler(): void {
    this.pageManagerService.pages = [];
  }

  onTabDragStart(e: any): void {
    /* e.itemData = e.fromData[e.fromIndex];*/
  }

  onTabDrop(e: any): void {
    /* e.fromData.splice(e.fromIndex, 1);
        e.toData.splice(e.toIndex, 0, e.itemData);*/
  }

  closeButtonHandler(itemData: PageItem): void {
    const index = this.pageManagerService.pages.indexOf(itemData);
    this.pageClose(index);
  }

  contextMenuClick(e: any) {
    const action = e.itemData.action;

    switch (action) {
      case ContextMenuActionType.Close:
        this.pageClose(this.selectedIndex);
        break;
      case ContextMenuActionType.CloseAll:
        this.pageCloseAll();
        break;
      case ContextMenuActionType.CloseOthers:
        this.pageCloseOthers(this.selectedIndex);
        break;
      case ContextMenuActionType.Duplicate:
        this.pageDuplicate(this.selectedIndex);
        break;
    }
  }

  pageClose(index: number): void {
    this.pageManagerService.ClosePage(index);
  }

  pageCloseAll(): void {
    this.pageManagerService.pages = [];
  }

  pageCloseOthers(index: number): void {
    this.pageManagerService.pages = this.pageManagerService.pages.filter(
      (page, i) => i === index
    );
  }

  tabPanelGetNoDataText() {
    return `
    <div class="no-data dx-theme-border-color-as-text-color">
        <div class="no-data-header">
        ${htmlTagRemove(this.translate.instant('no data header'))}
        </div>
        <div class="no-data-info">
         ${htmlTagRemove(this.translate.instant('no data info'))}
        </div>
    </div>
`;
  }

  private pageDuplicate(index: number) {
    const currentPage = this.pageManagerService.pages[index];
    const newPage = new PageItem(
      currentPage.name,
      currentPage.component,
      currentPage.params
    );
    this.pageManagerService.OpenPage(newPage, true);
  }

  onResized($event) {
    const height = $event.newRect.height - 2;
    this.appTabPageWrapperContainer!.changeHeight(height);
  }
}
