import { Component, OnInit } from '@angular/core';
import { ProductsService } from 'projects/ucanlo/bll/services/products/products.service';

@Component({
  selector: 'app-layout-test',
  templateUrl: './layout-test.component.html',
  styleUrls: ['./layout-test.component.scss'],
})
export class LayoutTestComponent implements OnInit {
  dataSource: any;

  constructor(private repo: ProductsService) {
    this.dataSource = repo.odata();
  }

  ngOnInit(): void {}
}
