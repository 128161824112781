<div class="shipping">

  <h1>{{'shipping' | translate}}</h1>

  <table>
    <tr style="header">
      <th dx-border style="text-align: left">
        {{'status' | translate}}
      </th>
      <th dx-border>
        {{'quantity' | translate}}
      </th>
      <th dx-border>
        {{'units' | translate}}
      </th>
      <th *ngFor="let transportType of transportTypes;" dx-border
      >{{transportType}}</th>
    </tr>
    <tr *ngFor="let data of aggregatedShippingData;">
      <td dx-border>
        {{'@.enum.Reports.' + data.status | translate}}
      </td>
      <td class="value" dx-border>
        <span
          [ngClass]="{'error-background-color delayed' :
          data.status === 'DeliveryDelayed' && data.quantity>0 }"> {{data.quantity}}</span>

      </td>
      <td class="value" dx-border>
        {{data.units}}
      </td>
      <td *ngFor="let transportType of transportTypes;" class="value" dx-border>
        {{data[transportType + "_quantity"]}}
        <span class="units">
        {{data[transportType + "_units"]}}
          </span>
      </td>

    </tr>

    <tfoot>
    <td dx-border>{{'total' | translate}}</td>
    <td class="value" dx-border>{{ aggregatedShippingDataSummary.quantity }}</td>
    <td class="value" dx-border>{{ aggregatedShippingDataSummary.units }}</td>

    <td *ngFor="let transportType of transportTypes;" class="value" dx-border>
      {{aggregatedShippingDataSummary[transportType + "_quantity"]}}
      <span class="units">
        {{aggregatedShippingDataSummary[transportType + "_units"]}}
          </span>
    </td>

    </tfoot>

  </table>
</div>



