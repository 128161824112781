import { LocatorService } from '../../injectors/locator.service';
import { HttpClient } from '@angular/common/http';
import CustomStore from 'devextreme/data/custom_store';
import { map, tap } from 'rxjs/operators';
import { CONFIG } from '../../config';
import { of } from 'rxjs';
import { odataCustomStoreForLookupParams } from './lookup-repository-odata.params';

export function odataCustomStoreForLookup(
  _endpointName: string,
  params: odataCustomStoreForLookupParams | null = null
) {
  if (!params) {
    params = {};
  }

  const endpointUrl = `${CONFIG.API_URL}/${_endpointName}`;
  const idField = params.idField ?? 'id';
  const nameField = params.nameField ?? 'name';
  const selectFields = params.selectFields ?? ['id', 'name'];
  const sortFields = params.sortFields ?? ['name'];
  const http = LocatorService.injector.get(HttpClient);

  return {
    store: new CustomStore({
      key: 'id',

      loadMode: 'processed',
      load: (loadOptions) => {
        console.log('loadOptions:', loadOptions);

        let url = endpointUrl;

        // jeżeli dxo-lookup nie znajduje rekordu, pyta ponownie o rekord ale wykorzystując filtr
        // zakładam, że filtr wygląda tak: ["id","=","23"] więc upraszczam i wyciągam 3 element tablicy
        if (loadOptions.filter) {
          const key = loadOptions.filter[2];
          return getByKey(
            endpointUrl,
            nameField,
            idField,
            selectFields,
            http,
            key
          ).toPromise();
        }

        const skip = loadOptions.skip;
        const take = loadOptions.take;

        let search = loadOptions.searchValue;

        if (!take) return of({}).toPromise();

        url = `${url}?$select=${selectFields.toString()}&$orderby=${sortFields.toString()}&$top=${take}`;

        if (skip || skip == 0) url = url + `&$skip=${skip}`;
        if (search) {
          search = search.replaceAll("'", "''");
          url = url + `&$filter=(_TS eq '${search}')`;
        }

        return http
          .get(url)
          .pipe(
            tap((p) => console.log('tap in:', p)),
            map((res: any) =>
              res.value.map((p) => {
                return { name: p[nameField], id: p[idField] };
              })
            ),
            tap((p) => console.log('tap out:', p))
          )
          .toPromise();
      },
      byKey: (key) => {
        return getByKey(
          endpointUrl,
          nameField,
          idField,
          selectFields,
          http,
          key
        ).toPromise();
      },
    }),
    remoteOperations: true,
    paginate: true,
    pageSize: 50,
  };
}

function getByKey(
  endpointUrl: string,
  nameField: string,
  idField: string,
  selectFields: Array<string>,
  http: HttpClient,
  key: any
) {
  let url = `${endpointUrl}?$select=${selectFields.toString()}&$filter=id eq ${key}`;

  return http.get(url).pipe(
    tap((p) => console.log('tap in:', p)),
    map((res: any) =>
      res.value.map((p) => {
        return { name: p[nameField], id: p[idField] };
      })
    ),
    tap((p) => console.log('tap out:', p))
  );
}

export default odataCustomStoreForLookup;
