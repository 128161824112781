import { Component, OnInit, ViewChild } from '@angular/core';
import 'devextreme/data/odata/store';
import {
  ActionType,
  EditActionParameterModel,
} from 'projects/xlibs/src/gui/edit-form-wrapper/edit-action-parameter-model';
import { TranslateService } from '@ngx-translate/core';
import { AccountUserEditComponent } from '../account-user-edit/account-user-edit.component';
import { DataGridWrapperComponent } from 'projects/xlibs/src/gui/data-grid-wrapper/data-grid-wrapper.component';
import { AccountUsersService } from 'projects/ucanlo/bll/services/accounts/account-users.service';
import { PageManagerService } from 'projects/xlibs/src/gui/page-manager/page-manager.service';
import { AccountUsersViewService } from 'projects/ucanlo/bll/services/accounts/account-users-view.service';

@Component({
  selector: 'app-account-user-list',
  templateUrl: './account-user-list.component.html',
  styleUrls: ['./account-user-list.component.scss'],
})
export class AccountUserListComponent implements OnInit {
  dataSource: any;

  @ViewChild('grid') grid: DataGridWrapperComponent | null = null;

  constructor(
    private repo: AccountUsersService,
    private repoView: AccountUsersViewService,
    private pageManagerService: PageManagerService,
    private translate: TranslateService
  ) {
    this.dataSource = repoView.odata({
      fields: [
        'id',
        'email',
        'firstname',
        'surname',
        'active',
        'roles',
        'notes',
      ],
    });
  }

  ngOnInit(): void {}

  edit(editActionParameterModel: EditActionParameterModel) {
    this.repo.getById(editActionParameterModel?.id).subscribe((model) => {
      //domyślnie użytkownik aktywny, przy dodawaniu
      if (editActionParameterModel.actionType == ActionType.Add) {
        model.active = true;
      }

      this.pageManagerService.OpenEditFormPage(
        this.translate.instant('user - edit'),
        AccountUserEditComponent,
        editActionParameterModel,
        model
      );
    });
  }

  delete(id: number) {
    this.repo.deleteById(id).subscribe((p) => {
      this.grid?.dgRefresh();
    });
  }

  gridViewAction(action: EditActionParameterModel) {
    switch (action.actionType) {
      case ActionType.Add:
      case ActionType.Copy:
      case ActionType.Edit:
        this.edit(action);
        break;
      case ActionType.Delete:
        this.delete(action.id);
        break;
    }
  }
}
