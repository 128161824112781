import { Component } from '@angular/core';

import { loadMessages, locale } from 'devextreme/localization';
// @ts-ignore
import deMessages from 'devextreme/localization/messages/de.json';
// @ts-ignore
import ruMessages from 'devextreme/localization/messages/ru.json';
// @ts-ignore
import plMessages from 'projects/xlibs/src/assets/dx-messages/pl.json';
import { EnumInfoService } from 'projects/xlibs/src/enums/enum-info.service';
import { EnumsInfoRepositoryService } from 'projects/ucanlo/bll/services/_sys/enums-info-repository.service';
import { CONFIG } from '../../../../xlibs/src/config';
import { environment } from '../environments/environment.prod';
import { NotesService } from '../../../bll/services/notes/notes.service';
import { HttpClient } from '@angular/common/http';
import TextBox from 'devextreme/ui/text_box';
import dxDataGrid from 'devextreme/ui/data_grid';
import dxDateBox from 'devextreme/ui/date_box';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  constructor(
    private enumService: EnumInfoService,
    private enumsInfoRepositoryService: EnumsInfoRepositoryService,
    private notesService: NotesService,
    private translateService: TranslateService,
    private http: HttpClient
  ) {
    CONFIG.API_URL = environment.API_URL;
    CONFIG.SERVICE_NOTES_GET = (p) => {
      return notesService.get(p);
    };
    CONFIG.SERVICE_NOTES_SAVE = (p) => {
      return notesService.save(p);
    };

    //inicjacja enumów
    enumsInfoRepositoryService.getAll().subscribe((enums) => {
      enumService.init(enums);
    });

    loadMessages(plMessages);
    loadMessages(ruMessages);
    loadMessages(deMessages);
    locale('pl');

    this.devExtremeDefaultParametersConfigure();
  }

  private devExtremeDefaultParametersConfigure() {
    TextBox.defaultOptions({
      options: {
        showClearButton: true,
      },
    });

    dxDateBox.defaultOptions({
      options: {
        displayFormat: 'yyyy-MM-dd',
        type: 'date',
      },
    });

    dxDataGrid.defaultOptions({
      options: {
        customizeColumns: (columns) => {
          columns.forEach((c) => {
            if (c.dataType === 'date') c.format = 'yyyy-MM-dd';
            if (c.dataType === 'datetime') c.format = 'yyyy-MM-dd HH:mm:ss';
            // if (!c.editorOptions) c.editorOptions = {};
            // c.editorOptions.displayFormat = 'yyyy-mm-dd';
          });
        },

        filterPanel: {
          visible: false,
        },

        filterBuilderPopup: {
          title: this.translateService.instant('advanced filters - settings'),
        },

        loadPanel: {
          enabled: true,
          showPane: false,
          shading: true,
        },

        groupPanel: {
          visible: true,
        },
        searchPanel: {
          visible: true,
          highlightSearchText: false,
        },

        paging: {
          pageSize: 100,
          enabled: false,
        },

        scrolling: {
          scrollByContent: true,
          showScrollbar: 'always',
          mode: 'virtual',
          //columnRenderingMode: 'virtual',
        },

        columnChooser: {
          enabled: false,
          mode: 'select',
          allowSearch: true,
        },

        headerFilter: {
          visible: false,
        },

        grouping: {
          contextMenuEnabled: false,
        },

        sorting: {
          mode: 'multiple',
        },

        selection: {
          mode: 'single',
          deferred: true,
        },

        focusedRowEnabled: true,
        focusedRowIndex: 0,

        height: '100%',
        width: '100%',

        filterRow: {
          visible: true,
        },

        rowAlternationEnabled: true,
        showBorders: true,
        showRowLines: true,
        allowColumnReordering: true,
        allowColumnResizing: true,
        columnResizingMode: 'widget',
        columnAutoWidth: false,
        columnMinWidth: 80,

        //focusedRowIndex : 0,
        //focusedColumnIndex : 0,
        //autoNavigateToFocusedRow : true,

        twoWayBindingEnabled: false,

        repaintChangesOnly: true,
      },
    });
  }
}
