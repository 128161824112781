import { Injectable } from '@angular/core';
import { BaseRepositoryOdata } from '../../../../xlibs/src/bll/services/base-repository-odata';
import { InventoryActionModel } from '../../models/inventory-transactions/inventory-action.model';
import { InventoryActionDeliveryToLocationModel } from '../../models/inventory-transactions/inventory-action-delivery-to-location.model';

@Injectable({
  providedIn: 'root',
})
export class InventoryActionsDeliveryToLocationViewService extends BaseRepositoryOdata<
  InventoryActionDeliveryToLocationModel,
  number
> {
  constructor() {
    super('InventoryActionDeliveryToLocationView');
  }
}
