import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { BaseRepositoryOdata } from '../../../../xlibs/src/bll/services/base-repository-odata';
import { AccountUserModel } from '../../models/accounts/account-user.model';

@Injectable({
  providedIn: 'root',
})
export class AccountUsersService extends BaseRepositoryOdata<
  AccountUserModel,
  number
> {
  constructor() {
    super('accountUser');
  }

  public override onSave(model: AccountUserModel) {
    if (model.roles == '') {
      model.roles = null;
    }
  }
}
