import {Component, Input, OnInit} from '@angular/core';
import {DownloadService} from '../../../../../shared/services/download.service';
import * as XLSX from 'xlsx';
import {TranslateService} from '@ngx-translate/core';
import {DataImportErrorInfoComponent} from '../data-import-error-info/data-import-error-info.component';
import {MessageBoxService} from 'projects/xlibs/src/gui/message-box/services/message-box.service';
import {PageItem} from 'projects/xlibs/src/gui/page-manager/page-item';
import {DataImportType} from 'projects/ucanlo/bll/models/data/import/data-import-input-data.model';
import {DataImportRepositoryService} from 'projects/ucanlo/bll/services/data/import/data-import-repository.service';
import {DataImportResultModel} from 'projects/ucanlo/bll/models/data/import/data-import-result.model';
import {PageManagerService} from "projects/xlibs/src/gui/page-manager/page-manager.service";

@Component({
    selector: 'app-data-import-item',
    templateUrl: './data-import-item.component.html',
    styleUrls: ['./data-import-item.component.scss'],
})
export class DataImportItemComponent implements OnInit {
    @Input() sampleFileName = '';
    @Input() title = '';
    @Input() description = '';
    @Input() buttonImportFileText = '';
    @Input() exportedObjectType: DataImportType = DataImportType.products;

    constructor(
        private downloads: DownloadService,
        private dataImportRepository: DataImportRepositoryService,
        private translate: TranslateService,
        private pageManagerService: PageManagerService,
        private messageBox: MessageBoxService
    ) {}

    ngOnInit(): void {}

    getSample($event: any, fileName: string) {
        this.downloads
            .download(`/assets/import-files/${fileName}`)
            .subscribe((blob) => {
                const a = document.createElement('a');
                const objectUrl = URL.createObjectURL(blob);
                a.href = objectUrl;
                a.download = fileName;
                a.click();
                URL.revokeObjectURL(objectUrl);
            });
    }

    uploadData(event: any, type: DataImportType) {
        const target: DataTransfer = <DataTransfer>event.target;
        if (target.files.length !== 1) throw new Error('Cannot use multiple files');
        const reader: FileReader = new FileReader();
        reader.onload = (e: any) => {
            const fileData: string = e.target.result;
            const wb: XLSX.WorkBook = XLSX.read(fileData, {type: 'binary'});
            const workSheetName: string = wb.SheetNames[0];
            const ws: XLSX.WorkSheet = wb.Sheets[workSheetName];
            const data = XLSX.utils.sheet_to_json(ws, {header: 1});
            const res = this.jsonToObject(data);

            this.dataImportRepository
                .import(type, res)
                .subscribe((importResult) => this.importResultHandler(importResult));
        };

        reader.readAsBinaryString(target.files[0]);
    }

    private importResultHandler(result: DataImportResultModel) {
        if (result.hasError) {
            this.pageManagerService.OpenPage(
                new PageItem(
                    this.translate.instant('import errors'),
                    DataImportErrorInfoComponent,
                    {importResult: result}
                ),
                true
            );

            return;
        }

        if (result.updated + result.inserted > 0) {
            const message = this.messageAfterImport
                .replace('{insertedCount}', result.inserted.toString())
                .replace('{updatedCount}', result.updated.toString());

            this.messageBox.showInfo(message);
            return;
        }

        this.messageBox.showInfo(
            this.translate.instant(
                'file import failed. the file is empty or has an invalid format'
            )
        );
    }

    jsonToObject(data: any) {
        const rowsCount = data.length;
        const rowHeader = data[0] as string[];
        const propertiesCount = rowHeader.length;

        let res: any = [];

        for (let row = 1; row < rowsCount; row++) {
            const item: { [key: string]: unknown } = {};
            for (let prop = 0; prop < propertiesCount; prop++) {
                item[rowHeader[prop]] = data[row][prop] ?? null;
            }
            res.push(item);
        }
        return res;
    }

    messageAfterImport = `

        <div class="mb2 strong"">
            ${this.translate.instant('import information')}
        </div>

        <table>
          <tr>
            <td>
               ${this.translate.instant('import records inserted')}
            </td>
            <td>
              <strong>: {insertedCount}</strong>
            </td>
          </tr>
          <tr>
            <td>
              ${this.translate.instant('import records updated')}
            </td>
            <td>
              <strong>: {updatedCount}</strong>
            </td>
          </tr>
      </table>`;
}
