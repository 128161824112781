import { Injectable } from '@angular/core';
import { AccountModel } from '../../models/accounts/account.model';

@Injectable({
  providedIn: 'root',
})
export class AccountService {
  constructor() {}

  getConfig(): AccountModel {
    return {
      locationsSupport: true,
      serialNumberSupport: true,
      expirationDateSupport: true,
      highStorageSupport: true,
      multiPickingSupport: true,
    };
  }
}
