import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { BaseRepository } from 'projects/xlibs/src/bll/services/base-repository';
import {
  AccountingTransactionStatusType,
  AccountingTransactionType,
} from 'projects/ucanlo/bll/models/accounting-transactions/accounting-transaction.model';
import buildQuery from 'odata-query';
import {
  BaseRepositoryOdata,
  DataSourceParameters,
} from '../../../../xlibs/src/bll/services/base-repository-odata';
import { AccountingTransactionViewModel } from '../../models/accounting-transactions/accounting-transaction-view.model';

export enum AccountingTransactionViewMode {
  DeliveryToStock = 0, // dostawy nowe lub otwarte do zamagazynowania
}

@Injectable({
  providedIn: 'root',
})
export class AccountingTransactionsViewService extends BaseRepositoryOdata<
  AccountingTransactionViewModel,
  number
> {
  constructor() {
    super('accountingTransactionView');
  }

  odataDetails(params: DataSourceParameters = {}) {
    params.endPointName = 'accountingTransactionDetailView';
    return this.odata(params);
  }

  odataByViewMode(viewMode: AccountingTransactionViewMode) {
    switch (viewMode) {
      case AccountingTransactionViewMode.DeliveryToStock:
        return this.odataDeliveryOnStock();
    }
  }

  /**
   * Zwraca dostawy możliwe do zamagazynowania (nowe lub otwarte)
   */
  odataDeliveryOnStock() {
    const filter = `
            Type eq '${
              AccountingTransactionType[AccountingTransactionType.Delivery]
            }' and
            Status in 
                (
                    '${
                      AccountingTransactionStatusType[
                        AccountingTransactionStatusType.New
                      ]
                    }',
                    '${
                      AccountingTransactionStatusType[
                        AccountingTransactionStatusType.Processed
                      ]
                    }'
                )
            `;

    // return this.dataSource({
    //   filter: filter,
    // });
  }
}
