import {
  AfterContentInit,
  AfterViewInit,
  Component,
  ContentChild,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  DxScrollViewComponent,
  DxFormModule,
  DxFormComponent,
} from 'devextreme-angular';

import { Subscription } from 'rxjs';

import { isEqual, cloneDeep } from 'lodash';
import { MessageBoxService } from '../message-box/services/message-box.service';
import { TranslateService } from '@ngx-translate/core';
import { EditActionParameterModel } from 'projects/xlibs/src/gui/edit-form-wrapper/edit-action-parameter-model';
import { PageManagerService } from 'projects/xlibs/src/gui/page-manager/page-manager.service';

@Component({
  selector: 'xlib-edit-form-wrapper',
  templateUrl: './edit-form-wrapper.component.html',
  styleUrls: ['./edit-form-wrapper.component.scss'],
})
export class EditFormWrapperComponent
  implements OnInit, AfterViewInit, AfterContentInit
{
  @ContentChild(DxFormComponent) editForm!: DxFormComponent;

  @Input() title: string = 'List';
  @Input() askWhenExitWithoutSave = true;
  @Output() save = new EventEmitter();

  subscriptionOnChangePageIndex?: Subscription;
  selectedPageId: number = 0;

  editObject: any;
  editObjectCopy: any;
  editActionParameterModel: EditActionParameterModel | null = null;

  constructor(
    private pageManagerService: PageManagerService,
    private messagebox: MessageBoxService,
    private translate: TranslateService
  ) {}

  public init(
    editObject: any,
    editActionParameterModel: EditActionParameterModel | null,
    saveFunction: Function | null = null
  ) {
    this.editObject = editObject;
    this.editObjectCopy = cloneDeep(editObject);
    this.editActionParameterModel = editActionParameterModel;

    if (saveFunction) {
      this.save.subscribe(() => {
        saveFunction();
      });
    }
  }

  ngOnInit(): void {
    this.subscriptionOnChangePageIndex =
      this.pageManagerService.onChangeSelectedIndexPage.subscribe(
        (index) => (this.selectedPageId = index)
      );
  }

  ngOnDestroy(): void {
    this.subscriptionOnChangePageIndex?.unsubscribe();
  }

  ngAfterContentInit(): void {
    this.formConfiguration(this.editForm);
  }

  private formConfiguration(editForm: DxFormComponent) {
    editForm.instance.focus();

    editForm.scrollingEnabled = true;
  }

  ngAfterViewInit(): void {}

  efSave() {
    const validateResult = this.editForm?.instance.validate().isValid;
    if (!validateResult) {
      return;
    }

    this.save.emit();
  }

  /**
   * zamyka okno edycyjne
   * @param withQuestion weryfikuje czy dane zostały zmienione, jeśli tak - wyświetla monit
   * @param
   */
  close(withQuestion: boolean = false) {
    if (!withQuestion) {
      this.pageManagerService.CloseCurrentPage();
      return;
    }

    const editObjectIsChanged = !isEqual(this.editObject, this.editObjectCopy);

    if (editObjectIsChanged) {
      this.messagebox.questionYesNo(
        this.translate.instant('are you sure you want exit without save'),
        () => {
          this.pageManagerService.CloseCurrentPage();
        }
      );
    } else {
      this.pageManagerService.CloseCurrentPage();
    }
  }
}
