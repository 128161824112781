import { MessageBoxService } from '../services/message-box.service';

export enum MessageBoxMessageType {
  None,
  Ok,
  YesNo,
  YesNoCancel,
}

export class MessageBoxModel {
  constructor(
    public type: MessageBoxMessageType = MessageBoxMessageType.Ok,
    public text: string = '',
    public yesAction?: () => any,
    public noAction?: () => any,
    public title: string = ''
  ) {}
}
