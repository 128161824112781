<xlib-notes #notes> </xlib-notes>
<xlib-data-import
  #dataImport
  [importDataButtonFromFileDescriptionTranslateKey]="
    importDataButtonFromFileDescriptionTranslateKey
  "
  [importDataButtonFromClipBoardDescriptionTranslateKey]="
    importDataButtonFromClipBoardDescriptionTranslateKey
  "
  [importDataFields]="importDataFields"
></xlib-data-import>

<div
  class="data-grid-wrapper-container"
  #dataGridWrapperContainer
  (resized)="onResized($event)"
>
  <div *ngIf="description" [innerHtml]="description" class="description"></div>
  <div *ngIf="showHeader == true" class="header">
    <div
      [ngClass]="
        showTitleWithNormalFont
          ? 'title-normal-font'
          : 'title dx-theme-accent-as-text-color dx-form-group-content'
      "
    >
      {{ title }}
    </div>
    <div class="buttons-action-container">
      <xlib-action-button-large
        *ngFor="let action of actions; index as i"
        [text]="action.text"
        [description]="action.description"
        [action]="action.actionName"
        [icon]="action.icon"
        [disabled]="action.disabled"
        (click)="dgCustomAction(action.actionName, action.selectedRowRequired)"
      >
      </xlib-action-button-large>
    </div>
    <div [title]="error" class="buttons-container">
      <div *ngIf="error" class="error dx-theme-background-color-as-text-color">
        !
      </div>

      <!-- user actions buttons ---------------------------------------------------------------------------------->
      <div *ngIf="showActionButtons" class="buttons">
        <div *ngIf="showActionEditButtons" style="display: inline">
          <div *ngIf="selectMode === 'none'" class="buttons-common-action">
            <dx-button
              (onClick)="dgAdd()"
              [visible]="showAddButton"
              class="button"
              hint="{{ 'add new' | translate }}"
              icon="add"
            >
            </dx-button>
            <dx-button
              (onClick)="dgEdit()"
              [visible]="showEditButton"
              class="button"
              hint="{{ 'edit selected' | translate }}"
              icon="edit"
            >
            </dx-button>
            <dx-button
              (onClick)="dgDelete()"
              [visible]="showDeleteButton"
              class="button"
              hint="{{ 'delete selected' | translate }}"
              icon="trash"
            >
            </dx-button>
            <dx-button
              (onClick)="dgCopy()"
              [visible]="showCopyButton"
              class="button"
              hint="{{ 'copy selected' | translate }}"
              icon="copy"
            >
            </dx-button>
          </div>
        </div>
        <div
          *ngIf="
            showExportToExcelButton ||
            showNotesEditButton ||
            showClearFiltersButton
          "
          class="separator"
        ></div>
        <dx-button
          (onClick)="dgImportData()"
          [visible]="showImportDataButton"
          class="button"
          hint="{{ importDataButtonHintTranslateKey | translate }}"
          icon="upload"
        ></dx-button>
        <dx-button
          (onClick)="dgExportToExcel()"
          [visible]="showExportToExcelButton"
          class="button"
          hint="{{ 'export to excel' | translate }}"
          icon="xlsxfile"
        ></dx-button>
        <dx-button
          (onClick)="dgNotes()"
          [visible]="showNotesEditButton"
          class="button"
          hint="{{ 'notes edit' | translate }}"
          icon="textdocument"
        >
        </dx-button>

        <dx-button
          (onClick)="dgSetFilters()"
          [visible]="showClearFiltersButton"
          class="button"
          hint="{{ 'set advanced filters' | translate }}"
          template="filterSetButtonTemplate"
        >
          <div *dxTemplate="let data of 'filterSetButtonTemplate'">
            <fa-icon [icon]="iconFilterSet"></fa-icon>
          </div>
        </dx-button>

        <dx-button
          (onClick)="dgClearFilters()"
          [visible]="showClearFiltersButton"
          class="button"
          hint="{{ 'clear filters' | translate }}"
          template="filterCancelButtonTemplate"
        >
          <div *dxTemplate="let data of 'filterCancelButtonTemplate'">
            <fa-icon [icon]="iconFilterCancel"></fa-icon>
          </div>
        </dx-button>

        <div *ngIf="showRefreshButton" class="separator"></div>

        <dx-button
          (onClick)="dgRefresh()"
          [visible]="showRefreshButton"
          class="button"
          hint="{{ 'refresh' | translate }}"
          icon="refresh"
        >
        </dx-button>
        <div *ngIf="selectMode !== 'none'" class="buttons-select-action">
          <dx-button
            (onClick)="dgSelect()"
            [visible]="showOkButton"
            class="button"
            hint="{{ 'ok' | translate }}"
            icon="check"
            type="default"
          >
          </dx-button>

          <dx-button
            (onClick)="dgCancel()"
            [visible]="showCancelButton"
            class="button"
            hint="{{ 'cancel' | translate }}"
            icon="close"
          >
          </dx-button>
        </div>
      </div>
    </div>
  </div>

  <div class="grid-content" #gridContent>
    <ng-content select="dx-data-grid"></ng-content>
  </div>

  <div *ngIf="showFooter" class="footer">
    <div class="space"></div>

    <div class="counter">
      <span *ngIf="focusedRowNumber != 0" class="focused-row-number">
        {{ focusedRowNumber }} /
      </span>
      <span class="strong dx-theme-accent-as-text-color">
        {{ totalCount }}</span
      >
    </div>

    <div class="actions">
      <i
        (click)="showColumnChooser()"
        class="dx-icon-menu icon-action dx-theme-accent-as-text-color"
        title="{{ 'column chooser' | translate }}"
      ></i>
      <i
        (click)="clearColumnSettings()"
        class="dx-icon-clear icon-action clear-settings"
        title="{{ 'clear column settings' | translate }}"
      ></i>
    </div>
  </div>
</div>
