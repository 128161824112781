import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import {
  AccountingTransactionModel,
  AccountingTransactionStatusType,
  AccountingTransactionType,
} from 'projects/ucanlo/bll/models/accounting-transactions/accounting-transaction.model';
import { BaseRepository } from 'projects/xlibs/src/bll/services/base-repository';
import { Observable, of } from 'rxjs';
import {
  ActionType,
  EditActionParameterModel,
} from 'projects/xlibs/src/gui/edit-form-wrapper/edit-action-parameter-model';
import { CustomerModel } from 'projects/ucanlo/bll/models/customers/customer.model';
import { AccountingTransactionProductModel } from 'projects/ucanlo/bll/models/accounting-transactions/accounting-transaction-product.model';
import {
  dxFilterAddCondition,
  dxFilterOrCondition,
  dxFilterPrepare,
} from 'projects/xlibs/src/gui/dev-extreme/dx-filter-helper';
import { AddAdHocTypeTransactionParams } from 'projects/ucanlo/bll/models/accounting-transactions/parameters/add-ad-hoc-type-transaction-params';
import { AccountingTransactionQueryParams } from 'projects/ucanlo/bll/models/accounting-transactions/parameters/accounting-transaction-query-params';
import { BaseRepositoryOdata } from '../../../../xlibs/src/bll/services/base-repository-odata';

@Injectable({
  providedIn: 'root',
})
export class AccountingTransactionsService extends BaseRepositoryOdata<
  AccountingTransactionModel,
  number
> {
  constructor(private translate: TranslateService) {
    super('accountingTransaction');
  }

  /**
   * Tworzy filtr DX dla podanych parametrów
   * @param params
   */
  odataForAccountTransactionViewCreateFilter(
    params: AccountingTransactionQueryParams | null = null
  ): Array<any> | null {
    if (!params) {
      return null;
    }

    const dxFilter = new Array<any>();

    if (params.type !== undefined) {
      dxFilterAddCondition(dxFilter, [
        'type',
        '=',
        AccountingTransactionType[params?.type!],
      ]);
    }

    if (params?.statuses) {
      const dxFilterForStatuses = new Array<any>();

      params?.statuses.forEach((status) => {
        dxFilterOrCondition(dxFilterForStatuses, [
          'status',
          '=',
          AccountingTransactionStatusType[status],
        ]);
      });

      if (dxFilterForStatuses) {
        dxFilterAddCondition(dxFilter, dxFilterForStatuses);
      }
    }

    return dxFilterPrepare(dxFilter);
  }

  // public odataForAccountTransactionView(
  //   params: AccountingTransactionQueryParams | null = null
  // ) {
  //   const dxFilter = this.odataForAccountTransactionViewCreateFilter(params);
  //
  //   return this.getDataSource(null, 'accountingTransactionView', dxFilter);
  // }

  // public odataForAccountTransactionDetailsView(
  //   accountingTransactionId: number
  // ) {
  //   return this.getDataSource(
  //     ['id', 'productId', 'productNumber', 'productName', 'quantity'],
  //     'accountingTransactionDetailView',
  //     ['accountingTransactionId', '=', accountingTransactionId]
  //   );
  // }

  get(
    id: number | null,
    type: AccountingTransactionType
  ): Observable<AccountingTransactionModel> {
    if (id === null) {
      return of({
        details: new Array<AccountingTransactionProductModel>(),
        differentShippingAddress: false,
        type: AccountingTransactionType[type],
      } as AccountingTransactionModel);
    } else {
      return this.http.get<AccountingTransactionModel>(
        `${this.endpointUrl}/${id}?${this.odataInfoRemove}`
      );
    }
  }

  save(
    editMode: EditActionParameterModel,
    model: AccountingTransactionModel
  ): Observable<AccountingTransactionModel> {
    let method = 'post';
    let url = this.endpointUrl;

    console.log('AccountingTransactionsService.save.model', model);

    switch (editMode.actionType) {
      case ActionType.Add:
      case ActionType.Copy:
        this.removeIds(model);
        break;
      case ActionType.Edit:
        method = 'patch';
        url += `/${model.id}`;
        break;
    }
    return this.http.request<AccountingTransactionModel>(method, url, {
      body: model,
    });
  }

  delete(id: string) {
    return this.http.delete(`${this.endpointUrl}/${id}`);
  }

  private removeIds(model: AccountingTransactionModel) {
    delete model.id;
    model.details.forEach((p) => delete p.id);
  }

  copyDataFromTranscationCustomerToShippmentAddress(
    t: AccountingTransactionModel
  ) {
    t.shippingCompanyName = t.customerCompanyName;
    t.shippingFirstName = t.customerFirstName;
    t.shippingLastName = t.customerLastName;
    t.shippingAddressLine1 = t.customerAddressLine1;
    t.shippingAddressLine2 = t.customerAddressLine2;
    t.shippingCity = t.customerCity;
    t.shippingPostCode = t.customerPostCode;
    t.shippingCountry = t.customerCountry;
    t.shippingPhone = t.customerPhone;
    t.shippingEmail = t.customerEmail;
  }

  copyDataFromCustomerToTransactionCustomer(
    t: AccountingTransactionModel,
    c: CustomerModel
  ) {
    t.customerCompanyName = c.companyName;
    t.customerFirstName = c.firstName;
    t.customerLastName = c.lastName;
    t.customerAddressLine1 = c.addressLine1;
    t.customerAddressLine2 = c.addressLine2;
    t.customerCity = c.city;
    t.customerPostCode = c.postCode;
    t.customerCountry = c.country;
    t.customerPhone = c.phone;
    t.customerEmail = c.email;

    t.differentShippingAddress = c.differentShippingAddress;

    t.shippingCompanyName = c.shippingCompanyName;
    t.shippingFirstName = c.shippingFirstName;
    t.shippingLastName = c.shippingLastName;
    t.shippingAddressLine1 = c.shippingAddressLine1;
    t.shippingAddressLine2 = c.shippingAddressLine2;
    t.shippingCity = c.shippingCity;
    t.shippingPostCode = c.shippingPostCode;
    t.shippingCountry = c.shippingCountry;
    t.shippingPhone = c.shippingPhone;
    t.shippingEmail = c.shippingEmail;
  }

  addAdHocTypeTransaction(
    params: AddAdHocTypeTransactionParams
  ): Observable<AccountingTransactionModel> {
    const url = `${this.endpointUrl}/addAdHocTypeTransaction`;

    return this.http.request<AccountingTransactionModel>('post', url, {
      body: params,
    });
  }
}
