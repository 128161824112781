<xlib-data-grid-wrapper
  #grid
  (buttonsAction)="gridViewAction($event)"
  [title]="'user list' | translate"
  entityName="AccountUser"
>
  <dx-data-grid
    #dataGridRef
    [dataSource]="dataSource"
    id="account-user-list"
    id="grid"
  >
    <dxi-column
      caption="{{ 'login (email)' | translate }}"
      dataField="email"
    ></dxi-column>
    <dxi-column
      caption="{{ 'firstname' | translate }}"
      dataField="firstname"
    ></dxi-column>
    <dxi-column
      caption="{{ 'surname' | translate }}"
      dataField="surname"
    ></dxi-column>
    <dxi-column
      caption="{{ 'active' | translate }}"
      dataField="active"
    ></dxi-column>
    <dxi-column
      [allowFiltering]="false"
      [allowGrouping]="false"
      [allowHeaderFiltering]="false"
      [allowSearch]="false"
      [allowSorting]="false"
      [allowSorting]="false"
      caption="{{ 'user roles' | translate }}"
      cellTemplate="cellTemplateLoginWithRoles"
      dataField="roles"
    ></dxi-column>
    <div *dxTemplate="let data of 'cellTemplateLoginWithRoles'">
      <div>{{ data.value | rolesDescription }}</div>
    </div>
  </dx-data-grid>
</xlib-data-grid-wrapper>
