import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';

import {
  ActionType,
  EditActionParameterModel,
} from 'projects/xlibs/src/gui/edit-form-wrapper/edit-action-parameter-model';
import { DataGridWrapperComponent } from 'projects/xlibs/src/gui/data-grid-wrapper/data-grid-wrapper.component';
import { TranslateService } from '@ngx-translate/core';
import { LocationEditComponent } from '../location-edit/location-edit.component';
import { LocationsService } from 'projects/ucanlo/bll/services/layout/locations.service';
import { EnumInfoService } from 'projects/xlibs/src/enums/enum-info.service';
import { LocationZoneType } from 'projects/ucanlo/bll/models/layout/location.model';
import { PageManagerService } from 'projects/xlibs/src/gui/page-manager/page-manager.service';
import { LocationsViewService } from '../../../../../../bll/services/layout/locations-view.service';

@Component({
  selector: 'app-location-list',
  templateUrl: './location-list.component.html',
  styleUrls: ['./location-list.component.scss'],
})
export class LocationListComponent {
  dataSource: any;
  @ViewChild('grid') grid: DataGridWrapperComponent | null = null;
  zoneTypeLookup: any;

  constructor(
    private repo: LocationsService,
    private repoView: LocationsViewService,
    private pageManagerService: PageManagerService,
    private translate: TranslateService,
    private enums: EnumInfoService
  ) {
    this.dataSource = this.repoView.odata({
      fields: [
        'name',
        'barcode',
        'notes',
        'blocked',
        'pickingOverQueue',
        'pickingBelowQueue',
        'oneSupplier',
        'oneSku',
        'id',
        'zoneType',
      ],
    });
    this.zoneTypeLookup = this.enums.getDataSource('LocationZoneType');
  }

  gridViewAction(action: EditActionParameterModel) {
    switch (action.actionType) {
      case ActionType.Add:
      case ActionType.Copy:
      case ActionType.Edit:
        this.edit(action);
        break;
      case ActionType.Delete:
        this.delete(action.id);
        break;
    }
  }

  edit(editActionParameterModel: EditActionParameterModel) {
    this.repo.getById(editActionParameterModel.id).subscribe((model) => {
      this.pageManagerService.OpenEditFormPage(
        this.translate.instant('location - edit'),
        LocationEditComponent,
        editActionParameterModel,
        model
      );
    });
  }

  delete(id: string) {
    this.repo.deleteById(id).subscribe((p) => {
      this.grid?.dgRefresh();
    });
  }
}
