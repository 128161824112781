import {
  AfterViewInit,
  Component,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';

import { DxFormComponent } from 'devextreme-angular';
import { EditActionParameterModel } from 'projects/xlibs/src/gui/edit-form-wrapper/edit-action-parameter-model';
import { EditFormWrapperComponent } from 'projects/xlibs/src/gui/edit-form-wrapper/edit-form-wrapper.component';
import { LocationModel } from 'projects/ucanlo/bll/models/layout/location.model';
import { LocationsService } from 'projects/ucanlo/bll/services/layout/locations.service';
import { EnumInfoService } from 'projects/xlibs/src/enums/enum-info.service';

@Component({
  selector: 'app-location-edit',
  templateUrl: './location-edit.component.html',
  styleUrls: ['./location-edit.component.scss'],
})
export class LocationEditComponent implements OnInit, AfterViewInit {
  constructor(private repository: LocationsService, enums: EnumInfoService) {
    this.dxSelectBoxZoneTypeOptions = enums.getDataSource('LocationZoneType');
  }

  @ViewChild('editFormWrapper') editFormWrapper!: EditFormWrapperComponent;
  @ViewChild('editForm') editForm: DxFormComponent | null = null;

  @Input() editActionParams: EditActionParameterModel | null = null;

  editObject: LocationModel | null = null;
  dxSelectBoxZoneTypeOptions: any;

  ngOnInit(): void {
    this.editObject = this.editActionParams?.model;
  }

  ngAfterViewInit() {
    this.editFormWrapper?.init(this.editObject, this.editActionParams!, () =>
      this.save()
    );
  }

  save() {
    this.repository
      .save(this.editActionParams!, this.editObject as LocationModel)
      .subscribe((res) => {
        this.editFormWrapper.close();
        this.editActionParams!.dataGrid!.dgRefreshAndFocusRowById(res.id);
      });
  }
}
