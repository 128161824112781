import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpRequest,
} from '@angular/common/http';

import { catchError, tap } from 'rxjs/operators';

import { BehaviorSubject, of, throwError } from 'rxjs';
import { UserModel } from 'projects/xlibs/src/authorization/jwt/models/user.model';
import { AuthModel } from 'projects/xlibs/src/authorization/jwt/models/auth.model';
import { TokenModel } from 'projects/xlibs/src/authorization/jwt/models/token.model';
import jwt_decode from 'jwt-decode';
import { CONFIG } from 'projects/xlibs/src/config';

@Injectable({
  providedIn: 'root',
})
export class JwtAuthorizationService {
  private readonly ACCESS_TOKEN = 'at';
  private readonly REFRESH_TOKEN = 'rt';

  constructor(private http: HttpClient) {
    this.init();
  }

  /**
   * uruchamiane przy starcie aplikacji, loguje użytkownika jeśli są tokeny
   * @private
   */
  private init() {
    const accessToken = localStorage.getItem(this.ACCESS_TOKEN);
    const refreshToken = localStorage.getItem(this.REFRESH_TOKEN);

    if (accessToken && refreshToken) {
      this.setSession({ accessToken, refreshToken });
    }
  }

  get user(): UserModel | null {
    return this.userSubject.getValue();
  }

  get isAuthenticated(): boolean {
    return this.isAuthenticatedSubject.getValue();
  }

  get authorizationToken(): string | null {
    return localStorage.getItem(this.ACCESS_TOKEN);
  }

  isAuthenticatedSubject = new BehaviorSubject<boolean>(false);
  userSubject = new BehaviorSubject<UserModel | null>(null);

  login(login: string, password: string) {
    return this.http
      .post<AuthModel>(`${CONFIG.API_URL}/auth/login`, { login, password })
      .pipe(
        tap((data) => {
          this.setSession(data);
        })
      );
  }

  private setSession(authData: AuthModel) {
    console.log('#1', authData.accessToken);

    const jwt = jwt_decode(authData.accessToken) as TokenModel;

    console.log('#2');

    const user: UserModel = {
      id: jwt.i,
      accountId: jwt.a,
      login: jwt.l,
      roles: jwt.r,
    };

    localStorage.setItem(this.ACCESS_TOKEN, authData.accessToken);
    localStorage.setItem(this.REFRESH_TOKEN, authData.refreshToken);

    this.setAuth(user);
  }

  setAuth(user: UserModel | null) {
    this.userSubject.next(user);
    if (user) {
      this.isAuthenticatedSubject.next(true);
      console.log('isAuthenticated: true, user:', user);
    } else {
      this.isAuthenticatedSubject.next(false);
      console.log('isAuthenticated: false, user:', user);
    }
  }

  refresh() {
    const accessToken = localStorage.getItem(this.ACCESS_TOKEN);
    const refreshToken = localStorage.getItem(this.REFRESH_TOKEN);

    if (!refreshToken) {
      this.logout();
      return of();
    }

    return this.http
      .post<AuthModel>(`${CONFIG.API_URL}/auth/refresh`, {
        accessToken,
        refreshToken,
      })
      .pipe(
        tap((authData) => this.setSession(authData)),
        catchError((error: HttpErrorResponse) => {
          if (error) {
            if (error.status == 401) {
              this.logout();
            }
            if (error.status == 403) {
              this.logout();
            }
          }
          return throwError(error);
        })
      );
  }

  logout() {
    localStorage.removeItem(this.ACCESS_TOKEN);
    localStorage.removeItem(this.REFRESH_TOKEN);
    this.setAuth(null);
  }

  public getRequestWithAuthorizationToken(request: HttpRequest<any>) {
    const cloned = request.clone({
      headers: request.headers.set(
        'Authorization',
        'Bearer ' + this.authorizationToken
      ),
    });
    return cloned;
  }
}
