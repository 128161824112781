import {Injectable, OnDestroy} from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    Router,
    RouterStateSnapshot,
    UrlTree,
} from '@angular/router';
import {Observable, Subscription} from 'rxjs';
import {JwtAuthorizationService} from 'projects/xlibs/src/authorization/jwt/jwt-authorization.service';
import {UserModel} from 'projects/xlibs/src/authorization/jwt/models/user.model';

@Injectable({
    providedIn: 'root',
})
export class AuthServiceGuard implements CanActivate, OnDestroy {
    user?: UserModel | null;
    subscription?: Subscription;

    constructor(
        private jwtAuthorizationService: JwtAuthorizationService,
        private router: Router
    ) {
        jwtAuthorizationService.userSubject.subscribe((user) => this.onChangeUser(user));
    }

    onChangeUser(user: UserModel | null) {
        this.user = user;
        if (!this.user) {
            this.router.navigateByUrl("/login");
        }
    }


    ngOnDestroy() {
        this.subscription?.unsubscribe();
    }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ):
        | Observable<boolean | UrlTree>
        | Promise<boolean | UrlTree>
        | boolean
        | UrlTree {
        if (this.user) {
            return true;
        } else {
            this.router.navigateByUrl("/login");

            return false;
        }
    }
}
