import { Component, Inject, OnInit, ViewChild } from '@angular/core';

import { DxTreeViewComponent } from 'devextreme-angular';
import { MenuItemModel } from '../../../../core/models/menu-item-model';

import { ConfigService } from '../../../../_config/config.service';
import { PageItem } from 'projects/xlibs/src/gui/page-manager/page-item';
import { DOCUMENT } from '@angular/common';
import { StatusPanel } from '../../../reports/status-panel/status-panel.component';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { JwtAuthorizationService } from 'projects/xlibs/src/authorization/jwt/jwt-authorization.service';
import { PageManagerService } from 'projects/xlibs/src/gui/page-manager/page-manager.service';

import { MenuService } from './menu.service';
import { ScreenService } from '../../../../../../../../xlibs/src/gui/screen-service/screen.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit {
  @ViewChild(DxTreeViewComponent, { static: false })
  treeView?: DxTreeViewComponent;

  menuOptions: Array<MenuItemModel> = [];
  ctrlKey: boolean = false;

  constructor(
    private pageManagerService: PageManagerService,
    private config: ConfigService,
    private translate: TranslateService,
    private authService: JwtAuthorizationService,
    private router: Router,
    private menuService: MenuService,
    private screenService: ScreenService,
    @Inject(DOCUMENT) private doc: Document
  ) {
    this.addEventListener();
  }

  addEventListener() {
    this.doc.addEventListener('keydown', this.handleEvent.bind(this));
  }

  handleEvent(event: KeyboardEvent) {
    this.ctrlKey = event.ctrlKey || false;
  }

  ngOnDestroy() {
    this.doc.removeEventListener('keydown', this.handleEvent);
  }

  ngOnInit(): void {
    this.menuOptions = this.config.menu;
  }

  clickItem(e: any) {
    if (e.node.children) {
      if (!e.node.expanded) {
        this.treeView?.instance.expandItem(e.node.key);
      } else {
        this.treeView?.instance.collapseItem(e.node.key);
      }
    }

    const option = e.itemData as MenuItemModel;

    if (!option.component) {
      return;
    }

    this.openPage(option.component, option.text, option.tabText, option.params);

    const newPage = new PageItem(
      option.tabText ?? option.text,
      option.component,
      option.params
    );
    this.pageManagerService.OpenPage(newPage, this.ctrlKey);

    this.ctrlKey = false;
  }

  keydown(e: KeyboardEvent) {
    console.log(e);
  }

  openPage(
    component: any,
    text: string,
    tabText: string | undefined = undefined,
    params: any = undefined
  ) {
    const newPage = new PageItem(tabText ?? text, component, params);
    this.pageManagerService.OpenPage(newPage, this.ctrlKey);

    this.ctrlKey = false;

    if (this.screenService.isMobile) {
      this.menuService.menuClose();
    }
  }

  buttonReportsAction() {
    this.openPage(StatusPanel, this.translate.instant('status'));
  }

  buttonScreenLockAction() {
    this.authService.logout();
    this.router.navigateByUrl('/login');
  }

  buttonNewWindowAction() {
    window.open('/', '_blank');
  }

  closeMenu() {
    this.menuService.menuClose();
  }
}
