import { Observable } from 'rxjs';
import {
  NotesParam,
  NotesResult,
} from './gui/data-grid-wrapper/data-grid-wrapper-notes.model';

export type NotesGetFunction = (param: NotesParam) => Observable<NotesResult>;
export type NotesSaveFunction = (param: NotesParam) => Observable<object>;

export class CONFIG {
  public static API_URL = 'localhost';
  public static PLATFORM = {};
  /**
   * Funkcja odczytująca notatkę (wykorzystywana w datagrid)
   */
  public static SERVICE_NOTES_GET: NotesGetFunction;
  /**
   * Funkcja zapisująca notatkę  (wykorzystywana w datagrid)
   */
  public static SERVICE_NOTES_SAVE: NotesSaveFunction;
}
