<xlib-edit-form-wrapper
  #editFormWrapper
  [title]="'customer - edit' | translate"
>
  <dx-form [formData]="editObject" #editForm>
    <!-- GROUP Dane podstawowe START ---------------------------------------------------------------------------------->
    <dxi-item
      itemType="group"
      caption="{{ 'basic info' | translate }}"
      [colCount]="2"
    >
      <dxi-item
        dataField="number"
        [label]="{ text: 'customer number' | translate }"
      >
        <dxi-validation-rule
          type="required"
          message="{{ 'customer number is required' | translate }}"
        ></dxi-validation-rule>
      </dxi-item>

      <dxi-item itemType="empty"></dxi-item>

      <dxi-item
        dataField="companyName"
        [label]="{ text: 'company name' | translate }"
      >
      </dxi-item>
      <dxi-item
        dataField="vatNumber"
        [label]="{ text: 'vat number' | translate }"
      >
      </dxi-item>
      <dxi-item
        dataField="firstName"
        [label]="{ text: 'first name' | translate }"
      >
      </dxi-item>
      <dxi-item
        dataField="lastName"
        [label]="{ text: 'last name' | translate }"
      >
      </dxi-item>

      <dxi-item
        dataField="addressLine1"
        [label]="{ text: 'address line 1' | translate }"
      >
      </dxi-item>

      <dxi-item
        dataField="addressLine2"
        [label]="{ text: 'address line 2' | translate }"
      >
      </dxi-item>

      <dxi-item dataField="postCode" [label]="{ text: 'postCode' | translate }">
      </dxi-item>

      <dxi-item dataField="city" [label]="{ text: 'city' | translate }">
      </dxi-item>

      <dxi-item
        dataField="phone"
        [label]="{ text: 'phone' | translate }"
      ></dxi-item>
      <dxi-item
        dataField="email"
        [label]="{ text: 'email' | translate }"
      ></dxi-item>

      <dxi-item dataField="country" [label]="{ text: 'country' | translate }">
      </dxi-item>
      <dxi-item itemType="empty"></dxi-item>

      <dxi-item
        dataField="differentShippingAddress"
        [label]="{ text: 'different shipping address' | translate }"
        editorType="dxCheckBox"
      >
      </dxi-item>
    </dxi-item>
    <!-- GROUP Dane podstawowe END ------------------------------------------------------------------------------------>

    <!-- GROUP Adres dostawy START ------------------------------------------------------------------------------------>
    <dxi-item
      [visible]="differentShippingAddress"
      itemType="group"
      caption="{{ 'shipping address' | translate }}"
      [colCount]="2"
    >
      <dxi-item
        itemType="button"
        [buttonOptions]="buttonCustomerCopyDataOptions"
        [colSpan]="2"
        location="after"
      >
      </dxi-item>
      <dxi-item
        dataField="shippingCompanyName"
        [label]="{ text: 'company name' | translate }"
        [colSpan]="2"
      >
      </dxi-item>

      <dxi-item
        dataField="shippingFirstName"
        [label]="{ text: 'first name' | translate }"
      >
      </dxi-item>
      <dxi-item
        dataField="shippingLastName"
        [label]="{ text: 'last name' | translate }"
      >
      </dxi-item>
      <dxi-item
        dataField="shippingAddressLine1"
        [label]="{ text: 'address line 1' | translate }"
      >
      </dxi-item>
      <dxi-item
        dataField="shippingAddressLine2"
        [label]="{ text: 'address line 2' | translate }"
      >
      </dxi-item>
      <dxi-item
        dataField="shippingPostCode"
        [label]="{ text: 'postCode' | translate }"
      >
      </dxi-item>
      <dxi-item dataField="shippingCity" [label]="{ text: 'city' | translate }">
      </dxi-item>

      <dxi-item
        dataField="shippingPhone"
        [label]="{ text: 'phone' | translate }"
      ></dxi-item>
      <dxi-item
        dataField="shippingEmail"
        [label]="{ text: 'email' | translate }"
      ></dxi-item>

      <dxi-item
        dataField="shippingCountry"
        [label]="{ text: 'country' | translate }"
      >
      </dxi-item>
    </dxi-item>
    <!-- GROUP Adres dostawy END -------------------------------------------------------------------------------------->

    <!-- GROUP Typ kontrahenta START ---------------------------------------------------------------------------------->
    <dxi-item itemType="group" caption="{{ 'customer type' | translate }}">
      <dxi-item
        dataField="supplier"
        editorType="dxCheckBox"
        [label]="{ text: 'supplier' | translate }"
      >
      </dxi-item>

      <dxi-item
        dataField="purchaser"
        editorType="dxCheckBox"
        [label]="{ text: 'purchaser' | translate }"
      >
      </dxi-item>
    </dxi-item>
    <!-- GROUP Typ kontrahenta END ------------------------------------------------------------------------------------>
  </dx-form>
</xlib-edit-form-wrapper>
