import { Directive, HostBinding } from '@angular/core';

@Directive({
  selector: '[dx-color-inverse-light]',
})
export class AddClassDxColorInverseLightDirective {
  @HostBinding('class')
  elementClass = 'elements-theme-border-color-as-background-color';

  constructor() {}
}
