api: {{ apiSettings }} <br />
app: {{ appVersion }}
<!--
<div class="container">
  <div class="info">
    <h1>ucanlo</h1>
    <p>{{ "application description" | translate }}</p>
    <p>{{ "application copyright information" | translate }}</p>

    <table>
      <tr>
        <td>{{ "version" | translate }}</td>
        <td>{{ appVersion }}</td>
      </tr>
      <tr>
        <td>{{ "api settings" | translate }}</td>
        <td>{{ apiSettings }}</td>
      </tr>
    </table>
  </div>
  <div class="image"></div>
</div>
-->
