import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { CustomerModel } from '../../../../../../bll/models/customers/customer.model';

@Component({
  selector: 'app-customer-list-select',
  templateUrl: './customer-list-select.component.html',
  styleUrls: ['./customer-list-select.component.scss'],
})
export class CustomerListSelectComponent implements OnInit {
  constructor() {}

  popupVisible = false;

  ngOnInit(): void {}

  @Output()
  onSelectCustomer = new EventEmitter<number | null>();

  show() {
    this.popupVisible = true;
  }
  hide() {
    this.popupVisible = false;
  }

  popupGeCustomerAfterSelect(customerId: number | null) {
    console.log('>>>', customerId);
    this.popupVisible = false;
    this.onSelectCustomer.emit(customerId);
  }
}
