import { DxDataGridComponent } from 'devextreme-angular';

/**
 * Zwraca wartość VisibleIndex dla kolumny o podłączonej z polem dataField w źródle danych
 * @param dataGrid
 * @param dataFieldName
 */
export function getColumnVisibleIndexByFieldName(
  dataGrid: DxDataGridComponent,
  dataFieldName: string
): number {
  const column = dataGrid.instance
    .getVisibleColumns()
    .find((p) => p.dataField === dataFieldName);

  if (column) {
    // @ts-ignore
    return dataGrid.instance.getVisibleColumnIndex(column.index);
  } else {
    //jeżeli nie ma kolumny, zwracamy ostatnią.
    return dataGrid.instance.getVisibleColumnIndex(dataGrid.columns.length);
  }
}
export function setFocusOnColumnByFieldName(
  dataGrid: any,
  rowIndex: number,
  fieldName: string
) {
  const colIndex = getColumnVisibleIndexByFieldName(dataGrid, fieldName);
  const quantityElement = dataGrid.instance.getCellElement(rowIndex, colIndex);
  dataGrid.instance.focus(quantityElement);
}
