import { Component, Input, OnInit, ViewChild } from '@angular/core';

import { DxTextAreaComponent } from 'devextreme-angular';

@Component({
  selector: 'xlib-data-import',
  templateUrl: './data-import.component.html',
  styleUrls: ['./data-import.component.scss'],
})
export class DataImportComponent implements OnInit {
  @ViewChild('textarea', { static: false })
  public textArea?: DxTextAreaComponent;

  @Input()
  importDataFields: string = '';

  @Input()
  importDataButtonFromClipBoardDescriptionTranslateKey: string = '';

  @Input()
  importDataButtonFromFileDescriptionTranslateKey: string = '';

  dataSource: any;

  isPopupDataImportVisible = false;

  clipboardText: string = '';

  importButtonDisabled = true;

  constructor() {}

  ngOnInit(): void {}

  show() {
    this.isPopupDataImportVisible = true;
  }

  hide() {
    this.isPopupDataImportVisible = false;
  }

  importDataFromClipboard() {
    const columns = this.importDataFields.split(',');
    let data = new Array<any>();
    const lines = this.clipboardText.split('\n');

    lines.forEach((line) => {
      if (!line) {
        return;
      }

      const row = {};
      const rowData = line.split('\t');
      for (let i = 0; i < columns.length; i++) {
        row[columns[i]] = rowData[i];
      }
      data.push(row);
    });

    console.log(data);
  }
}
