<xlib-edit-form-wrapper #editFormWrapper [title]="'product - edit' | translate">
  <dx-form #editForm [formData]="product">
    <div *dxTemplate="let data of 'inventoryMethodItemTemplate'">
      <div>
        <strong>{{ data.inventoryMethod }}</strong>
      </div>
      <div class="inventory-method-information">
        {{ data.inventoryMethodInfo }}
      </div>
    </div>

    <dxi-item itemType="tabbed">
      <dxi-tab title="{{ 'data' | translate }} ">
        <dxi-item caption="{{ 'basic info' | translate }}" itemType="group">
          <dxi-item
            [label]="{ text: 'product number' | translate }"
            dataField="number"
          >
            <dxi-validation-rule
              message="{{ 'product number is required' | translate }}"
              type="required"
            ></dxi-validation-rule>
          </dxi-item>
          <dxi-item [label]="{ text: 'name' | translate }" dataField="name">
            <dxi-validation-rule
              message="{{ 'product name is required' | translate }}"
              type="required"
            ></dxi-validation-rule>
          </dxi-item>
          <dxi-item
            [label]="{ text: 'barcode' | translate }"
            dataField="barcode"
          ></dxi-item>
          <dxi-item
            [editorOptions]="{
              items: inventoryMethodTypes,
              searchEnabled: true,
              displayExpr: 'inventoryMethod',
              valueExpr: 'id',
              itemTemplate: 'inventoryMethodItemTemplate'
            }"
            [label]="{ text: 'inventory method' | translate }"
            dataField="defaultInventoryMethod"
            editorType="dxSelectBox"
            helpText="{{ '#prop.product.defaultInventoryMethod' | translate }}"
          >
          </dxi-item>
        </dxi-item>
        <dxi-item
          caption="{{ 'additional features' | translate }}"
          itemType="group"
        >
          <dxi-item
            [label]="{ text: 'serial number' | translate }"
            dataField="hasSerialNumberOnOutput"
            editorType="dxCheckBox"
            helpText="{{ '#prop.product.hasSerialNumberOnOutput' | translate }}"
          ></dxi-item>
          <dxi-item
            [label]="{ text: 'expiration date' | translate }"
            dataField="hasExpirationDate"
            editorType="dxCheckBox"
            helpText="{{ '#prop.product.hasExpirationDate' | translate }}"
          ></dxi-item>
          <dxi-item
            [label]="{ text: 'many expiration date in location' | translate }"
            dataField="manyExpirationDateInLocation"
            editorType="dxCheckBox"
            helpText="{{
              '#prop.product.manyExpirationDateInLocation' | translate
            }}"
          ></dxi-item>
          <dxi-item
            [label]="{ text: 'alone in location' | translate }"
            dataField="aloneInLocation"
            editorType="dxCheckBox"
            helpText="{{ '#prop.product.aloneInLocation' | translate }}"
          ></dxi-item>
        </dxi-item>
      </dxi-tab>

      <dxi-tab title="{{ 'additional barcodes' | translate }}">
        <dx-data-grid
          (onInitNewRow)="newBarcode($event)"
          [dataSource]="barcodes"
          keyExpr="id"
        >
          <dxo-group-panel [visible]="false"></dxo-group-panel>
          <dxo-column-chooser [enabled]="false"></dxo-column-chooser>
          <dxo-search-panel [visible]="false"></dxo-search-panel>
          <dxo-editing
            [allowAdding]="true"
            [allowDeleting]="true"
            [allowUpdating]="true"
            [confirmDelete]="false"
            mode="popup"
          >
            <dxo-popup
              [height]="350"
              [shading]="false"
              [showTitle]="true"
              [width]="400"
              dx-group-column-count="1"
              title="{{ 'barcode' | translate }}"
            >
            </dxo-popup>
            <dxo-form [colCount]="1" labelLocation="top">
              <dxi-item
                [label]="{ text: 'barcode' | translate }"
                dataField="barcode"
              >
                <dxi-validation-rule
                  message="{{ 'barcode is required' | translate }}"
                  type="required"
                >
                </dxi-validation-rule>
              </dxi-item>
              <dxi-item
                [editorOptions]="{
                  min: 1,
                  max: 10000,
                  showSpinButtons: true,
                  format: '#,##0'
                }"
                [label]="{ text: 'barcode quantity' | translate }"
                dataField="quantity"
                editorType="dxNumberBox"
              >
                <dxi-validation-rule
                  message="{{ 'quantity is required' | translate }}"
                  type="required"
                >
                </dxi-validation-rule>
              </dxi-item>
            </dxo-form>
          </dxo-editing>
          <dxi-column dataField="barcode"></dxi-column>
          <dxi-column dataField="quantity"></dxi-column>
        </dx-data-grid>
      </dxi-tab>
      <!--zdjęcia tab ---------------------------------------------------------------------------------------------->
      <dxi-tab title="{{ 'pictures' | translate }}">
        <dxi-item
          [label]="{ text: 'image url' | translate }"
          dataField="imageUrl"
        >
        </dxi-item>

        <dxi-item dataField="imageUrl" [template]="'imageUrlTemplate'">
          <dxo-label [visible]="false"></dxo-label>
        </dxi-item>

        <dxi-item
          [label]="{ text: 'image package url' | translate }"
          dataField="imagePackageUrl"
        >
        </dxi-item>

        <dxi-item
          dataField="imagePackageUrl"
          [template]="'imagePackageUrlTemplate'"
        >
          <dxo-label [visible]="false"></dxo-label>
        </dxi-item>
      </dxi-tab>
    </dxi-item>

    <div *dxTemplate="let item of 'imageUrlTemplate'">
      <div *ngIf="product?.imageUrl" class="image-preview">
        <a href="{{ product?.imageUrl }}" target="_blank">
          <img src="{{ product?.imageUrl }}" />
        </a>
      </div>
    </div>
    <div *dxTemplate="let item of 'imagePackageUrlTemplate'">
      <div *ngIf="product?.imageUrl" class="image-preview">
        <a href="{{ product?.imagePackageUrl }}" target="_blank">
          <img
            src="{{ product?.imagePackageUrl }}"
            (error)="onImgError($event)"
          />
        </a>
      </div>
    </div>
  </dx-form>
</xlib-edit-form-wrapper>
