import {
  AfterViewInit,
  Component,
  ComponentFactory,
  ComponentFactoryResolver,
  ComponentRef,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { delay } from 'rxjs/operators';
import { LoadingIndicatorService } from '../../../../shared/services/loading-indicator.service';
import { Debounce } from '../../../../../../../../xlibs/src/helpers/debounce-helper';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-tab-page-wrapper-container',
  templateUrl: './tab-page-wrapper.component.html',
  styleUrls: ['./tab-page-wrapper.component.scss'],
})
export class TabPageWrapperComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  @Input()
  myComponent: any;
  @Input()
  params: any;

  @Input()
  height: any;

  @ViewChild('container1', { read: ViewContainerRef }) container?: any;
  componentRef?: ComponentRef<any>;

  @ViewChild('tabForm') tabForm: ElementRef | null = null;

  constructor(
    private resolver: ComponentFactoryResolver,
    private loadingIndicatorService: LoadingIndicatorService,
    private renderer: Renderer2
  ) {}

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.createComponent(this.myComponent, this.params);
    });
  }
  createComponent(type: any, params: {}): void {
    this.container.clear();
    const factory = this.resolver.resolveComponentFactory(type);

    this.componentRef = this.container.createComponent(factory);

    if (this.componentRef) {
      Object.assign(this.componentRef.instance, params);
      this.componentRef.location.nativeElement.style = `min-width: 100%; display:block; height: 100%;`;
      this.componentRef.changeDetectorRef.detectChanges();
    }
  }

  ngOnDestroy(): void {
    this.componentRef?.destroy();
  }

  ngOnInit(): void {
    this.loadingIndicatorServiceInit();
  }

  loading = false;

  loadingIndicatorServiceInit(): void {
    this.loadingIndicatorService.loadingSub
      .pipe(delay(0)) // This prevents a ExpressionChangedAfterItHasBeenCheckedError for subsequent requests
      .subscribe((loading) => {
        this.loading = loading;
      });
  }

  changeHeight(height: number) {
    // return;
    // this.renderer.setStyle(
    //   this.tabForm!.nativeElement,
    //   'height',
    //   `${height}px`
    // );
  }
}
