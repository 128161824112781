import { Component, OnInit, ViewChild } from '@angular/core';
import { ProductsViewService } from '../../../../../../bll/services/products/products-view.service';

import { TranslateService } from '@ngx-translate/core';
import { odataCustomStoreForLookup } from '../../../../../../../xlibs/src/bll/services/lookup-repository-odata';
import {
  ActionType,
  EditActionParameterModel,
} from '../../../../../../../xlibs/src/gui/edit-form-wrapper/edit-action-parameter-model';
import { DxDataGridComponent } from 'devextreme-angular';
import { LocationsViewService } from '../../../../../../bll/services/layout/locations-view.service';
import {
  FallbackDocumentModel,
  FallbackDocumentType,
} from '../../../../../../bll/models/fallback-procedures/falback-document.model';
import { FallbackItemModel } from '../../../../../../bll/models/fallback-procedures/fallback-item.model';
import { EditFormWrapperComponent } from '../../../../../../../xlibs/src/gui/edit-form-wrapper/edit-form-wrapper.component';
import { FallbackDeliveryService } from '../../../../../../bll/services/fallback-procedures/fallback-delivery.service';

@Component({
  selector: 'app-fallback-delivery',
  templateUrl: './fallback-delivery.component.html',
  styleUrls: ['./fallback-delivery.component.scss'],
})
export class FallbackDeliveryComponent implements OnInit {
  @ViewChild('editFormWrapper')
  editFormWrapper: EditFormWrapperComponent | null = null;

  deliveryEditObject: FallbackDocumentModel = {
    items: new Array<FallbackItemModel>(),
    type: FallbackDocumentType.Delivery,
  };

  lookupProductOptions = {};
  lookupLocationOptions = {};

  productGridLookupDataSource: any;
  locationGridLookupDataSource: any;

  constructor(
    private productService: ProductsViewService,
    private locationService: LocationsViewService,
    private translate: TranslateService,
    private fallbackService: FallbackDeliveryService
  ) {
    this.controlsInit();
  }

  ngOnInit(): void {}

  ngAfterViewInit() {
    this.editFormWrapper?.init(this.deliveryEditObject, null, () =>
      this.save()
    );
  }

  controlsInit() {
    this.productGridLookupDataSource = odataCustomStoreForLookup(
      'productView',
      {
        selectFields: ['id', 'name', 'barcode', 'number'],
      }
    );

    this.locationGridLookupDataSource = odataCustomStoreForLookup(
      'locationView',
      {
        selectFields: ['id', 'name', 'barcode'],
      }
    );

    this.lookupProductOptions = {
      dataSource: this.productService.odata({
        fields: ['id', 'name', 'barcode', 'number'],
      }),
      valueExpr: 'id',
      displayExpr: 'name',
      searchExpr: ['_TS'],
      itemTemplate: 'listProductItem',
    };

    this.lookupLocationOptions = {
      dataSource: this.locationService.odata({
        fields: ['id', 'name', 'barcode'],
        filter:
          "blocked eq false and zoneType in ('StorageHigh', 'StorageLow')",
      }),
      valueExpr: 'id',
      displayExpr: 'name',
      searchExpr: ['_TS'],
      itemTemplate: 'listLocationItem',
    };
  }

  dataGridOnToolbarPreparing(e) {
    e.toolbarOptions.items.forEach((p) => {
      p.visible = false;
    });
  }

  save() {
    this.fallbackService.add(this.deliveryEditObject).subscribe((p) => {
      this.editFormWrapper?.close();
    });
  }
}
