import { Guid } from 'guid-typescript';

export class PageItem {
  id: Guid;
  name: string;
  component: any;
  params: {};

  constructor(name: string, component: any, params = {}) {
    this.id = Guid.create();
    this.name = name;
    this.component = component;
    this.params = params;
  }
}
