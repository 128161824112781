<dx-popup
  #popupNotes
  [(visible)]="isPopupNotesVisible"
  [title]="'notes' | translate"
  (onHiding)="popupNotesOnHiding($event)"
  (onShown)="onShown($event)"
>
  <p>{{ "entity notes" | translate }}</p>
  <dx-text-area
    #textarea
    [height]="'93%'"
    [(value)]="notes"
    (onKeyDown)="onKeyDown($event)"
    valueChangeEvent="keyup"
  >
  </dx-text-area>
</dx-popup>
