<as-split
  direction="vertical"
  [gutterSize]="6"
  (dragEnd)="onSplitterDragEnd($event)"
>
  <as-split-area [size]="40">
    <div class="headers">
      <xlib-data-grid-wrapper
        #dataGridWrapperHeader
        (buttonsAction)="gridViewAction($event)"
        [actions]="accountingTransactionActions"
        [showImportDataButton]="true"
        importDataButtonHintTranslateKey="import products"
        importDataButtonFromClipBoardDescriptionTranslateKey="import product from clipboard"
        importDataButtonFromFileDescriptionTranslateKey="import product from file"
        importDataFields="id,quantity"
      >
        <dx-data-grid
          #dataGridHeader
          (onContentReady)="onContentReady($event)"
          (onFocusedRowChanged)="onFocusedRowChanged($event.row.data.id)"
          [dataSource]="dataSourceAccountingTransactions"
          id="grid-accounting-transaction-list"
        >
          <dxi-column
            caption="{{ 'type' | translate }}"
            dataField="type"
            cellTemplate="cellTemplateAccountingTransactionEnumType"
          ></dxi-column>
          <div
            *dxTemplate="
              let cell of 'cellTemplateAccountingTransactionEnumType'
            "
          >
            {{ cell.text | enumTranslate: "AccountingTransactionType" }}
          </div>

          <dxi-column
            caption="{{ 'status' | translate }}"
            dataField="status"
            cellTemplate="cellTemplateAccountingTransactionEnumStatus"
          ></dxi-column>
          <div
            *dxTemplate="
              let cell of 'cellTemplateAccountingTransactionEnumStatus'
            "
          >
            {{ cell.text | enumTranslate: "AccountingTransactionStatusType" }}
          </div>

          <dxi-column
            caption="{{ 'accounting transaction number' | translate }}"
            dataField="number"
          ></dxi-column>
          <dxi-column
            caption="{{ 'date' | translate }}"
            dataField="date"
            format="yyyy-MM-dd"
          ></dxi-column>

          <dxi-column
            [calculateCellValue]="
              accountingTransactionCustomerNameCalculateCellValue
            "
            caption="{{ 'customer name' | translate }}"
            dataField="customerName"
          ></dxi-column>
          <dxi-column
            caption="{{ 'customer address' | translate }}"
            dataField="customerAddress"
          ></dxi-column>
          <dxi-column
            caption="{{ 'vat number' | translate }}"
            dataField="customerVatNumber"
          ></dxi-column>
          <dxi-column
            caption="{{ 'email' | translate }}"
            dataField="customerEmail"
          ></dxi-column>
          <dxi-column
            caption="{{ 'phone' | translate }}"
            dataField="customerPhone"
          ></dxi-column>

          <dxi-column
            caption="{{ 'progress' | translate }}"
            cellTemplate="progressTemplate"
            dataField="progress"
          ></dxi-column>

          <div *dxTemplate="let data of 'progressTemplate'">
            <dx-progress-bar
              [max]="1"
              [min]="0"
              [value]="data.data.quantityCompletedPercent"
              [showStatus]="false"
            >
            </dx-progress-bar>
            <div class="progress-bar-info">
              {{ data.data.quantityCompleted + "/" + data.data.quantity }}
            </div>
          </div>
        </dx-data-grid>
      </xlib-data-grid-wrapper>
    </div>
  </as-split-area>
  <as-split-area [size]="60">
    <!-- DETAILS ------------------------------------------------------------------------------------------------------------------------------------->

    <dx-tab-panel class="details">
      <!-- ITEMS AND TASK -------------------------------------------------------------------------------------------------------------------------->

      <dxi-item title="{{ 'items' | translate }}" style="padding: 6px">
        <xlib-data-grid-wrapper
          #dataGridWrapperDetailTaskInfo
          (buttonsAction)="gridViewAction($event)"
          [showActionEditButtons]="false"
          [title]="'document details with task info' | translate"
        >
          <dx-data-grid
            #dataGridDetail
            [dataSource]="dataSourceAccountingTransactionsDetailsAndActions"
            id="grid-accounting-transaction-detail-list"
          >
            <dxi-column
              caption="{{ 'product number' | translate }}"
              dataField="productNumber"
            ></dxi-column>
            <dxi-column
              caption="{{ 'name' | translate }}"
              dataField="productName"
            ></dxi-column>
            <dxi-column
              caption="{{ 'quantity in document' | translate }}"
              dataField="quantity"
            ></dxi-column>
            <dxi-column
              caption="{{ 'quantity in stock' | translate }}"
              dataField="quantityCompleted"
            ></dxi-column>

            <dxi-column
              caption="{{ 'progress' | translate }}"
              dataField="quantityCompletedPercent"
            >
              <dxo-format type="percent"></dxo-format>
            </dxi-column>

            <dxo-master-detail
              [enabled]="true"
              template="detail"
            ></dxo-master-detail>
            <div *dxTemplate="let item of 'detail'">
              <div class="master-detail-caption">
                <div class="info">
                  {{ "inventory movement - details" | translate }}
                </div>
                <div class="actions">
                  <dx-button
                    icon="search"
                    stylingMode="text"
                    type="default"
                    text="{{ 'show in new window' | translate }}"
                    (click)="openInventoryMovementInNewWindow(item.data.id)"
                  >
                  </dx-button>
                </div>
              </div>
              <app-inventory-action-list
                [accountTransactionDetailId]="item.data.id"
                viewMode="raw"
              ></app-inventory-action-list>
            </div>
          </dx-data-grid>
        </xlib-data-grid-wrapper>
      </dxi-item>

      <!-- STORAGE INFO----------------------------------------------------------------------------------------------------------------------------->

      <dxi-item title="{{ 'storage info' | translate }}" style="padding: 6px">
        <xlib-data-grid-wrapper
          #dataGridWrapperDetailStorageInfo
          (buttonsAction)="gridViewAction($event)"
          [showActionEditButtons]="false"
          [title]="'document details with storage info' | translate"
          viewMode="inTabView"
        >
          <dx-data-grid
            #dataGridDetail
            [dataSource]="dataSourceAccountingTransactionsDetailsAndStorageInfo"
            id="grid-accounting-transaction-detail-storage-info-list"
          >
            <dxi-column
              caption="{{ 'product number' | translate }}"
              dataField="productNumber"
            ></dxi-column>
            <dxi-column
              caption="{{ 'name' | translate }}"
              dataField="productName"
            ></dxi-column>
            <dxi-column
              caption="{{ 'quantity in document' | translate }}"
              dataField="quantity"
            ></dxi-column>

            <dxi-column
              caption="{{ 'product waiting for process' | translate }}"
              dataField="waiting"
            ></dxi-column>

            <dxi-column
              caption="{{ 'product in user' | translate }}"
              dataField="inUser"
            ></dxi-column>

            <dxi-column
              caption="{{ 'product in location' | translate }}"
              dataField="inLocation"
            ></dxi-column>

            <dxo-master-detail
              [enabled]="true"
              template="detail"
            ></dxo-master-detail>
            <div *dxTemplate="let item of 'detail'">
              <div class="master-detail-caption">
                <div class="info">
                  {{ "storage info" | translate }}
                </div>
                <div class="actions">
                  <dx-button
                    icon="search"
                    stylingMode="text"
                    type="default"
                    text="{{ 'show in new window' | translate }}"
                    (click)="openInventoryStorageInfoInNewWindow(item.data.id)"
                  >
                  </dx-button>
                </div>
              </div>
              <app-inventory-action-storage-info-list
                [accountTransactionDetailId]="item.data.id"
                viewMode="raw"
              ></app-inventory-action-storage-info-list>
              <!--        <detail-grid [key]="employee.key"></detail-grid>-->
            </div>
          </dx-data-grid>
        </xlib-data-grid-wrapper>
      </dxi-item>

      <!-- DOCUMENT STATUS HISTORY ----------------------------------------------------------------------------------------------------------------->
      <dxi-item
        title="{{ 'status document history' | translate }}"
        style="padding: 6px"
      >
        <xlib-data-grid-wrapper
          #dataGridWrapperStatusHistory
          (buttonsAction)="gridViewAction($event)"
          [showActionEditButtons]="false"
          [title]="'status document history' | translate"
          viewMode="inTabView"
          storageKeySufix="grid-accounting-transaction-status-history"
        >
          <dx-data-grid
            #dataGridDetail
            [dataSource]="dataSourceAccountingTransactionsStatusHistoryView"
            id="grid-accounting-transaction-status-history"
          >
            <dxi-column
              caption="{{ 'date' | translate }}"
              dataField="date"
              dataType="datetime"
            ></dxi-column>

            <dxi-column
              caption="{{ 'status from' | translate }}"
              dataField="statusFrom"
              cellTemplate="cellTemplateAccountingTransactionEnumStatus"
            ></dxi-column>
            <div
              *dxTemplate="
                let cell of 'cellTemplateAccountingTransactionEnumStatus'
              "
            >
              {{ cell.text | enumTranslate: "AccountingTransactionStatusType" }}
            </div>

            <dxi-column
              caption="{{ 'status to' | translate }}"
              dataField="statusTo"
              cellTemplate="cellTemplateAccountingTransactionEnumStatus"
            ></dxi-column>

            <dxi-column
              caption="{{ 'user' | translate }}"
              dataField="userName"
            ></dxi-column>
            <dxi-column
              caption="{{ 'ip address' | translate }}"
              dataField="userIp"
            ></dxi-column>
          </dx-data-grid>
        </xlib-data-grid-wrapper>
      </dxi-item>
    </dx-tab-panel>
  </as-split-area>
</as-split>
