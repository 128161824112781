/**
 * Obsługa tłumaczeń dla typów enum
 * Wymagana inicjacja w AppComponent
 *
 * export class AppComponent {
 *   constructor(
 *       private enumService: EnumInfoService,
 *       private enumsInfoRepositoryService: EnumsInfoRepositoryService
 *   ) {
 *       enumsInfoRepositoryService.getAll().subscribe((enums) => {
 *           enumService.init(enums);
 *       });
 *   }
 * }
 */

import {EnumsInfoRepositoryService} from "projects/ucanlo/bll/services/_sys/enums-info-repository.service";
import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {EnumInfoModel} from 'projects/xlibs/src/enums/enum-info.model';

@Injectable({
    providedIn: 'root',
})
export class EnumInfoService {
    public enums: Array<EnumInfoModel> = new Array<EnumInfoModel>();

    constructor(private translate: TranslateService) {}

    init(enums: EnumInfoModel[]) {
        this.enums = enums;
    }


    /**
     * Zwraca przetłumaczoną wartość enum na podstawie typu
     */
    getTranslatedValue(type: string, enumValue: string) {
        return this.translate.instant(`#enum.${type}.${enumValue}`);
    }

    /**
     * Zwraca datasource dla komponentów biblioteki dxextreme wraz z kolekcją elementów typu enum dla podanego typu
     */
    getDataSource(type: string) {
        const enumValue = this.enums.find((p) => p.type === type);

        if (!enumValue) {
            return null;
        }
        return {
            dataSource: {
                store: {
                    type: 'array',
                    data: enumValue.values.map((p) => {
                        return {
                            id: p,
                            name: this.translate.instant(`#enum.${type}.${p}`),
                        };
                    }),
                },
            },
            valueExpr: 'id',
            displayExpr: 'name',
        };
    }


}
