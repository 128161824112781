import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DevExtremeModule } from 'devextreme-angular';
import { TranslateModule } from '@ngx-translate/core';
import { DataGridWrapperComponent } from './data-grid-wrapper/data-grid-wrapper.component';
import { EditFormWrapperComponent } from 'projects/xlibs/src/gui/edit-form-wrapper/edit-form-wrapper.component';
import { MessageBoxComponent } from './message-box/message-box.component';
import { MessageBoxService } from 'projects/xlibs/src/gui/message-box/services/message-box.service';
import { ListWrapperComponent } from './list-wrapper/list-wrapper.component';
import { HtmlTextEditorComponent } from './html-text-editor/html-text-editor.component';
import { NotesComponent } from './notes/notes.component';
import { ActionButtonLargeComponent } from './action-button/action-button-large/action-button-large.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ActionButtonSmallComponent } from './action-button/action-button-small/action-button-small.component';
import { DataImportComponent } from './data-import/data-import.component';
import { AngularResizeEventModule } from 'angular-resize-event';

@NgModule({
  declarations: [
    MessageBoxComponent,
    DataGridWrapperComponent,
    EditFormWrapperComponent,
    ListWrapperComponent,
    HtmlTextEditorComponent,
    NotesComponent,
    ActionButtonLargeComponent,
    ActionButtonSmallComponent,
    DataImportComponent,
  ],
  imports: [
    CommonModule,
    DevExtremeModule,
    TranslateModule,
    FontAwesomeModule,
    AngularResizeEventModule,
  ],
  exports: [
    DataGridWrapperComponent,
    EditFormWrapperComponent,
    MessageBoxComponent,
    ListWrapperComponent,
  ],
})
export class GuiModule {}
