import { Component, OnInit } from '@angular/core';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-sys-info',
  templateUrl: './sys-info.component.html',
  styleUrls: ['./sys-info.component.scss'],
})
export class SysInfoComponent implements OnInit {
  get appVersion(): string {
    return environment.appVersion;
  }

  get apiSettings(): string {
    return environment.API_URL;
  }

  constructor() {}

  ngOnInit(): void {}
}
