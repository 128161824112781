import { Component, Input, OnInit } from '@angular/core';
import {
  faClone,
  faSquarePlus,
  faSquareMinus,
  faPenToSquare,
} from '@fortawesome/free-regular-svg-icons';

@Component({
  selector: 'xlib-action-button-small',
  templateUrl: './action-button-small.component.html',
  styleUrls: ['./action-button-small.component.css'],
})
export class ActionButtonSmallComponent implements OnInit {
  constructor() {}

  @Input()
  type: 'new' | 'edit' | 'delete' | 'copy' = 'new';

  iconNew = faSquarePlus;
  iconEdit = faPenToSquare;
  iconDelete = faSquareMinus;
  iconCopy = faClone;

  faIcon: any;

  text: string = '';
  description: string = '';

  ngOnInit(): void {
    switch (this.type) {
      case 'new':
        this.faIcon = this.iconNew;
        this.text = 'Dodaj';
        this.description = 'new';
        break;
      case 'edit':
        this.faIcon = this.iconEdit;
        this.text = 'Popraw';
        this.description = 'edit';
        break;
      case 'delete':
        this.faIcon = this.iconDelete;
        this.text = 'Usuń';
        this.description = 'new';
        break;
      case 'copy':
        this.faIcon = this.iconCopy;
        this.text = 'Kopiuj';
        this.description = 'new';
        break;
      default:
        this.faIcon = this.iconNew;
        this.text = 'new';
        this.description = 'new';
        break;
    }
  }
}
