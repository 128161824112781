import { Component, OnInit, ViewChild } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { DataGridWrapperComponent } from 'projects/xlibs/src/gui/data-grid-wrapper/data-grid-wrapper.component';
import { ProductInLocationsService } from 'projects/ucanlo/bll/services/store/product-in-locations.service';
import { Debounce } from 'projects/xlibs/src/helpers/debounce-helper';

@Component({
  selector: 'app-product-in-location-by-product-list',
  templateUrl: './product-in-location-by-product-list.component.html',
  styleUrls: ['./product-in-location-by-product-list.component.scss'],
})
export class ProductInLocationByProductListComponent implements OnInit {
  dataSource: any;
  dataSourceDetail: any;

  @ViewChild('gridWrapperDetails')
  gridWrapperDetails: DataGridWrapperComponent | null = null;

  constructor(private repository: ProductInLocationsService) {
    this.dataSource = repository.odataProductInLocationByProductView();
  }

  ngOnInit(): void {}

  onFocusedRowChanged(e: any) {
    console.log(e);
    this.detailsRefresh(e.row.data.id, e.row.data.name);
  }

  @Debounce(environment.masterDetailRefreshTimeInMs)
  detailsRefresh(productId: number, productName: string) {
    this.dataSourceDetail = this.repository.odataProductInLocationView(
      null,
      productId
    );
    this.gridWrapperDetails!.title = productName;
  }
}
