import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

import * as _ from 'lodash';
import { PageItem } from 'projects/xlibs/src/gui/page-manager/page-item';
import { EditActionParameterModel } from 'projects/xlibs/src/gui/edit-form-wrapper/edit-action-parameter-model';

@Injectable({
  providedIn: 'root',
})
export class PageManagerService {
  pages: PageItem[] = [];

  private selectedPageIndexSource = new BehaviorSubject<number>(0);
  onChangeSelectedIndexPage = this.selectedPageIndexSource.asObservable();

  constructor() {}

  private _selectedPageIndex: number = 0;

  get selectedPageIndex(): number {
    return this._selectedPageIndex;
  }

  get selectedPage(): PageItem {
    return this.pages[this._selectedPageIndex];
  }

  set selectedPageIndex(value: number) {
    this._selectedPageIndex = value;
    this.selectedPageIndexSource.next(value);
  }

  /**
   * Otwiera nową stronę
   * @param newPage
   * @param asDuplicate
   *  true: otwiera zawsze nową stronę,
   *  false: przełącza na istniejącą stronę lub jeśli nie była otwarta, otwiera nową
   * @constructor
   */
  public OpenPage(newPage: PageItem, asDuplicate: boolean = false) {
    if (!asDuplicate) {
      const existingPageIndex = this.pages.findIndex(
        (p) =>
          p.component === newPage.component &&
          _.isEqual(p.params, newPage.params)
      );

      if (existingPageIndex !== -1) {
        this.selectedPageIndexSource.next(existingPageIndex);
        return;
      }
    }
    this.pages.push(newPage);
    this.selectedPageIndex = this.pages.length - 1;
  }

  public OpenEditFormPage(
    title: string,
    editFormComponent: any,
    editActionParameterModel: EditActionParameterModel | null = null,
    model: any = null
  ) {
    console.log('OpenEditFormPage');

    if (editActionParameterModel && model) {
      editActionParameterModel.model = model;
    }
    this.OpenPage(
      new PageItem(title, editFormComponent, {
        editActionParams: editActionParameterModel,
      }),
      true
    );
  }

  public ClosePage(index: number) {
    this.pages.splice(index, 1);
    this.selectedPageIndex = index - 1;
  }

  public CloseCurrentPage() {
    const currentPageIndex = this.selectedPageIndex;
    this.pages.splice(currentPageIndex, 1);
    this.selectedPageIndex = currentPageIndex - 1;
  }
}
