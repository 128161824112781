import { Injectable } from '@angular/core';
import { BaseRepositoryOdata } from '../../../../xlibs/src/bll/services/base-repository-odata';
import { LocationModel } from '../../models/layout/location.model';
import { InventoryActionModel } from '../../models/inventory-transactions/inventory-action.model';

@Injectable({
  providedIn: 'root',
})
export class InventoryActionsViewService extends BaseRepositoryOdata<
  InventoryActionModel,
  number
> {
  constructor() {
    super('inventoryActionView');
  }
}
