import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ProductModel } from 'projects/ucanlo/bll/models/products/product.model';
import { ProductBarcodeModel } from '../../models/products/product-barcode.model';
import {
  ActionType,
  EditActionParameterModel,
} from 'projects/xlibs/src/gui/edit-form-wrapper/edit-action-parameter-model';
import { TranslateService } from '@ngx-translate/core';
import { BaseRepository } from 'projects/xlibs/src/bll/services/base-repository';
import {
  BaseRepositoryOdata,
  DataSourceParameters,
} from '../../../../xlibs/src/bll/services/base-repository-odata';

@Injectable({
  providedIn: 'root',
})
export class ProductsViewService extends BaseRepositoryOdata<
  ProductModel,
  number
> {
  constructor() {
    super('productView');
  }
}
