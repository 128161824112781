import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { LocationModel } from '../../models/layout/location.model';
import { BaseRepositoryOdata } from 'projects/xlibs/src/bll/services/base-repository-odata';
import { LocationGetByParams } from './locations.params';
import { InventoryActionModel } from '../../models/inventory-transactions/inventory-action.model';

@Injectable({
  providedIn: 'root',
})
export class LocationsService extends BaseRepositoryOdata<
  LocationModel,
  number
> {
  constructor(private translate: TranslateService) {
    super('location');
  }

  /**
   * Zwraca pojedynczą lokalizację wg podanych parametrów
   * @param params
   */
  getByParams(params: LocationGetByParams): Observable<LocationModel> {
    const filter: [any] = [{ barcode: params.barcode }];
    if (params.blocked !== undefined) filter.push({ blocked: params.blocked });
    if (params.zoneType) filter.push({ zoneType: params.zoneType });

    return this.getSingleByQuery({
      filter,
    });
  }
}
