/**
 * dodaje klasę .elements-theme-border-color do elementu. Wykorzystywane np w tworzeniu table (border elements jest fajny, i zależny od Theme)
 */

import { Directive, HostBinding } from '@angular/core';

@Directive({
  selector: '[dx-border]',
})
export class AddClassDxThemeBorderColorDirective {
  @HostBinding('class')
  elementClass = 'elements-theme-border-color';

  constructor() {}
}
