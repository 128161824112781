import { Component, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  ActionType,
  EditActionParameterModel,
} from 'projects/xlibs/src/gui/edit-form-wrapper/edit-action-parameter-model';
import * as _ from 'lodash';
import { DxDataGridComponent, DxPopupComponent } from 'devextreme-angular';
import { ProductListSelectComponent } from '../../products/product-list-select/product-list-select.component';
import { MessageBoxService } from 'projects/xlibs/src/gui/message-box/services/message-box.service';
import { DataGridWrapperComponent } from 'projects/xlibs/src/gui/data-grid-wrapper/data-grid-wrapper.component';
import { LocationsService } from 'projects/ucanlo/bll/services/layout/locations.service';
import { ProductSelectModel } from 'projects/ucanlo/bll/models/products/product-select.model';
import { setFocusOnColumnByFieldName } from 'projects/xlibs/src/gui/dev-extreme/dx-data-grid-column-helper';

@Component({
  selector: 'app-product-localization-add',
  templateUrl: './product-localization-add.component.html',
  styleUrls: ['./product-localization-add.component.scss'],
})
export class ProductLocalizationAddComponent implements OnInit {
  @ViewChild('popupGetProduct') popupGetProduct: DxPopupComponent | null = null;
  @ViewChild('popupGetProductComponent')
  popupGetProductComponent: ProductListSelectComponent | null = null;

  @ViewChild('dataGridProducts')
  dataGridProducts: DxDataGridComponent | null = null;

  @ViewChild('dataGridProductsWrapper')
  dataGridProductsWrapper: DataGridWrapperComponent | null = null;

  locationsDataSource: any;
  products: Array<ProductSelectModel> = new Array<ProductSelectModel>();
  editObject = {};
  dxSelectBoxCheckLocationOptions = {};
  dxItemProductIOptions = {};

  constructor(
    private locationsRepository: LocationsService,
    private translate: TranslateService,
    private messageBox: MessageBoxService
  ) {
    this.locationsDataSource = locationsRepository.odata();
  }

  ngOnInit(): void {
    this.dxSelectBoxCheckLocationOptions = {
      dataSource: this.locationsDataSource,
      valueExpr: 'id',
      displayExpr: 'name',
      searchExpr: ['name', 'barcode'],
      searchMode: 'contains',
      searchEnabled: true,
      itemTemplate: 'selectLocationItem',
    };
  }

  // region wybór produktów popup

  popupProductShow() {
    this.popupGetProduct!.visible = true;
    setTimeout(() => {
      console.log(
        'ProductLocalizationAddComponent.popupProductShow.SelectedProduct',
        this.products
      );

      //this.popupGetProductComponent!.refreshDataGrid(this.products);
    });
  }

  popupGetProductAfterSelect(
    modifiedProducts: Array<ProductSelectModel> | null
  ) {
    if (modifiedProducts) {
      this.addProductsToTransaction(modifiedProducts);
    }
    this.popupGetProduct!.visible = false;
  }

  // endregion

  // region Produkty

  productListButtonsActionDelete(index: number) {
    this.dataGridProducts?.instance.deleteRow(index);
  }

  productListButtonsAction(e: EditActionParameterModel) {
    switch (e.actionType) {
      case ActionType.Add:
        this.productListButtonsActionAdd();
        break;
      case ActionType.Delete:
        this.productListButtonsActionDelete(e.index);
        break;
    }
  }

  productListButtonsActionAdd() {
    this.popupProductShow();
  }

  onRowClick(e: any) {
    setFocusOnColumnByFieldName(this.dataGridProducts!, e.rowIndex, 'quantity');
    console.log(this.products);
  }

  onCellPrepared(e: any) {
    if (e.rowType === 'data' && e.column.dataField === 'expirationDate') {
      if (e.data.productHasExpirationDate) {
        e.cellElement.classList.add('cell-with-required-value');
      }
    }
  }

  savePrepare() {
    this.dataGridProducts?.instance.closeEditCell();

    // czy są produkty?
    if (this.products.length == 0) {
      this.dataGridProductsWrapper!.error = this.translate.instant(
        'select the products you want to add to the location'
      );
      return;
    } else {
      this.dataGridProductsWrapper!.error = null;
    }

    //czy są produkty z wymaganą datą ważności a nie wprowadzoną?
    if (this.products.some((p) => p.hasExpirationDate && !p.expirationDate)) {
      this.messageBox.questionYesNo(
        this.translate.instant(
          'product with expiry date - no expiry date entered'
        ),
        () => this.saveData()
      );
      return;
    }

    this.saveData();
  }

  // endregion

  saveData() {
    alert('zapis!');
  }

  private addProductsToTransaction(
    modifiedProducts: Array<ProductSelectModel>
  ) {
    console.log(
      'ProductLocalizationAddComponent.addProductsToTransaction.modifiedProducts',
      modifiedProducts
    );

    modifiedProducts.forEach((modifiedProduct) => {
      const productInCurrentTransaction = this.products.find(
        (p) => p.id == modifiedProduct.id
      );

      //zmiana ilości
      if (productInCurrentTransaction && modifiedProduct.quantity) {
        productInCurrentTransaction.quantity = modifiedProduct.quantity;
      }

      //zmiana daty ważności
      if (productInCurrentTransaction && modifiedProduct.expirationDate) {
        productInCurrentTransaction.expirationDate =
          modifiedProduct.expirationDate;
      }

      //usunięcie produktu
      if (productInCurrentTransaction && !modifiedProduct.quantity) {
        _.remove(this.products, (p) => p.id === modifiedProduct.id);
      }

      //dodanie produktu
      if (!productInCurrentTransaction && modifiedProduct.quantity) {
        this.products.push({
          id: modifiedProduct.id,
          number: modifiedProduct.number,
          name: modifiedProduct.name,
          quantity: modifiedProduct.quantity,
          expirationDate: modifiedProduct.expirationDate,
          hasExpirationDate: modifiedProduct.hasExpirationDate,
        });
      }
    });

    this.dataGridProducts?.instance.refresh();
  }
}
