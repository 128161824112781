<dx-scroll-view
  [scrollByContent]="true"
  [scrollByThumb]="true"
  showScrollbar="always"
  direction="both"
  id="scrollView"
>
  <div class="container">
    <div class="title dx-theme-accent-as-text-color">
      {{ "data import" | translate }}
    </div>
    <div class="information">
      <p>{{ "data import description" | translate }}</p>
    </div>

    <app-data-import-item
      title="{{ 'products' | translate }}"
      description="{{ 'data import products description' | translate }}"
      sampleFileName="products.xlsx"
      [buttonImportFileText]="'data import products' | translate"
      [exportedObjectType]="dataImportType.products"
    ></app-data-import-item>

    <app-data-import-item
      title="{{ 'locations' | translate }}"
      description="{{ 'data import locations description' | translate }}"
      sampleFileName="locations.xlsx"
      [buttonImportFileText]="'data import locations' | translate"
      [exportedObjectType]="dataImportType.locations"
    ></app-data-import-item>

    <app-data-import-item
      title="{{
        'accounting-accounting-accounting-transactions (delivery/shipping)'
          | translate
      }}"
      description="{{
        'data import accounting-accounting-accounting-transactions description'
          | translate
      }}"
      sampleFileName="transactions.xlsx"
      [buttonImportFileText]="
        'data import accounting-accounting-accounting-transactions' | translate
      "
      [exportedObjectType]="dataImportType.transactions"
    ></app-data-import-item>

    <app-data-import-item
      title="{{ 'stock' | translate }}"
      description="{{ 'data import stock description' | translate }}"
      sampleFileName="stock.xlsx"
      [buttonImportFileText]="'data import stock' | translate"
      [exportedObjectType]="dataImportType.stock"
    ></app-data-import-item>
  </div>
</dx-scroll-view>
