import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import {
  LocationModel,
  LocationZoneType,
} from '../../models/layout/location.model';
import {
  BaseRepositoryOdata,
  DataSourceParameters,
} from 'projects/xlibs/src/bll/services/base-repository-odata';
import { LocationGetByParams } from './locations.params';

@Injectable({
  providedIn: 'root',
})
export class LocationsViewService extends BaseRepositoryOdata<
  LocationModel,
  number
> {
  constructor() {
    super('locationView');
  }

  getByParams(params: LocationGetByParams): Observable<LocationModel> {
    const filter: [any] = [{ barcode: params.barcode }];
    if (params.blocked !== undefined) filter.push({ blocked: params.blocked });
    if (params.zoneType) filter.push({ zoneType: params.zoneType });

    return this.getSingleByQuery({
      filter,
    });
  }
}
