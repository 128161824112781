<div class="container">
  <div class="header">
    <div class="title dx-theme-accent-as-text-color dx-form-group-content">
      {{ title }}
    </div>
    <div class="buttons">
      <dx-button
        (onClick)="efSave()"
        class="button"
        hint="{{ 'save' | translate }}"
        icon="save"
        type="default"
      >
      </dx-button>
      <dx-button
        (onClick)="close(true)"
        class="button"
        hint="{{ 'close without save' | translate }}"
        icon="close"
      >
      </dx-button>
    </div>
  </div>
  <div>
    <dx-scroll-view class="edit-form-scroll-view">
      <ng-content select="dx-form"></ng-content>
    </dx-scroll-view>
  </div>
</div>
