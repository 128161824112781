import { AccountingTransactionProductModel } from 'projects/ucanlo/bll/models/accounting-transactions/accounting-transaction-product.model';

export enum AccountingTransactionStatusType {
  New = 0,
  Processed = 10,
  Paused = 20,
  Finished = 100,
}

export enum AccountingTransactionType {
  Delivery = 0,
  Shipping = 1,
}

export enum AccountingTransactionViewMode {
  DeliveryToStock = 0, // dostawy nowe lub otwarte do zamagazynowania
}

export interface AccountingTransactionModel {
  type: string;
  status: string;
  number: string;
  date: string;
  adHoc: boolean;
  customerCompanyName: string | null;
  customerFirstName: string | null;
  customerLastName: string | null;
  customerAddressLine1: string | null;
  customerAddressLine2: string | null;
  customerPostCode: string | null;
  customerCity: string | null;
  customerCountry: string | null;
  customerPhone: string | null;
  customerEmail: string | null;
  customerVatNumber: string | null;
  differentShippingAddress: boolean;
  shippingCompanyName: string | null;
  shippingFirstName: string | null;
  shippingLastName: string | null;
  shippingAddressLine1?: string | null;
  shippingAddressLine2?: string | null;
  shippingPostCode: string | null;
  shippingCity: string | null;
  shippingCountry: string | null;
  shippingPhone: string | null;
  shippingEmail: string | null;
  notes: string | null;
  documentType: string;
  transportType: string | null;
  id?: number;
  _TS: string;

  details: Array<AccountingTransactionProductModel>;
}
