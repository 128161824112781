export enum DataImportType {
  products = 0,
  locations,
  transactions,
  stock,
}

export interface DataImportInputDataModel {
  type: DataImportType;
  data: any;
}
