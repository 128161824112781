import { APP_INITIALIZER, Injector, NgModule } from '@angular/core';

import devextremeAjax from 'devextreme/core/utils/ajax';

import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
} from '@angular/common/http';
import {
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { DevExtremeModule, DxButtonModule } from 'devextreme-angular';
import { AccountUserListComponent } from './pages/accounts/account-user-list/account-user-list.component';
import { MainPageComponent } from './pages/_app/main-page/main-page.component';
import { MenuComponent } from './pages/_app/main-page/menu/menu.component';
import { TabComponent } from './pages/_app/main-page/tab/tab.component';
import { TabPageWrapperComponent } from './pages/_app/main-page/tab-page-wrapper/tab-page-wrapper.component';
import { ProductListComponent } from './pages/products/product-list/product-list.component';
import { LoginPageComponent } from './pages/_app/login-page/login-page.component';
import { ProductEditComponent } from './pages/products/product-edit/product-edit.component';
import { ReactiveFormsModule } from '@angular/forms';
import { LayoutTestComponent } from './pages/_tests/layout-test/layout-test.component';
import { CommonModule, LOCATION_INITIALIZED } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { MessageBoxTestComponent } from './pages/_tests/message-box-test/message-box-test.component';
import { RolesDescriptionPipe } from './shared/pipes/roles-description.pipe';
import { AccountUserEditComponent } from './pages/accounts/account-user-edit/account-user-edit.component';
import { RouterModule } from '@angular/router';
import { AppRoutingModule } from './app-routing.module';
import { SettingsEditComponent } from './pages/layout/settings-edit/settings-edit.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { DataImportComponent } from './pages/data/import/data-import/data-import.component';
import { DataImportItemComponent } from './pages/data/import/data-import/data-import-item/data-import-item.component';
import { DataImportErrorInfoComponent } from './pages/data/import/data-import/data-import-error-info/data-import-error-info.component';
import { LocationListComponent } from './pages/layout/location-list/location-list.component';
import { LocationEditComponent } from './pages/layout/location-edit/location-edit.component';
import { CustomerEditComponent } from './pages/customers/customer-edit/customer-edit.component';
import { CustomerListComponent } from './pages/customers/customer-list/customer-list.component';
import { AccountingTransactionListComponent } from './pages/accounting-transactions/accounting-transaction-list/accounting-transaction-list.component';
import { AccountingTransactionEditComponent } from './pages/accounting-transactions/accounting-transaction-edit/accounting-transaction-edit.component';
import { EmptyComponent } from './pages/_tests/empty/empty.component';
import { SysInfoComponent } from './pages/_app/main-page/sys-info/sys-info.component';
import { ProductListSelectComponent } from './pages/products/product-list-select/product-list-select.component';
import { ProductLocalizationAddComponent } from './pages/fallback-operations/product-localization-add/product-localization-add.component';
import { ProductLocalizationRemoveComponent } from './pages/fallback-operations/product-localization-remove/product-localization-remove.component';
import { StatusPanel } from './pages/reports/status-panel/status-panel.component';
import { AddClassDxThemeBorderColorDirective } from './shared/directives/add-class-dx-theme-border-color.directive';
import { AddClassDxColorInverseDirective } from './shared/directives/add-class-dx-color-inverse.directive';
import { AddClassDxColorInverseLightDirective } from './shared/directives/add-class-dx-color-inverse-light.directive';
import { ProductInLocationListComponent } from './pages/store/product-in-location-list/product-in-location-list.component';
import { ProductInLocationByLocationListComponent } from './pages/store/product-in-location-by-location-list/product-in-location-by-location-list.component';
import { ProductInLocationByProductListComponent } from './pages/store/product-in-location-by-product-list/product-in-location-by-product-list.component';
import { EditFormWrapperComponent } from 'projects/xlibs/src/gui/edit-form-wrapper/edit-form-wrapper.component';
import { DataGridWrapperComponent } from 'projects/xlibs/src/gui/data-grid-wrapper/data-grid-wrapper.component';
import { HttpErrorInterceptor } from 'projects/xlibs/src/http/http-error.interceptor';
import { LoadingIndicatorInterceptor } from 'projects/xlibs/src/http/loading-indicator.interceptor';
import { environment } from 'projects/ucanlo/desktop/src/environments/environment.prod';
import { CONFIG } from 'projects/xlibs/src/config';
import { GuiModule } from 'projects/xlibs/src/gui/gui.module';
import { LocatorService } from 'projects/xlibs/src/injectors/locator.service';
import { AuthInterceptor } from 'projects/xlibs/src/http/auth.interceptor';
import { sendRequestFactory } from 'projects/xlibs/src/http/dx-http.helper';
import { DxDataGridTestComponent } from './pages/_tests/dx-data-grid-test/dx-data-grid-test.component';
import { FallbackDeliveryComponent } from './pages/fallback-procedures/fallback-delivery/fallback-delivery.component';
import { AngularSplitModule } from 'angular-split';
import { InventoryActionListComponent } from './pages/inventory-transactions/inventory-action-list/inventory-action-list.component';
import { InventoryActionStorageInfoListComponent } from './pages/inventory-transactions/inventory-action-storage-info-list/inventory-action-storage-info-list.component';
import { EnumTranslatePipe } from './shared/pipes/enum-translate.pipe';
import { CustomerListSelectComponent } from './pages/customers/customer-list-select/customer-list-select.component';
import { AngularResizeEventModule } from 'angular-resize-event';

export function appInitializerFactory(
  translate: TranslateService,
  injector: Injector
) {
  return () =>
    new Promise<any>((resolve: any) => {
      const locationInitialized = injector.get(
        LOCATION_INITIALIZED,
        Promise.resolve(null)
      );
      // https://github.com/ngx-translate/core/issues/517
      locationInitialized.then(() => {
        const langToSet = 'pl';
        translate.setDefaultLang('pl');
        translate.use(langToSet).subscribe(
          () => {
            console.info(`Successfully initialized '${langToSet}' language.'`);
          },
          (err) => {
            console.error(
              `Problem with '${langToSet}' language initialization.'`
            );
          },
          () => {
            resolve(null);
          }
        );
      });
    });
}

@NgModule({
  declarations: [
    AppComponent,
    AccountUserListComponent,
    MainPageComponent,
    MenuComponent,
    TabComponent,
    TabPageWrapperComponent,
    ProductListComponent,
    LoginPageComponent,
    ProductEditComponent,
    LayoutTestComponent,
    MessageBoxTestComponent,
    RolesDescriptionPipe,
    AccountUserEditComponent,
    SettingsEditComponent,

    DataImportComponent,
    DataImportItemComponent,

    DataImportErrorInfoComponent,
    LocationListComponent,
    LocationEditComponent,
    CustomerListComponent,
    CustomerEditComponent,
    AccountingTransactionListComponent,
    AccountingTransactionEditComponent,
    EmptyComponent,
    SysInfoComponent,
    ProductListSelectComponent,
    ProductLocalizationAddComponent,
    ProductLocalizationRemoveComponent,
    StatusPanel,
    AddClassDxThemeBorderColorDirective,

    AddClassDxColorInverseDirective,
    AddClassDxColorInverseLightDirective,
    ProductInLocationListComponent,
    ProductInLocationByLocationListComponent,
    ProductInLocationByProductListComponent,
    DxDataGridTestComponent,
    FallbackDeliveryComponent,
    InventoryActionListComponent,
    InventoryActionStorageInfoListComponent,
    EnumTranslatePipe,
    CustomerListSelectComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    GuiModule,
    AngularSplitModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpClient],
      },
    }),
    DxButtonModule,
    DevExtremeModule,
    ReactiveFormsModule,
    CommonModule,
    BrowserAnimationsModule,
    FontAwesomeModule,

    ToastrModule.forRoot({
      timeOut: 2500,
      positionClass: 'toast-top-full-width',
      preventDuplicates: true,
      toastClass: 'ucanlo-toast',
    }),
    RouterModule,
    AppRoutingModule,
    AngularResizeEventModule,
  ],

  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingIndicatorInterceptor,
      multi: true,
    },

    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [TranslateService, Injector],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
  exports: [AddClassDxThemeBorderColorDirective],
})
export class AppModule {
  constructor(private injector: Injector, http: HttpClient) {
    LocatorService.injector = this.injector;
    devextremeAjax.inject({ sendRequest: sendRequestFactory(http) });
  }
}

export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
