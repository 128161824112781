<dx-button
  [template]="'buttonTemplate'"
  class="button"
  hint="{{ description }}"
  [disabled]="disabled ?? false"
>
  <div *dxTemplate="let data of 'buttonTemplate'">
    <!--    <i [className]="iconClass"></i>-->
    <fa-icon
      [icon]="faIcon"
      size="2x"
      class="dx-theme-accent-as-text-color"
    ></fa-icon>
    <div class="label">{{ text }}</div>
  </div>
</dx-button>
