import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { SimpleBaseRepository } from '../../../../xlibs/src/bll/services/simple-base-repository';
import {
  NotesParam,
  NotesResult,
} from '../../../../xlibs/src/gui/data-grid-wrapper/data-grid-wrapper-notes.model';

@Injectable({
  providedIn: 'root',
})
export class NotesService extends SimpleBaseRepository {
  constructor() {
    console.log('##');
    super('notes');
  }

  get(notesParam: NotesParam) {
    return this.http.post<NotesResult>(this.methodUrl('get'), notesParam);
  }

  save(notesParam: NotesParam) {
    return this.http.post(this.methodUrl('save'), notesParam);
  }
}
