/**
 * Bazowa klasa dla repozytoriów danych
 * Biblioteki:
 * https://www.npmjs.com/package/odata-query
 */

import ODataStore
    from 'devextreme/data/odata/store';
import DataSource
    from 'devextreme/data/data_source';
import {CONFIG} from 'projects/xlibs/src/config';
import {HttpClient} from "@angular/common/http";
import {LocatorService} from "projects/xlibs/src/injectors/locator.service";
import {map} from "rxjs/operators";
import buildQuery from "odata-query";


export interface DataSourceParameters {
    fields?: Array<string>;
    endPointName?: string;
    filter?: string;
    key?: string;
    top?: number;
    keyType?: string; // keyType, Accepted Values: 'String' | 'Int32' | 'Int64' | 'Guid' | 'Boolean' | 'Single' | 'Decimal'
}

export abstract class BaseRepository {

    http2: HttpClient;
    endpointName: string;
    fields: Array<string> | null;
    odataInfoRemove = '$format=application/json;odata.metadata=none';

    constructor(endpointName: string, fields: Array<string> | null = null) {
        this.endpointName = endpointName;
        this.fields = fields;
        this.http2 = LocatorService.injector.get(HttpClient);
    }

    get apiUrl(): string {
        return CONFIG.API_URL;
    }

    get endpointUrl(): string {
        return `${this.apiUrl}/${this.endpointName}`;
    }

    private getUrlByEndpointName(endPointName: string | null = null) {
        if (endPointName) {
            return `${this.apiUrl}/${endPointName}`;
        } else {
            return this.endpointUrl;
        }
    }

    /**
     * Zwraca pojedynczy rekord na podstawie tablicy query ()
     */
    getSingleByQuery(queryDef: any, endpointName: string | null = null) {
        queryDef.top = 1;
        const q = buildQuery(queryDef);
        return this.http2.get(
            this.getUrlByEndpointName(endpointName) + q)
            .pipe(map((res: any) => res.value[0]));
    }

    /**
     * Zwraca dane na podstawie tablicy query ()
     */
    getByQuery(queryDef: any, endpointName: string | null = null) {
        const q = buildQuery(queryDef);
        return this.http2.get(
            this.getUrlByEndpointName(endpointName) + q)
            .pipe(map((res: any) => res.value));
    }


    dataSource(params: DataSourceParameters = {}) {
        if (!params.fields && this.fields) params.fields = this.fields;
        if (!params.endPointName) params.endPointName = this.endpointName;
        if (!params.key) params.key = "id";
        if (!params.keyType) params.key = "Int64";

        const ds = new DataSource({
            store: new ODataStore({
                url: `${this.apiUrl}/${params.endPointName}`,
                version: 4,
                key: params.key,
                keyType: params.keyType,
                filterToLower: false,
                beforeSend: (e) => {
                    this.beforeSend(e);
                    if (params.filter) {
                        const defaultFilter = params.filter.replace(/^\s+|\s+$|\s+(?=\s)/g, "");
                        if (e.params.$filter) {
                            e.params.$filter = `(${e.params.$filter}) and (${defaultFilter})`
                        } else {
                            e.params.$filter = defaultFilter;
                        }
                    }
                },
                onLoaded: this.onLoaded,
                onModified: this.onModified,
                onPush: this.onPush,
                onUpdating: this.onUpdating,
            }),
            select: params.fields ?? undefined,
            requireTotalCount: true
        });
        return ds;
    }

    getDataSource(
        fields: Array<string> | null,
        endPointName: string = this.endpointName,
        filter: Array<any> | null = null,
        key: string = 'id',
        keyType: string = 'Int64'
    ) {
        const ds = new DataSource({
            store: new ODataStore({
                url: `${this.apiUrl}/${endPointName}`,
                version: 4,
                key: key,
                keyType: keyType,
                filterToLower: false,
                beforeSend: this.beforeSend,
                onLoaded: this.onLoaded,
                onModified: this.onModified,
                onPush: this.onPush,
                onUpdating: this.onUpdating,
            }),
            select: fields ?? undefined,
            requireTotalCount: true
        });

        return ds;
    }

    urlForGet(id: any): string {
        return `${this.endpointUrl}/${id}?{this.odataInfoRemove}`;
    }

    public beforeSend(e: any) {
        //detekcja ')) or ( to sie pojawi gdy korzystamy z elements-data-grid search panel
        //(po warunkiem, że mamy przynajmniej dwie kolumny)

        if (e.params.$filter && e.params.$filter.includes(`')) or (`)) {

            const value = e.params.$filter.match(/\)\) or \(contains\(.*?,'(.*?)'\)\)/);
            // wyrażenie poszukiwane powinniśmy mieć na drugim elemencie
            if (value[1]) {
                e.params.$filter = `(_TS eq '${value[1]}')`;
            }
        }
    }

    public onLoaded = (result: Array<any>) => {};

    public onModified = () => {};

    public onPush = (changes: Array<any>) => {};

    private onUpdating = (key: any, values: any) => {};
}
