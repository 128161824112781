<!--messagebox-->
<xlib-message-box></xlib-message-box>

<dx-popup #popupSysInfo [title]="'sys info' | translate" [showTitle]="false">
  <app-sys-info></app-sys-info>
</dx-popup>

<dx-load-panel
  #loadPanel
  [(visible)]="loading"
  [closeOnOutsideClick]="false"
  [shading]="true"
  [showIndicator]="true"
  [showPane]="true"
  shadingColor="rgba(0,0,0,.1)"
>
</dx-load-panel>

<!-- shading na tabsy:  [position]="{ of: '#pages-container' }"-->

<div class="container">
  <div class="header">
    <div class="logo no-select" (click)="menuHamburgerClick()">
      <fa-icon class="menu-bar" [icon]="iconMenu" size="2x"></fa-icon>
      <span class="ucanlo dx-theme-accent-as-text-color">UCANLO</span>
      <!--      <span class="copyright">®</span>-->
      <span class="info">DESK</span>
    </div>

    <div class="space"></div>

    <div class="theme">
      <img (click)="switchTheme()" [src]="themeImageSrc" />
    </div>

    <dx-menu
      (onItemClick)="userMenuItemClick($event.itemData.id)"
      cssClass="user-menu"
      [hideSubmenuOnMouseLeave]="true"
      [items]="userMenuItems"
      [showFirstSubmenuMode]="'onHover'"
      itemTemplate="items"
    >
      <div *dxTemplate="let item of 'items'">
        <div class="setting-menu-item" style="display: flex; padding: 6px">
          <div class="setting-menu-icon">
            <fa-icon [icon]="item.faIcon" size="lg"></fa-icon>
          </div>
          <div
            class="setting-menu-text"
            [ngStyle]="{ 'margin-left': item.text ? '10px' : 0 }"
          >
            <span> {{ item.text }}</span>
          </div>
        </div>
      </div>
    </dx-menu>
  </div>
  <div class="content">
    <dx-drawer
      #drawer
      [(opened)]="isMenuOpen"
      height="100%"
      template="template"
    >
      <div *dxTemplate="let data of 'template'" class="menu-wrapper">
        <div class="menu-container">
          <app-menu></app-menu>
        </div>
      </div>
      <div class="pages-container">
        <app-tab #appTab></app-tab>
      </div>
    </dx-drawer>
  </div>
</div>
