export enum ContextMenuActionType {
  Close,
  CloseAll,
  CloseOthers,
  Duplicate = 3,
}

export interface ContextMenuOptionModel {
  text: string;
  icon?: string;
  faIcon?: any;
  action: ContextMenuActionType;
}
