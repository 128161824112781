<xlib-edit-form-wrapper
  #editFormWrapper
  [title]="'location - edit' | translate"
>
  <dx-form [formData]="editObject" [colCount]="1" #editForm>
    <dxi-item itemType="group" caption="{{ 'basic info' | translate }}">
      <dxi-item
        dataField="name"
        [label]="{ text: 'location name' | translate }"
      >
        <dxi-validation-rule
          type="required"
          message="{{ 'location name is required' | translate }}"
        ></dxi-validation-rule>
      </dxi-item>
      <dxi-item
        dataField="barcode"
        [label]="{ text: 'location barcode' | translate }"
      >
        <dxi-validation-rule
          type="required"
          message="{{ 'location barcode is required' | translate }}"
        ></dxi-validation-rule>
      </dxi-item>
      <dxi-item
        [label]="{ text: 'location zone type' | translate }"
        editorType="dxSelectBox"
        [editorOptions]="dxSelectBoxZoneTypeOptions"
        dataField="zoneType"
      >
      </dxi-item>
    </dxi-item>
    <dxi-item
      itemType="group"
      caption="{{ 'additional features' | translate }}"
    >
      <dxi-item
        dataField="blocked"
        [label]="{ text: 'location blocked' | translate }"
        editorType="dxCheckBox"
        helpText="{{ '#prop.location.blocked' | translate }}"
      ></dxi-item>
      <dxi-item
        dataField="pickingOverQueue"
        [label]="{ text: 'location picking over queue' | translate }"
        editorType="dxCheckBox"
        helpText="{{ '#prop.location.pickingOverQueue' | translate }}"
      ></dxi-item>
      <dxi-item
        dataField="pickingBelowQueue"
        [label]="{ text: 'location picking below queue' | translate }"
        editorType="dxCheckBox"
        helpText="{{ '#prop.location.pickingBelowQueue' | translate }}"
      ></dxi-item>
      <dxi-item
        dataField="oneSupplier"
        [label]="{ text: 'location only one supplier' | translate }"
        editorType="dxCheckBox"
        helpText="{{ '#prop.location.oneSupplier' | translate }}"
      ></dxi-item>

      <dxi-item
        dataField="oneSku"
        [label]="{ text: 'location only one sku' | translate }"
        editorType="dxCheckBox"
        helpText="{{ '#prop.location.oneSku' | translate }}"
      ></dxi-item>
    </dxi-item>
  </dx-form>
</xlib-edit-form-wrapper>
