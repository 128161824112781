import { Injectable } from '@angular/core';
import { BaseRepositoryOdata } from '../../../../xlibs/src/bll/services/base-repository-odata';
import { AccountingTransactionStatusHistoryViewModel } from '../../models/accounting-transactions/accounting-transaction-status-history-view.model';

@Injectable({
  providedIn: 'root',
})
export class AccountingTransactionStatusHistoryViewService extends BaseRepositoryOdata<
  AccountingTransactionStatusHistoryViewModel,
  number
> {
  constructor() {
    super('AccountingTransactionStatusHistoryView');
  }
}
