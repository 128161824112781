import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';
import {
  MessageBoxMessageType,
  MessageBoxModel,
} from '../models/message-box.model';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class MessageBoxService {
  display: BehaviorSubject<MessageBoxModel> = new BehaviorSubject(
    new MessageBoxModel(MessageBoxMessageType.None)
  );

  constructor(private translate: TranslateService) {}

  showInfo(
    message: string,
    title: string = this.translate.instant('information')
  ) {
    this.display.next(
      new MessageBoxModel(
        MessageBoxMessageType.Ok,
        message,
        null as any,
        null as any,
        title
      )
    );
  }

  questionYesNo(
    message: string,
    yesAction: any = null,
    noAction: any = null,
    title = this.translate.instant('question')
  ) {
    this.display.next(
      new MessageBoxModel(
        MessageBoxMessageType.YesNo,
        message,
        yesAction,
        noAction,
        title
      )
    );
  }

  questionYesNoCancel(
    message: string,
    yesAction: any = null,
    noAction: any = null,
    title = this.translate.instant('question')
  ) {
    this.display.next(
      new MessageBoxModel(
        MessageBoxMessageType.YesNoCancel,
        message,
        yesAction,
        noAction,
        title
      )
    );
  }
}
