import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { faEye } from '@fortawesome/free-regular-svg-icons';
import { JwtAuthorizationService } from 'projects/xlibs/src/authorization/jwt/jwt-authorization.service';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss'],
})
export class LoginPageComponent implements OnInit, AfterViewInit {
  @ViewChild('textEditUserName') textEditUserName: ElementRef | null = null;
  @ViewChild('submitButton', { read: ElementRef, static: true })
  button: ElementRef | null = null;

  form: FormGroup;
  return: string = '';
  loginInfo?: string;
  loginProblem = false;
  loginInProgress = false;
  afterSubmit = false;

  ico = faEye;

  constructor(
    private fb: FormBuilder,
    private authService: JwtAuthorizationService,
    private router: Router,
    private route: ActivatedRoute,
    private translate: TranslateService
  ) {
    this.form = this.initForm();
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(
      (params) => (this.return = params['return'] || '/')
    );
  }

  ngAfterViewInit(): void {
    this.textEditUserName?.nativeElement.focus();
  }

  private initForm(): FormGroup {
    const form = this.fb.group({
      login: ['', Validators.required],
      pass: ['', Validators.required],
    });

    return form;
  }

  login() {
    this.loginInfo = '';
    this.loginProblem = false;
    this.afterSubmit = true;

    if (!this.form.valid) {
      return;
    }

    this.loginInfo = this.translate.instant('login user credentials verify');
    this.loginInProgress = true;
    this.authService
      .login(this.form.value.login, this.form.value.pass)
      .subscribe(
        (res) => this.runApp(),
        (ex) => this.loginError(ex)
      );
  }

  loginError(ex: any): void {
    this.loginInProgress = false;

    //niepoprawne dane logowania
    console.log('logginError:', ex);

    if (ex.status === 403) {
      this.loginInfo = this.translate.instant(
        'login problem, bad username or password'
      );
    } else {
      this.loginInfo = this.translate.instant('login problem, other');
    }
  }

  private runApp() {
    console.log('runApp()');
    this.router.navigateByUrl(this.return);
  }
}
