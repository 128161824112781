<xlib-data-grid-wrapper
  #grid
  (buttonsAction)="gridViewAction($event)"
  [title]="'locations list' | translate"
  entityName="Location"
>
  <dx-data-grid #dataGridRef [dataSource]="dataSource" id="grid">
    <dxi-column
      caption="{{ 'name' | translate }}"
      dataField="name"
    ></dxi-column>
    <dxi-column
      caption="{{ 'barcode' | translate }}"
      dataField="barcode"
    ></dxi-column>
    <dxi-column
      [lookup]="zoneTypeLookup"
      caption="{{ 'location zone type' | translate }}"
      dataField="zoneType"
      width="150"
    ></dxi-column>
    <dxi-column
      caption="{{ 'only one supplier' | translate }}"
      dataField="oneSupplier"
    ></dxi-column>
    <dxi-column
      caption="{{ 'only one sku' | translate }}"
      dataField="oneSku"
    ></dxi-column>
    <dxi-column
      caption="{{ 'location blocked' | translate }}"
      dataField="blocked"
      dataType="boolean"
    ></dxi-column>
    <dxi-column
      caption="{{ 'picking over queue' | translate }}"
      dataField="pickingOverQueue"
    ></dxi-column>

    <dxi-column
      caption="{{ 'picking below queue' | translate }}"
      dataField="pickingBelowQueue"
    ></dxi-column>
  </dx-data-grid>
</xlib-data-grid-wrapper>
