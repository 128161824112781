<xlib-edit-form-wrapper
  #editFormWrapper
  [title]="'layout settings' | translate"
>
  <dx-form
    [formData]="editObject"
    [colCount]="1"
    [scrollingEnabled]="true"
    #editForm
  >
    <div *dxTemplate="let data of 'inventoryMethodItemTemplate'">
      <div>
        <strong>{{ data.inventoryMethod }}</strong>
      </div>
      <div class="inventory-method-information">
        {{ data.inventoryMethodInfo }}
      </div>
    </div>

    <dxi-item itemType="group" caption="{{ 'general' | translate }}">
      <dxi-item
        isRequired="true"
        [label]="{ text: 'default inventory method' | translate }"
        helpText="{{ '#prop.product.defaultInventoryMethod' | translate }}"
        dataField="inventoryMethod"
        editorType="dxSelectBox"
        [editorOptions]="{
          items: inventoryMethodTypes,
          searchEnabled: true,
          displayExpr: 'inventoryMethod',
          valueExpr: 'id',
          itemTemplate: 'inventoryMethodItemTemplate'
        }"
      >
      </dxi-item>
      <dxi-item
        dataField="useLocations"
        [label]="{ text: 'use location' | translate }"
        editorType="dxCheckBox"
        helpText="{{ '#prop.setting.useLocation' | translate }}"
      ></dxi-item>
    </dxi-item>

    <dxi-item itemType="group" caption="{{ 'delivery' | translate }}">
      <dxi-item
        dataField="deliveryOverQuantityAllow"
        [label]="{ text: 'delivery over quantity allow' | translate }"
        editorType="dxCheckBox"
        helpText="{{ '#prop.setting.deliveryOverQuantityAllow' | translate }}"
      ></dxi-item>
      <dxi-item
        dataField="deliveryOverQuantitySupervisorConfirmRequired"
        [label]="{
          text: 'delivery over quantity supervisor confirm required' | translate
        }"
        editorType="dxCheckBox"
        helpText="{{
          '#prop.setting.deliveryOverQuantitySupervisorConfirmRequired'
            | translate
        }}"
      ></dxi-item>
    </dxi-item>

    <dxi-item itemType="group" caption="{{ 'picking' | translate }}">
      <dxi-item
        dataField="pickingRequireScanLocation"
        [label]="{ text: 'require scan location' | translate }"
        editorType="dxCheckBox"
        helpText="{{ '#prop.setting.pickingRequireScanLocation' | translate }}"
      ></dxi-item>
    </dxi-item>
  </dx-form>
</xlib-edit-form-wrapper>
