<dx-popup #popupGetProducts [showTitle]="false" [visible]="popupVisible">
  <xlib-data-grid-wrapper
    (buttonsAction)="gridViewAction($event)"
    [title]="'product list' | translate"
    selectMode="multiple"
  >
    <dx-data-grid
      #dataGrid
      (onCellPrepared)="onCellPrepared($event)"
      (onRowClick)="onRowClick($event)"
      (onToolbarPreparing)="onToolbarPreparing($event)"
      [dataSource]="dataSource"
      [repaintChangesOnly]="true"
      showCheckBoxesMode="always"
      (onEditorPreparing)="onEditorPreparing($event)"
      (onCellClick)="dataGridOnCellClick($event)"
    >
      <dxo-editing
        [(changes)]="changes"
        [allowAdding]="false"
        [allowDeleting]="false"
        [allowUpdating]="true"
        [selectTextOnEditStart]="true"
        [startEditAction]="'click'"
        mode="batch"
      >
      </dxo-editing>
      <dxo-keyboard-navigation
        [editOnKeyPress]="true"
        enterKeyAction="startEdit"
        enterKeyDirection="column"
      >
      </dxo-keyboard-navigation>

      <dxi-column
        [allowEditing]="false"
        caption="{{ 'product number' | translate }}"
        dataField="number"
      ></dxi-column>
      <dxi-column
        [allowEditing]="false"
        caption="{{ 'name' | translate }}"
        dataField="name"
      ></dxi-column>
      <dxi-column
        [allowEditing]="false"
        caption="{{ 'barcode' | translate }}"
        dataField="barcode"
      ></dxi-column>
      <dxi-column
        [editorOptions]="quantityEditorOptions"
        caption="{{ 'quantity' | translate }}"
        cssClass="strong"
        dataField="quantity"
        dataType="number"
        fixedPosition="right"
        [fixed]="true"
        [calculateCellValue]="quantityCalculateCellValue"
        headerCellTemplate="editableHeaderTemplate"
        width="200"
        [allowResizing]="false"
        [allowReordering]="false"
        [allowGrouping]="false"
        [allowFiltering]="false"
        [allowSorting]="false"
      >
      </dxi-column>

      <!--      <dxi-column-->
      <!--        [fixed]="true"-->
      <!--        [visible]="withExpirationDate"-->
      <!--        caption="{{ 'expiration date' | translate }}"-->
      <!--        dataField="expirationDate"-->
      <!--        dataType="date"-->
      <!--        fixedPosition="right"-->
      <!--        format="yyyy-MM-dd"-->
      <!--        headerCellTemplate="editableHeaderTemplate"-->
      <!--      ></dxi-column>-->

      <div *dxTemplate="let info of 'editableHeaderTemplate'">
        {{ info.column.caption }} <i class="dx-icon-edit"></i>
      </div>
    </dx-data-grid>
  </xlib-data-grid-wrapper>
</dx-popup>
