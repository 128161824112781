import {
  AfterViewInit,
  Component,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import DxThemes from 'devextreme/ui/themes';
import { Router } from '@angular/router';
import { delay } from 'rxjs/operators';
import { LoadingIndicatorService } from '../../../shared/services/loading-indicator.service';
import { environment } from '../../../../environments/environment';
import { DxDrawerComponent, DxPopupComponent } from 'devextreme-angular';
import { PageItem } from 'projects/xlibs/src/gui/page-manager/page-item';
import { ConfigService } from '../../../_config/config.service';
import { TabComponent } from './tab/tab.component';
import { JwtAuthorizationService } from 'projects/xlibs/src/authorization/jwt/jwt-authorization.service';
import { PageManagerService } from 'projects/xlibs/src/gui/page-manager/page-manager.service';

import { faUser } from '@fortawesome/free-regular-svg-icons';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import {
  faBars,
  faLock,
  faGear,
  faCircleInfo,
} from '@fortawesome/free-solid-svg-icons';
import { ScreenService } from '../../../../../../../xlibs/src/gui/screen-service/screen.service';
import { MenuService } from './menu/menu.service';

@Component({
  selector: 'app-main-page',
  templateUrl: './main-page.component.html',
  styleUrls: ['./main-page.component.scss'],
})
export class MainPageComponent implements OnInit, AfterViewInit {
  themeImageSrc: string = '';

  @ViewChild('popupSysInfo') popupSysInfo: DxPopupComponent | null = null;
  @ViewChild('appTab') appTab: TabComponent | null = null;
  @ViewChild('drawer') drawer: DxDrawerComponent | null = null;

  iconMenu = faBars;

  isMenuOpen: boolean = false;

  loading = false;
  userMenuItems = [
    {
      id: 'MAIN',
      faIcon: faUser,

      items: [
        {
          text: this.translate.instant('logout'),
          faIcon: faLock,
          id: 'LOGOUT',
        },
        {
          text: this.translate.instant('settings'),
          faIcon: faGear,
          id: 'SETTINGS',
        },
        {
          text: this.translate.instant('sys info'),
          faIcon: faCircleInfo,
          id: 'INFO',
        },
      ],
    },
  ];

  constructor(
    public translate: TranslateService,
    private authService: JwtAuthorizationService,
    private router: Router,
    private loadingIndicatorService: LoadingIndicatorService,
    public pageManagerService: PageManagerService,
    private config: ConfigService,
    private renderer: Renderer2,
    private screenService: ScreenService,
    private menuService: MenuService
  ) {
    translate.addLangs(['pl', 'en']);
    translate.setDefaultLang('pl');

    this.setTheme();
  }

  get appVersion(): string {
    return `${environment.appVersion}`;
  }

  switchLang(lang: string) {
    this.translate.use(lang);
  }

  setTheme() {
    const theme = localStorage.getItem('theme');
    DxThemes.current(theme ?? 'generic.light.compact');

    if (DxThemes.current() == 'generic.light.compact') {
      this.themeImageSrc = 'assets/images/theme-switcher/dark-mode.svg';
    } else {
      this.themeImageSrc = 'assets/images/theme-switcher/light-mode.svg';
    }
  }

  switchTheme() {
    const dxScheme =
      DxThemes.current() === 'generic.light.compact'
        ? 'generic.dark.compact'
        : 'generic.light.compact';

    localStorage.setItem('theme', dxScheme);

    this.setTheme();
  }

  ngOnInit(): void {
    this.loadingIndicatorServiceInit();
    this.menuService.menuChangeState$.subscribe((state) => {
      this.menuChangeState(state);
    });
    this.menuService.initMenuState();
  }

  menuChangeState(state: boolean) {
    this.isMenuOpen = state;

    if (this.isMenuOpen && this.screenService.isMobile) {
      this.iconMenu = faXmark;
    } else {
      this.iconMenu = faBars;
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      if (this.config.startFirstTime) {
        this.pageManagerService.OpenPage(
          new PageItem(this.config.startPageTitle, this.config.startPage)
        );
        this.appTab!.setNoDataText();
        this.config.startFirstTime = false;
      }
    }, 200);
  }

  logout() {
    this.authService.logout();
    this.router.navigateByUrl('/login');
  }

  loadingIndicatorServiceInit(): void {
    this.loadingIndicatorService.loadingSub
      .pipe(delay(0)) // This prevents a ExpressionChangedAfterItHasBeenCheckedError for subsequent requests
      .subscribe((loading) => {
        this.loading = loading;
        console.log('loading:', loading);
      });
  }

  userProperties() {}

  userMenuItemClick(id: string) {
    switch (id) {
      case 'INFO':
        this.popupSysInfo!.visible = true;
        break;
      case 'SETTINGS':
        break;
      case 'LOGOUT':
        this.logout();
        break;
    }
  }

  menuHamburgerClick() {
    this.menuService.menuToggle();
  }
}
