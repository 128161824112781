import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MessageBoxService } from 'projects/xlibs/src/gui/message-box/services/message-box.service';

@Component({
  selector: 'app-message-box-test',
  templateUrl: './message-box-test.component.html',
  styleUrls: ['./message-box-test.component.scss'],
})
export class MessageBoxTestComponent implements OnInit {
  constructor(
    private messageBox: MessageBoxService,
    private trans: TranslateService
  ) {}
  ngOnInit(): void {}

  info() {
    this.messageBox.showInfo('info');
  }

  yesNo() {
    this.messageBox.questionYesNo(
      this.trans.instant('yes'),
      () => {
        alert('yesno - yes');
      },
      () => {
        alert('yesno - no');
      }
    );
  }

  yesNoCancel() {
    this.messageBox.questionYesNoCancel(
      'yesnocancel',
      () => {
        alert('yesnocancel - yes');
      },
      () => {
        alert('yesnocancel - no');
      }
    );
  }
}
