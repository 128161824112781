<dx-button
  [template]="'buttonTemplate'"
  class="button"
  hint="{{ description }}"
>
  <div *dxTemplate="let data of 'buttonTemplate'" class="button-template">
    <!--    <i [className]="iconClass"></i>-->
    <fa-icon [icon]="faIcon" class="icon"></fa-icon>
    <div class="label">{{ text }}</div>
  </div>
</dx-button>
