<xlib-data-grid-wrapper
  #grid
  (buttonsAction)="gridViewAction($event)"
  [selectMode]="selectMode"
  [title]="'customer list' | translate"
  [showSearchPanel]="false"
  [showGroupPanel]="false"
  entityName="Customer"
>
  <dx-data-grid #dataGridRef [dataSource]="dataSource" id="grid">
    <dxi-column
      caption="{{ 'customer number' | translate }}"
      dataField="number"
    ></dxi-column>

    <dxi-column
      caption="{{ 'name' | translate }}"
      dataField="name"
    ></dxi-column>

    <dxi-column
      caption="{{ 'address' | translate }}"
      dataField="address"
    ></dxi-column>

    <dxi-column
      caption="{{ 'vat number' | translate }}"
      dataField="vatNumber"
    ></dxi-column>

    <dxi-column
      caption="{{ 'email' | translate }}"
      dataField="email"
    ></dxi-column>

    <dxi-column
      caption="{{ 'phone' | translate }}"
      dataField="phone"
    ></dxi-column>

    <dxi-column
      caption="{{ 'supplier' | translate }}"
      dataField="supplier"
      dataType="boolean"
    ></dxi-column>

    <dxi-column
      caption="{{ 'purchaser' | translate }}"
      dataField="purchaser"
      dataType="boolean"
    ></dxi-column>
  </dx-data-grid>
</xlib-data-grid-wrapper>
