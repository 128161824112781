import { Component, OnInit } from '@angular/core';
import {
  DataImportResultModel,
  Error,
} from 'projects/ucanlo/bll/models/data/import/data-import-result.model';

@Component({
  selector: 'app-data-import-error-info',
  templateUrl: './data-import-error-info.component.html',
  styleUrls: ['./data-import-error-info.component.scss'],
})
export class DataImportErrorInfoComponent implements OnInit {
  importResult: DataImportResultModel | null = null;
  errors: Error[] = new Array<Error>();
  constructor() {}

  ngOnInit(): void {
    if (this.importResult?.errors) {
      this.errors = this.importResult?.errors ?? null;
    }
  }
}
