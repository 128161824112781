import { Component, OnInit, ViewChild } from '@angular/core';
import {
  ActionType,
  EditActionParameterModel,
} from 'projects/xlibs/src/gui/edit-form-wrapper/edit-action-parameter-model';
import { EditFormWrapperComponent } from 'projects/xlibs/src/gui/edit-form-wrapper/edit-form-wrapper.component';
import { SettingModel } from 'projects/ucanlo/bll/models/layout/setting.model';
import { SettingsService } from 'projects/ucanlo/bll/services/layout/settings.service';
import { InventoryModelsRepositoryService } from 'projects/ucanlo/bll/services/common/inventory-models-repository.service';

@Component({
  selector: 'app-settings-edit',
  templateUrl: './settings-edit.component.html',
  styleUrls: ['./settings-edit.component.scss'],
})
export class SettingsEditComponent implements OnInit {
  @ViewChild('editFormWrapper')
  editFormWrapper: EditFormWrapperComponent | null = null;
  editObject: SettingModel | null = null;
  inventoryMethodTypes: any;

  constructor(
    private repository: SettingsService,
    private inventoryMethodRepository: InventoryModelsRepositoryService
  ) {
    this.inventoryMethodTypes =
      this.inventoryMethodRepository.getInventoryMethodTypes(false);
  }

  ngOnInit(): void {}
  ngAfterViewInit() {
    this.editFormWrapper!.save.subscribe(() => this.save());
    this.getData();
  }

  getData(): void {
    setTimeout(() => {
      this.repository.getById(19720910).subscribe((model) => {
        this.editObject = model;
        this.editFormWrapper?.init(
          model,
          new EditActionParameterModel(ActionType.Edit, null, null)
        );
      });
    });
  }

  private save() {
    this.repository
      .save(
        new EditActionParameterModel(ActionType.Edit),
        this.editObject as SettingModel
      )
      .subscribe((res) => {
        this.editFormWrapper?.close();
      });
  }
}
