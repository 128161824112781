import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { EditFormWrapperComponent } from 'projects/xlibs/src/gui/edit-form-wrapper/edit-form-wrapper.component';

import { DxFormComponent } from 'devextreme-angular';
import {
  ActionType,
  EditActionParameterModel,
} from 'projects/xlibs/src/gui/edit-form-wrapper/edit-action-parameter-model';
import { DxiItemComponent } from 'devextreme-angular/ui/nested';
import { TranslateService } from '@ngx-translate/core';

import { ClipboardService } from 'ngx-clipboard';
import { ToastrService } from 'ngx-toastr';
import { AccountUserRolesModel } from 'projects/ucanlo/bll/models/accounts/account-user-roles.model';
import { AccountUserModel } from 'projects/ucanlo/bll/models/accounts/account-user.model';
import { AccountUsersService } from 'projects/ucanlo/bll/services/accounts/account-users.service';
import {
  getRandomPassword,
  checkPasswordRules,
} from 'projects/xlibs/src/cryptography/password-helper';

@Component({
  selector: 'app-account-user-edit',
  templateUrl: './account-user-edit.component.html',
  styleUrls: ['./account-user-edit.component.scss'],
})
export class AccountUserEditComponent implements OnInit, AfterViewInit {
  @ViewChild('editFormWrapper')
  editFormWrapper: EditFormWrapperComponent | null = null;
  @ViewChild('editForm') editForm: DxFormComponent | null = null;
  @ViewChild('textEditPassword') textEditPassword: DxiItemComponent | null =
    null;

  @ViewChild('ButtonChangePassword')
  buttonChangePassword: DxiItemComponent | null = null;

  @Input()
  editActionParams: EditActionParameterModel | null = null;

  constructor(
    private repo: AccountUsersService,
    private cdr: ChangeDetectorRef,
    private translate: TranslateService,
    private clipboardApi: ClipboardService,
    private toastr: ToastrService
  ) {
    this.configureEditorOptions();
  }

  public editObject: any;
  public passwordEditOptions: any;
  public buttonChangeUserPasswordOptions: any;
  public buttonChangeUserPasswordCanceOptions: any;
  public mustSetPassword = false;
  public passwordEditorVisible = true;

  ngOnInit(): void {
    this.setInitPasswordEditMode();

    const data = this.editActionParams;
    this.editObject = {
      accountUser: data?.model,
      roles: new AccountUserRolesModel(data?.model.roles),
    };
  }
  ngAfterViewInit() {
    this.editFormWrapper?.init(
      this.editActionParams?.model,
      this.editActionParams!,
      () => this.save()
    );
  }

  setInitPasswordEditMode() {
    switch (this.editActionParams!.actionType) {
      case ActionType.Add:
      case ActionType.Copy:
        this.mustSetPassword = true;
        this.passwordEditorVisible = true;
        break;
      case ActionType.Edit:
        this.mustSetPassword = false;
        this.passwordEditorVisible = false;
        break;
    }
  }

  passwordEditorVisibleToggle() {
    if (this.passwordEditorVisible) {
      this.editObject.accountUser.password = null;
    }

    this.passwordEditorVisible = !this.passwordEditorVisible;
  }

  private save() {
    const model = this.editObject.accountUser;
    model.roles = this.editObject.roles.getRoles();

    this.repo
      .save(this.editActionParams!, model as AccountUserModel)
      .subscribe((res) => {
        this.editFormWrapper?.close();

        this.editActionParams?.dataGrid?.dgRefreshAndFocusRowById(res.id);
      });
  }

  public validatePassword(e: any): boolean {
    return checkPasswordRules(e.value);
  }

  private configureEditorOptions() {
    // konfiguracja przycisków dla textedit "hasło użytkownika"
    this.passwordEditOptions = {
      buttons: [
        {
          name: 'generatePassword',
          location: 'after',
          options: {
            stylingMode: 'text',
            icon: 'pulldown',
            hint: this.translate.instant('generate password'),
            onClick: () => {
              const pass = getRandomPassword(10);
              this.editObject.accountUser.password = pass;
            },
          },
        },
        {
          name: 'copy',
          location: 'after',
          options: {
            stylingMode: 'text',
            icon: 'copy',
            hint: this.translate.instant('copy to clipboard'),
            onClick: () => {
              if (this.editObject.accountUser.password) {
                this.clipboardApi.copyFromContent(
                  this.editObject.accountUser.password
                );
                this.toastr.info(
                  this.translate.instant(
                    'the password has been copied to the clipboard'
                  )
                );
              }
            },
          },
        },
      ],
    };
    // konfiguracja dla przycisku "zmiana hasła dla użytkownika"
    this.buttonChangeUserPasswordOptions = {
      text: this.translate.instant('change user password'),
      width: 200,
      type: 'normal',
      onClick: () => {
        this.passwordEditorVisibleToggle();
      },
    };
    // konfiguracja dla przycisku "zmiana hasła dla użytkownika - anulowanie"
    this.buttonChangeUserPasswordCanceOptions = {
      text: this.translate.instant('change user password cancel'),
      width: 200,
      onClick: () => {
        this.passwordEditorVisibleToggle();
      },
    };
  }
}
