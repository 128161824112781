<xlib-edit-form-wrapper #editFormWrapper [title]="'user - edit' | translate">
  <dx-form
    [formData]="editObject"
    [colCount]="1"
    [scrollingEnabled]="true"
    #editForm
  >
    <dxi-item itemType="group" caption="{{ 'basic info' | translate }}">
      <dxi-item
        dataField="accountUser.email"
        [label]="{ text: 'login (email)' | translate }"
        class="bold"
      >
        <dxi-validation-rule
          type="email"
          message="{{ 'not a valid email address' | translate }}"
        ></dxi-validation-rule>
        <dxi-validation-rule
          type="required"
          message="{{ 'login (email) is required' | translate }}"
        ></dxi-validation-rule>
      </dxi-item>
      <dxi-item
        dataField="accountUser.firstname"
        [label]="{ text: 'firstname' | translate }"
      >
      </dxi-item>
      <dxi-item
        dataField="accountUser.surname"
        [label]="{ text: 'surname' | translate }"
      ></dxi-item>
      <dxi-item
        dataField="accountUser.active"
        [label]="{ text: 'active' | translate }"
        editorType="dxCheckBox"
      ></dxi-item>
    </dxi-item>

    <dxi-item itemType="group" caption="{{ 'user roles' | translate }}">
      <dxi-item
        dataField="roles.management"
        [label]="{ text: 'management' | translate }"
        editorType="dxCheckBox"
      ></dxi-item>
      <dxi-item
        dataField="roles.delivery"
        [label]="{ text: 'delivery' | translate }"
        editorType="dxCheckBox"
      ></dxi-item>
      <dxi-item
        dataField="roles.picking"
        [label]="{ text: 'picking' | translate }"
        editorType="dxCheckBox"
      ></dxi-item>
      <dxi-item
        dataField="roles.shipping"
        [label]="{ text: 'shipping' | translate }"
        editorType="dxCheckBox"
      ></dxi-item>
    </dxi-item>

    <dxi-item itemType="group" caption="{{ 'security' | translate }}">
      <dxi-item
        *ngIf="!mustSetPassword && !passwordEditorVisible"
        itemType="button"
        horizontalAlignment="left"
        [buttonOptions]="buttonChangeUserPasswordOptions"
      >
      </dxi-item>
      <dxi-item
        *ngIf="!mustSetPassword && passwordEditorVisible"
        itemType="button"
        horizontalAlignment="left"
        [buttonOptions]="buttonChangeUserPasswordCanceOptions"
      >
      </dxi-item>
      <dxi-item
        #textEditPassword
        *ngIf="passwordEditorVisible"
        dataField="accountUser.password"
        [baseSize]="200"
        [label]="{ text: 'password' | translate }"
        [editorOptions]="passwordEditOptions"
      >
        <dxi-validation-rule
          type="custom"
          message="{{ 'password rules' | translate }}"
          [validationCallback]="validatePassword"
        ></dxi-validation-rule>
      </dxi-item>
    </dxi-item>
  </dx-form>
</xlib-edit-form-wrapper>
