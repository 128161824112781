<xlib-data-grid-wrapper
  #grid
  [title]="'import errors list' | translate"
  [description]="'errors in the import files' | translate"
  [showActionButtons]="false"
>
  <dx-data-grid [dataSource]="errors" id="grid" keyExpr="id">
    <dxi-column
      dataField="info"
      cellTemplate="infoTemplate"
      caption="{{ 'import problem' | translate }}"
    ></dxi-column>
    <dxi-column
      dataField="rowNumber"
      sortOrder="asc"
      width="120"
      caption="{{ 'row number' | translate }}"
    ></dxi-column>

    <div *dxTemplate="let data of 'infoTemplate'">
      <span [innerHTML]="data.value"></span>
    </div>
  </dx-data-grid>
</xlib-data-grid-wrapper>
