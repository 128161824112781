<xlib-edit-form-wrapper
  #editFormWrapper
  [title]="'fallback delivery' | translate"
  (save)="(save)"
>
  <dx-form #editForm [formData]="deliveryEditObject">
    <dxi-item
      itemType="group"
      caption="{{ 'fallback delivery notes' | translate }}"
    >
      <dxi-item
        dataField="description"
        editorType="dxTextArea"
        [colSpan]="2"
        [editorOptions]="{ height: 100 }"
      >
        <dxo-label [visible]="false"></dxo-label>
        <dxi-validation-rule
          message="{{ 'description is required' | translate }}"
          type="required"
        >
        </dxi-validation-rule>
      </dxi-item>
    </dxi-item>
    <dxi-item itemType="group" caption="{{ 'products added' | translate }}">
      <dxi-item name="show-order">
        <dx-data-grid
          #dataGridRef
          [dataSource]="deliveryEditObject.items"
          id="grid"
          keyExpr="id"
        >
          <dxo-search-panel [visible]="false"></dxo-search-panel>
          <dxo-group-panel [visible]="false"></dxo-group-panel>
          <dxo-column-chooser [enabled]="false"></dxo-column-chooser>
          <dxo-filter-row [visible]="false"></dxo-filter-row>
          <dxo-editing
            [allowAdding]="true"
            [allowDeleting]="true"
            [allowUpdating]="true"
            [confirmDelete]="false"
            mode="popup"
          >
            <dxo-popup
              [height]="350"
              [shading]="false"
              [showTitle]="true"
              [width]="400"
              dx-group-column-count="1"
              title="{{ 'add product to location' | translate }}"
              [minWidth]="700"
              [minHeight]="500"
              [closeOnOutsideClick]="true"
            >
            </dxo-popup>
            <dxo-form [colCount]="1" labelLocation="top">
              <div *dxTemplate="let item of 'listProductItem'">
                <div>
                  <div class="product-name">{{ item.name }}</div>
                  <div class="product-number">
                    <span class="dx-theme-accent-as-text-color">
                      {{ item.number }}</span
                    >
                    <span class="product-barcode"> {{ item.barcode }}</span>
                  </div>
                </div>
              </div>
              <div *dxTemplate="let item of 'listLocationItem'">
                <div>
                  <div class="location-name">{{ item.name }}</div>
                  <div class="location-number">
                    <span class="dx-theme-accent-as-text-color">
                      {{ item.barcode }}</span
                    >
                  </div>
                </div>
              </div>
              <dxi-item
                [label]="{ text: 'product' | translate }"
                editorType="dxLookup"
                [editorOptions]="lookupProductOptions"
                dataField="productId"
              >
                <dxi-validation-rule
                  message="{{ 'product is required' | translate }}"
                  type="required"
                >
                </dxi-validation-rule>
              </dxi-item>

              <dxi-item
                [label]="{ text: 'storage location' | translate }"
                editorType="dxLookup"
                [editorOptions]="lookupLocationOptions"
                dataField="locationId"
              >
                <dxi-validation-rule
                  message="{{ 'location is required' | translate }}"
                  type="required"
                ></dxi-validation-rule>
              </dxi-item>

              <dxi-item
                [editorOptions]="{
                  min: 1,
                  max: 1000000,
                  showSpinButtons: true,
                  format: '#,##0'
                }"
                [label]="{ text: 'quantity' | translate }"
                dataField="quantity"
                editorType="dxNumberBox"
              >
                <dxi-validation-rule
                  message="{{ 'quantity is required' | translate }}"
                  type="required"
                >
                </dxi-validation-rule>
              </dxi-item>

              <dxi-item
                [label]="{ text: 'expiration date' | translate }"
                dataField="expirationDate"
              >
              </dxi-item>
            </dxo-form>
          </dxo-editing>
          <dxi-column
            dataField="productId"
            caption="{{ 'product name' | translate }}"
            [showEditorAlways]="true"
          >
            <dxo-lookup
              [dataSource]="productGridLookupDataSource"
              displayExpr="name"
              valueExpr="id"
            >
            </dxo-lookup>
          </dxi-column>

          <dxi-column
            dataField="locationId"
            caption="{{ 'location name' | translate }}"
            [showEditorAlways]="true"
          >
            <dxo-lookup
              [dataSource]="locationGridLookupDataSource"
              displayExpr="name"
              valueExpr="id"
            >
            </dxo-lookup>
          </dxi-column>

          <dxi-column
            caption="{{ 'quantity' | translate }}"
            dataField="quantity"
            dataType="number"
          ></dxi-column>
          <dxi-column
            caption="{{ 'expiration date' | translate }}"
            dataField="expirationDate"
            dataType="date"
          ></dxi-column>
        </dx-data-grid>
      </dxi-item>
    </dxi-item>
  </dx-form>
</xlib-edit-form-wrapper>
