/**
 * pomocnicza klasa dla akcji w gridach
 */

import { DataGridWrapperComponent } from '../data-grid-wrapper/data-grid-wrapper.component';

export enum ActionType {
  Add,
  Copy,
  Edit,
  Delete,
  Select,
  Cancel,
  CustomAction,
}

export class EditActionParameterModel {
  constructor(
    public actionType: ActionType,
    public id: any = null,
    public dataGrid: DataGridWrapperComponent | null = null,
    public model: any = null,
    public selected: any = null,
    /**
     * Index rekordu w data grid - wykorzystywany przy np usuwaniu rekordu z data source typu Array
     */
    public index: number = -1,
    /**
     * dodatkowy parametr określający tryb pracy formatki z edycją (w przypadku różnych trybów pracy)
     * przykład:
     * AccountingTransactionEdit obsługuje w taki sam sposób dostawy i wydania
     * W tym przypadku formatka może pracować w dwóch trybach: Delivery, Shipping
     */
    public mode: any = undefined,
    /**
     * nazwa akcji zdefiniowanej przez użytkownika
     */
    public customActionName: string | null = null
  ) {}
}
