/**
 * Serwis pozwalający na globalny dostęp do Injector co daje możliwość wstrzykiwania z pominięciem konstruktora
 * https://stackoverflow.com/questions/42461852/inject-a-service-manually
 */

import {
    Injectable,
    Injector
} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class LocatorService {
    static injector: Injector;
}
