import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { EditActionParameterModel } from '../edit-form-wrapper/edit-action-parameter-model';
import { NotesParam } from '../data-grid-wrapper/data-grid-wrapper-notes.model';
import { emitDistinctChangesOnlyDefaultValue } from '@angular/compiler/src/core';
import { DxPopupComponent, DxTextAreaComponent } from 'devextreme-angular';

@Component({
  selector: 'xlib-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.css'],
})
export class NotesComponent implements OnInit {
  notes: string = '';
  notesPreviousValue: string = '';

  isPopupNotesVisible: any;
  constructor() {}

  @ViewChild('textarea', { static: false })
  public textArea?: DxTextAreaComponent;

  @Output() OnSave: EventEmitter<string> = new EventEmitter();

  ngOnInit(): void {}

  setData(notes: string) {
    this.notes = notes;
    this.notesPreviousValue = notes;
    this.isPopupNotesVisible = true;
  }

  popupNotesOnHiding($event: any) {
    if (this.notes != this.notesPreviousValue) {
      this.OnSave.emit(this.notes);
    }
  }

  onShown($event: any) {
    this.textArea?.instance.focus();
  }

  onKeyDown($event: any) {
    if ($event.event.originalEvent.key == 'Escape') {
      // this.popupNotesOnHiding(null);
      this.isPopupNotesVisible = false;
    }
  }
}
