import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class InventoryModelsRepositoryService {
  constructor(private translate: TranslateService) {}

  getInventoryMethodTypes(withDefaultValue: boolean): any {
    const res: any[] = [];


    if (withDefaultValue) {
      res.push({
        id: null,
        inventoryMethod: this.translate.instant('default'),
        inventoryMethodInfo: this.translate.instant(
            'inventory method default description'
        ),
      });
    }

    res.push(
        {
          id: 'FIFO',
          inventoryMethod: 'FIFO',
          inventoryMethodInfo: this.translate.instant(
              'inventory method FIFO description'
          ),
        },
        {
          id: 'LIFO',
          inventoryMethod: 'LIFO',
          inventoryMethodInfo: this.translate.instant(
              'inventory method LIFO description'
          ),
        },
        {
          id: 'FEFO',
          inventoryMethod: 'FEFO',
          inventoryMethodInfo: this.translate.instant(
              'inventory method FEFO description'
          ),
        }
    );

    return res;
  }
}
