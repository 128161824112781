import {
  AfterContentInit,
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { DxDataGridComponent, DxButtonComponent } from 'devextreme-angular';
import { formatDate } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { MessageBoxService } from '../message-box/services/message-box.service';
import { Guid } from 'guid-typescript';
import {
  ActionType,
  EditActionParameterModel,
} from 'projects/xlibs/src/gui/edit-form-wrapper/edit-action-parameter-model';
import { CONFIG } from '../../config';
import { NotesComponent } from '../notes/notes.component';
import { PageManagerService } from '../page-manager/page-manager.service';
import { ActionButtonLargeModel } from '../action-button/action-button-large/action-button-large.model';

import {
  faFilter,
  faFilterCircleXmark,
} from '@fortawesome/free-solid-svg-icons';
import { DataImportComponent } from '../data-import/data-import.component';
import { ResizedEvent } from 'angular-resize-event';

@Component({
  selector: 'xlib-data-grid-wrapper',
  templateUrl: './data-grid-wrapper.component.html',
  styleUrls: ['./data-grid-wrapper.component.scss'],
})
export class DataGridWrapperComponent
  implements OnInit, AfterContentInit, AfterViewInit
{
  public editMode: typeof ActionType = ActionType;

  notesColumnName = 'notes';
  isPopupNotesVisible: boolean = false;

  @ContentChild(DxDataGridComponent, { static: false })
  dataGrid?: DxDataGridComponent;

  @ViewChild('notes') notesPopup: NotesComponent | null = null;
  @ViewChild('dataImport') dataImportPopup: DataImportComponent | null = null;

  @ViewChild('dataGridWrapperContainer')
  dataGridWrapperContainer: ElementRef | null = null;

  @ViewChild('gridContent')
  gridContent: ElementRef | null = null;

  /**
   * nazwa encji po stronie API, pozwala między innymi na generyczne tworzenie notatek (ważna jest wielkość liter)
   */
  @Input() entityName: string | null = null;

  /**
   * Czy dodajemy nową kolumnę o nazwie NOTES.
   * Wymagane wtedy jest również podanie nazwy encji (entityName)
   */
  @Input() addNotesColumn = true;

  @Input() exportFileName: string = 'export';
  @Input() exportFileAddDateSuffix: boolean = true;
  @Input() title: string = 'List';

  /**
   * Czy zapisujemy ustawienia kolumn, etc
   */
  @Input() saveLayout = true;

  @Input() description: string | null = null;

  /**
   * pokazuje header wraz z przyciskami
   */
  @Input() showHeader = true;

  /**
   * pokazuje stopkę wraz z opcjami
   */
  @Input() showFooter = true;

  /**
   * pokazuje buttony z akcjami
   */
  @Input() showActionButtons = true;

  /**
   * pokazuje buttony z akcjami edycyjnymi (dodaj, usuń, popraw, dodaj na podstawie wybranego)
   */
  @Input() showActionEditButtons = true;

  @Input() showAddButton = true;
  @Input() showEditButton = true;
  @Input() showDeleteButton = true;
  @Input() showCopyButton = true;
  @Input() showExportToExcelButton = true;
  @Input() showImportDataButton = false;

  @Input() importDataButtonHintTranslateKey = 'import data';
  @Input() importDataButtonFromClipBoardDescriptionTranslateKey =
    'import data from clipboard description';
  @Input() importDataButtonFromFileDescriptionTranslateKey =
    'import data from file description';

  /**
   * Pola które będą wysyłane do API.
   */
  @Input() importDataFields: string = '';

  @Input() showRefreshButton = true;
  @Input() showOkButton = true;
  @Input() showCancelButton = true;
  @Input() showClearFiltersButton = true;
  @Input() showNotesEditButton = true;

  @Input() confirmDelete = true;

  @Input() showGroupPanel = true;
  @Input() showTitle = true;
  @Input() showSearchPanel = true;

  @Input() showTitleWithNormalFont = false;

  @Input() selectMode: 'none' | 'single' | 'multiple' | 'custom' = 'none';

  /**
   * akcje możliwe do wykonania w ramach grida
   * pokazywane będą jako dodatkowe przyciski + menu kontekstowe
   */
  @Input() actions: Array<ActionButtonLargeModel> =
    new Array<ActionButtonLargeModel>();

  /**
   * standard - jeden grid jako komponent
   * inTabView - grid w tabie - inne szerokości etc
   * raw - bez żadnego formatowania
   */

  @Input() viewMode: 'standard' | 'inTabView' | 'raw' = 'standard';

  /**
   * storageKey obliczany jest na podstawie Title i wygląda on tak: dataGrid {title}
   * istnieje szansa, że wystąpią dwa gridy o takim samym Title i wtedy ustawienia będą współdzielić między siebie
   * aby tego uniknąć możemy skorzystać z właściwości storageKey, wtedy też klucz przyjmie wartość DataGrid {title} {storageKey}
   * nazwa dla storageKey  (każdy datagrid zapisuje informacje np. o ustawieniu szerokości kolumn.
   * Ta nazwa określa pod jaką nazwą klucza ta informacja zostanie zapisana)
   */
  @Input() storageKeySufix: string = '';

  @Output() buttonsAction: EventEmitter<EditActionParameterModel> =
    new EventEmitter();

  /**
   * Ustawia informacje o błędzie (wyświetla ikonkę)
   */
  public error: string | null = null;

  // @HostListener('document:keyup', ['$event'])
  // handleDeleteKeyboardEvent(event: KeyboardEvent) {
  //   if (event.key === 'Delete') {
  //     this.dgDelete();
  //   }
  //   if (event.key === 'Insert' && !event.ctrlKey) {
  //     this.dgAdd();
  //   }
  //
  //   if (event.key === 'Insert' && event.ctrlKey) {
  //     this.dgCopy();
  //   }
  //
  //   if (event.key === 'Enter') {
  //     this.dgEdit();
  //   }
  //
  //   if (event.key === 'q') {
  //     this.dgRefresh();
  //   }
  // }

  iconFilterSet = faFilter;
  iconFilterCancel = faFilterCircleXmark;

  focusedRowKey: any = 0;
  focusedRowNumber: number = 0;

  // container rozszerzalny, obliczamy na podstawie jego wielkość gridów
  dataGridContainerId: string = `data-grid-${Guid.create().toString()}`;

  constructor(
    private toastr: ToastrService,
    private translate: TranslateService,
    private messageBox: MessageBoxService,
    private renderer: Renderer2,
    private pageManagerService: PageManagerService,
    private cdRef: ChangeDetectorRef
  ) {}

  private dataGridConfiguration(dataGrid: DxDataGridComponent) {
    if (this.viewMode === 'raw') {
      dataGrid.groupPanel.visible = false;
      dataGrid.searchPanel.visible = false;
      dataGrid.columnChooser.enabled = false;
      this.showFooter = false;
      this.showHeader = false;
      this.cdRef.detectChanges();
    }

    if (this.exportFileName) {
      dataGrid.export = {
        allowExportSelectedData: true,
        fileName: this.dgGetExportFileName(),
      };
    }

    if (this.selectMode === 'multiple') {
      dataGrid.selection = {
        selectAllMode: 'allPages',
        showCheckBoxesMode: 'always',
        mode: 'multiple',
        allowSelectAll: false,
        deferred: true,
      };
    }

    if (this.selectMode === 'single') {
      dataGrid.selection = {
        mode: 'single',
        deferred: true,
      };
    }

    dataGrid.stateStoring = {
      enabled: true,
      type: 'custom',
      customSave: this.dxDataGridSaveState,
      customLoad: this.dxDataGridLoadState,
    };

    if (this.addNotesColumn && this.entityName) {
      dataGrid.columns.push({
        dataField: 'notes',
        caption: this.translate.instant('notes'),
        allowResizing: false,
        allowEditing: false,
        width: '0', //minimalna możliwa szerokość pola
        // cellTemplate: (container, options) => {
        //   if (options.value == true) {
        //     const icon = this.renderer.createElement('i');
        //     this.renderer.addClass(icon, 'dx-icon-paste');
        //     icon.class = 'dx-icon-paste';
        //     this.renderer.appendChild(container, icon);
        //   }
        // },
      });
    }

    dataGrid.onCellDblClick.subscribe((p: any) => {
      if (p.column.name == this.notesColumnName && this.entityName) {
        this.dgNotes();
      } else {
        //this.dgEdit();
      }
    });

    // dataGrid.onContentReady.subscribe((e) => {
    //   //this.calculateRowIndex();
    //   if (!e.component.__focused && e.component.totalCount() > 0) {
    //     e.component.focus();
    //   }
    // });
    //
    dataGrid.onFocusedRowChanged.subscribe((p) => {
      if (p.row) {
        this.focusedRowNumber = p.rowIndex + 1;
        this.rowData = p.row.data;
      }
    });

    dataGrid.columnChooserChange.subscribe((p) => {});
  }

  rowData: any;

  get totalCount(): string {
    if (this.dataGrid?.instance.totalCount) {
      const count = this.dataGrid.instance.totalCount();
      if (count === -1) {
        return '..';
      } else {
        return count.toString();
      }
    } else {
      return '..';
    }
  }

  private get storageKey(): string {
    const key = `#datagird.${this.pageManagerService.selectedPage.component.name}.${this.title}.${this.storageKeySufix}`;
    return key.replace(/[\s()-]+/g, '').toLowerCase();
  }

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    console.log('dataGrid.ngAfterViewInit');
    this.notesPopup?.OnSave.subscribe((notes) => {
      this.saveNotes(notes);
    });

    if (!this.entityName || !this.addNotesColumn) {
      this.showNotesEditButton = false;
    }
  }

  ngAfterContentInit(): void {
    if (this.dataGrid) {
      this.dataGridConfiguration(this.dataGrid);
    }
  }

  //region DataGrid akcje

  dgExportToExcel() {
    this.dataGrid?.instance.exportToExcel(false);
  }

  dgImportData() {
    this.dataImportPopup?.show();
  }

  dgNotes() {
    const id = this.getFocusedRowId(this.translate.instant('select an item'));

    if (id === null) {
      return;
    }

    if (this.entityName) {
      CONFIG.SERVICE_NOTES_GET({
        entityName: this.entityName,
        id: id,
      }).subscribe((notesResult) => {
        this.notesPopup?.setData(notesResult.notes);
        this.isPopupNotesVisible = true;
      });
    }
  }

  saveNotes(notes: string) {
    const id = this.getFocusedRowId();

    if (id && this.entityName) {
      CONFIG.SERVICE_NOTES_SAVE({
        entityName: this.entityName,
        id,
        notes,
      }).subscribe((p) => {
        const rowId = this.dataGrid?.instance.getRowIndexByKey(id);

        if (rowId != undefined) {
          this.dataGrid?.instance.cellValue(rowId, 'notes', notes);
        }
      });
    }
  }

  calculateRowIndex() {
    // if (this.dataGrid?.instance.totalCount() === 0) {
    //   console.log('a');
    //   this.focusedRowNumber = 0;
    //   return;
    // } else {
    //   console.log('b');
    //   this.focusedRowNumber = 1;
    // }
    // if (this.dataGrid?.focusedRowIndex) {
    //   this.focusedRowNumber = this.dataGrid?.focusedRowIndex + 1;
    // }
  }

  dgClearFilters() {
    this.dataGrid?.instance.clearFilter();
  }

  dgSetFilters() {
    this.dataGrid?.instance.option('filterBuilderPopup', { visible: true });
  }

  dgRefresh() {
    if (this.dataGrid) {
      this.dataGrid?.instance.refresh();
    }
  }

  public dgRefreshAndFocusRowById(id: any) {
    // navigateToRow - to powoduje że prawidłowo zachowuje się focusedRowKey = x
    // na kiedyś, do znalezienia ładniejszego rozwiązania

    this.dataGrid!.instance.navigateToRow(0).then(() => {
      this.dataGrid!.instance.refresh(true).then(() => {
        this.dataGrid!.focusedRowKey = id;
      });
    });
  }

  dgDelete(): void {
    const id = this.getFocusedRowId(
      this.translate.instant('select an item for delete')
    );

    if (id === null) {
      return;
    }

    const index = this.dataGrid?.instance.getRowIndexByKey(id);

    if (this.confirmDelete) {
      this.messageBox.questionYesNo(
        this.translate.instant('are you sure you want to delete this item'),
        () => this.dgDeleteFunction(id, index)
      );
    } else {
      this.dgDeleteFunction(id, index);
    }
  }

  dgUserActionClick = (actionName: string | undefined) => {
    alert(actionName);
  };

  dgDeleteFunction = (id: any, index: any) => {
    this.buttonsAction.emit(
      new EditActionParameterModel(
        ActionType.Delete,
        id,
        null,
        null,
        null,
        index
      )
    );
  };

  dgCustomAction(
    customActionName: string | undefined,
    selectedRowRequired: boolean
  ) {
    let id: string | null = null;

    if (selectedRowRequired) {
      id = this.getFocusedRowId(this.translate.instant('select an item'));

      if (id === null) {
        return;
      }
    }

    this.buttonsAction.emit(
      new EditActionParameterModel(
        ActionType.CustomAction,
        null,
        this,
        null,
        id,
        -1,
        undefined,
        customActionName
      )
    );
  }

  dgAdd() {
    this.buttonsAction.emit(
      new EditActionParameterModel(ActionType.Add, null, this)
    );
  }

  dgCopy(): void {
    const id = this.getFocusedRowId(
      this.translate.instant('select an item for copy')
    );

    if (id === null) {
      return;
    }

    this.buttonsAction.emit(
      new EditActionParameterModel(ActionType.Copy, id, this)
    );
  }

  dgEdit() {
    const id = this.getFocusedRowId(
      this.translate.instant('select an item for edit')
    );

    if (id === null) {
      return;
    }
    this.buttonsAction.emit(
      new EditActionParameterModel(ActionType.Edit, id, this)
    );
  }

  // wybór rekordu
  dgSelect() {
    switch (this.selectMode) {
      case 'single':
        this.dgSelectEmit(this.dataGrid!.focusedRowKey);
        break;
      case 'multiple':
        this.dataGrid?.instance.getSelectedRowKeys().then((selected) => {
          this.dgSelectEmit(selected);
        });
        break;
    }
  }

  dgSelectEmit(selected: any) {
    if (!selected || this.dataGrid?.instance.totalCount() == 0) {
      this.toastr.warning(this.translate.instant('select an item'));
      return;
    }

    this.buttonsAction.emit(
      new EditActionParameterModel(
        ActionType.Select,
        null,
        this,
        null,
        selected
      )
    );
  }

  //tryb edycji, anulowanie wyboru
  dgCancel() {
    this.buttonsAction.emit(new EditActionParameterModel(ActionType.Cancel));
  }

  //endregion

  getFocusedRowId(infoIfNull: string | null = null): string | null {
    const focusedRowKey = this.dataGrid?.focusedRowKey;

    if (!focusedRowKey) {
      if (infoIfNull) {
        this.toastr.warning(infoIfNull);
      }
      return null;
    }

    return focusedRowKey;
  }

  showColumnChooser() {
    this.dataGrid!.instance.showColumnChooser();
  }

  clearColumnSettings() {
    this.messageBox.questionYesNo(
      this.translate.instant('are you sure you want to clear column settings'),
      () => this.clearColumnSettingsAction()
    );
  }

  clearColumnSettingsAction() {
    localStorage.removeItem(this.storageKey);

    this.dataGrid!.instance.beginUpdate();
    this.dataGrid!.instance.state(null);
    this.dataGrid!.instance.endUpdate();
  }

  private dxDataGridSaveState = (layoutData: any) => {
    if (!this.saveLayout) {
      return;
    }

    layoutData?.columns?.forEach((e: any) => {
      delete e.filterValue;
      delete e.groupIndex;
      delete e.selectedFilterOperation;
      delete e.fixed;
      delete e.fixedPosition;
      delete e.fixedWidth;
    });

    delete layoutData.searchText;
    delete layoutData.focusedRowKey;
    delete layoutData.allowedPageSizes;
    delete layoutData.filterPanel;
    delete layoutData.filterValue;
    delete layoutData.pageIndex;
    delete layoutData.pageSize;
    delete layoutData.selectionFilter;

    localStorage.setItem(this.storageKey, JSON.stringify(layoutData));
  };

  private dxDataGridLoadState = () => {
    const layoutData = localStorage.getItem(this.storageKey);

    if (layoutData) {
      return JSON.parse(layoutData);
    }
  };

  private dgGetExportFileName(): string {
    let suffix = '';

    if (this.exportFileAddDateSuffix) {
      suffix = ' ' + formatDate(Date.now(), 'yyyyMMdd_HHmmss', 'en-US');
    }
    return this.exportFileName ?? this.title + suffix;
  }

  onResized(event: ResizedEvent) {
    if (this.viewMode === 'raw') {
      this.renderer.setStyle(this.gridContent!.nativeElement, 'height', `auto`);
      return;
    }

    const width = event.newRect.width;
    const height = event.newRect.height - 50;

    this.renderer.setStyle(
      this.gridContent!.nativeElement,
      'height',
      `${height}px`
    );

    this.renderer.setStyle(
      this.gridContent!.nativeElement,
      'width',
      `${width}px`
    );
  }
}
