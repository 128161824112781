import {Injectable} from '@angular/core';
import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {MessageBoxService} from 'projects/xlibs/src/gui/message-box/services/message-box.service';
import {catchError, finalize, switchMap} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';
import {JwtAuthorizationService} from "projects/xlibs/src/authorization/jwt/jwt-authorization.service";

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
    constructor(
        private messageBox: MessageBoxService,
        private translate: TranslateService,
        private authService: JwtAuthorizationService
    ) {}

    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            catchError((ex: HttpErrorResponse) => {

                //brak autoryzacji
                if (ex.status === 401) {
                    return this.reAuthenticate().pipe(
                        switchMap(() => next.handle(this.authService.getRequestWithAuthorizationToken(request)))
                    );
                }

                if (ex.error?.ucanlo) {
                    this.BllErrorHandler(ex.error.error.message.value);
                }

                return throwError(ex);
            }),
            finalize(() => {
                //this.loadingDialogService.hideDialog();
            })
        ) as Observable<HttpEvent<any>>;
    }

    private BllErrorHandler(message: string) {
        this.messageBox.showInfo(message);
    }

    reAuthenticate(): Observable<any> {
        return this.authService.refresh();
    }
}
