import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-product-localization-remove',
  templateUrl: './product-localization-remove.component.html',
  styleUrls: ['./product-localization-remove.component.scss'],
})
export class ProductLocalizationRemoveComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
