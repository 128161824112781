import { debounce } from 'lodash';

/**
 * Debounce a method, decorator
 */
export function Debounce(ms: number) {
  return function (target: any, key: any, descriptor: any) {
    const oldFunc = descriptor.value;
    const newFunc = debounce(oldFunc, ms);
    descriptor.value = function () {
      // @ts-ignore
      return newFunc.apply(this, arguments);
    };
  };
}
