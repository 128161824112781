import { Component, OnInit } from '@angular/core';
import { DataImportType } from 'projects/ucanlo/bll/models/data/import/data-import-input-data.model';

@Component({
  selector: 'app-data-import',
  templateUrl: './data-import.component.html',
  styleUrls: ['./data-import.component.scss'],
})
export class DataImportComponent implements OnInit {
  public dataImportType: typeof DataImportType = DataImportType;

  constructor() {}

  ngOnInit(): void {}
}
