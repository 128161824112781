import {
  AfterContentInit,
  AfterViewInit,
  Component,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';

import {
  DxDataGridComponent,
  DxFormComponent,
  DxPopupComponent,
} from 'devextreme-angular';
import {
  ActionType,
  EditActionParameterModel,
} from 'projects/xlibs/src/gui/edit-form-wrapper/edit-action-parameter-model';

import { TranslateService } from '@ngx-translate/core';
import { CustomerListComponent } from '../../customers/customer-list/customer-list.component';
import { ProductListSelectComponent } from '../../products/product-list-select/product-list-select.component';
import * as _ from 'lodash';

import { EditFormWrapperComponent } from 'projects/xlibs/src/gui/edit-form-wrapper/edit-form-wrapper.component';
import { CustomerModel } from 'projects/ucanlo/bll/models/customers/customer.model';
import { ProductSelectModel } from 'projects/ucanlo/bll/models/products/product-select.model';
import {
  AccountingTransactionModel,
  AccountingTransactionType,
} from 'projects/ucanlo/bll/models/accounting-transactions/accounting-transaction.model';
import { AccountingTransactionProductModel } from 'projects/ucanlo/bll/models/accounting-transactions/accounting-transaction-product.model';
import { AccountingTransactionsService } from 'projects/ucanlo/bll/services/accounting-transactions/accounting-transactions.service';
import {
  getColumnVisibleIndexByFieldName,
  setFocusOnColumnByFieldName,
} from 'projects/xlibs/src/gui/dev-extreme/dx-data-grid-column-helper';
import { CustomerListSelectComponent } from '../../customers/customer-list-select/customer-list-select.component';
import { CustomersService } from '../../../../../../bll/services/customers/customers.service';

@Component({
  selector: 'app-accounting-transaction-edit',
  templateUrl: './accounting-transaction-edit.component.html',
  styleUrls: ['./accounting-transaction-edit.component.scss'],
})
export class AccountingTransactionEditComponent
  implements OnInit, AfterContentInit, AfterViewInit
{
  @ViewChild('editFormWrapperAccountingTransaction')
  editFormWrapper: EditFormWrapperComponent | null = null;

  @ViewChild('appCustomerListSelect')
  appCustomerListSelect: CustomerListSelectComponent | null = null;

  @ViewChild('appProductListSelect')
  appProductListSelect: ProductListSelectComponent | null = null;

  @ViewChild('editForm') editForm: DxFormComponent | null = null;

  @ViewChild('dataGridProducts')
  dataGridProducts: DxDataGridComponent | null = null;

  @Input()
  editActionParams: EditActionParameterModel | null = null;

  transaction: AccountingTransactionModel | null = null;
  products: Array<AccountingTransactionProductModel> =
    new Array<AccountingTransactionProductModel>();
  // Przekopiowuje dane z płatnika do adresu dostawy
  buttonCustomerCopyDataOptions = {
    text: this.translate.instant('copy address data from customer'),
    type: 'default',
    stylingMode: 'outlined',
    width: 200,
    onClick: () => {
      this.repository.copyDataFromTranscationCustomerToShippmentAddress(
        this.transaction!
      );
    },
  };
  // Button - pobiera kontrahenta ze słownika
  buttonCustomerGetDataOptions = {
    text: this.translate.instant('get customer from dictionary'),
    type: 'default',
    stylingMode: 'outlined',
    width: 200,
    onClick: () => {
      console.log('#clikc');
      this.appCustomerListSelect?.show();
    },
  };

  constructor(
    private repository: AccountingTransactionsService,
    private customerService: CustomersService,
    private translate: TranslateService
  ) {}

  get title() {
    switch (this.editActionParams?.mode as AccountingTransactionType) {
      case AccountingTransactionType.Delivery:
        return this.translate.instant(
          'accounting transaction delivery - edit long'
        );
      case AccountingTransactionType.Shipping:
        return this.translate.instant(
          'accounting transaction shipping - edit long'
        );
    }
  }

  public get differentShippingAddress(): boolean {
    return this.transaction!.differentShippingAddress;
  }

  ngOnInit(): void {}

  // region Kontrahent

  ngAfterContentInit() {
    console.log('ngAfterContentInit');
    this.transaction = this.editActionParams?.model;
    this.products = this.transaction!.details;
  }

  ngAfterViewInit() {
    console.log('ngAfterViewInit');

    this.editFormWrapper?.init(
      this.editActionParams!.model,
      this.editActionParams!,
      () => {
        this.save();
      }
    );
  }

  onSelectCustomer(customerId: number | null) {
    console.log('customerId', customerId);

    if (customerId) {
      this.customerService.getById(customerId).subscribe((customer) => {
        this.repository.copyDataFromCustomerToTransactionCustomer(
          this.transaction!,
          customer
        );
      });
    }
  }

  // endregion

  // region Produkty

  productListButtonsActionDelete(index: number) {
    if (this.dataGridProducts) {
      this.dataGridProducts.instance.deleteRow(index);
    }
  }

  onSelectProduct(modifiedProducts: Array<ProductSelectModel> | null) {
    if (modifiedProducts) {
      this.updateProductsInTransaction(modifiedProducts);
      this.dataGridProducts?.instance.refresh();
    }
  }

  productListButtonsAction(e: EditActionParameterModel) {
    switch (e.actionType) {
      case ActionType.Add:
        this.productListButtonsActionAdd();
        break;
      case ActionType.Delete:
        this.productListButtonsActionDelete(e.index);
        break;
    }
  }

  productListButtonsActionAdd() {
    this.dataGridProducts?.instance.saveEditData();

    const productSelectedList = this.products.map((p) => {
      return {
        id: p.productId,
        name: p.productName,
        number: p.productNumber,
        quantity: p.quantity,
      };
    });

    this.appProductListSelect!.show(productSelectedList);
  }

  dataGridProductsOnFocusedCellChanging(e: any) {
    e.newColumnIndex = getColumnVisibleIndexByFieldName(
      this.dataGridProducts!,
      'quantity'
    );
  }

  dataGridProductsOnRowClick(e: any) {
    setFocusOnColumnByFieldName(this.dataGridProducts!, e.rowIndex, 'quantity');
  }

  private updateProductsInTransaction(
    selectedProducts: Array<ProductSelectModel>
  ) {
    //dodanie, aktualizacja produktu
    selectedProducts.forEach((product) => {
      const productInCurrentTransaction = this.products.find(
        (p) => p.productId == product.id
      );

      //zmiana ilości
      if (productInCurrentTransaction) {
        productInCurrentTransaction.quantity = product.quantity;
      }

      //dodanie produktu
      if (!productInCurrentTransaction) {
        this.products.push({
          productId: product.id,
          productNumber: product.number!,
          productName: product.name!,
          quantity: product.quantity,
          expirationDate: product.expirationDate ?? null,
          productHasExpirationDate: product.hasExpirationDate!,
        });
      }
    });

    //usuniecie produktu
    this.products = this.products.filter((p) =>
      selectedProducts.map((p) => p.id).includes(p.productId)
    );
  }

  // endregion

  private save() {
    //wymuszam zapis, bez wymuszenia nie zapisywała się ostatnia modyfikacja ilości
    this.dataGridProducts?.instance.saveEditData();

    this.repository
      .save(this.editActionParams!, this.transaction!)
      .subscribe((res) => {
        this.editFormWrapper?.close();
        this.editActionParams?.dataGrid?.dgRefreshAndFocusRowById(res.id);
      });
  }
}
