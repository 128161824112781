import { EventManager } from '@angular/platform-browser';
import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ScreenService {
  onResize$ = new EventEmitter<{ width: number; height: number }>();

  width: number = window.innerWidth;
  height: number = window.innerHeight;

  get isMobile(): boolean {
    return this.width < 481;
  }

  constructor(eventManager: EventManager) {
    eventManager.addGlobalEventListener('window', 'resize', (e) => {
      this.width = e.target.innerWidth;
      this.height = e.target.innerHeight;
      this.onResize$.emit({ width: this.width, height: this.height });
    });
  }
}
