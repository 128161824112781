import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { BaseRepository } from 'projects/xlibs/src/bll/services/base-repository';

@Injectable({
  providedIn: 'root',
})
export class ProductInLocationsService extends BaseRepository {
  constructor(private http: HttpClient, private translate: TranslateService) {
    super('productInLocation');
  }

  public odataProductInLocationView(
    locationId: number | null = null,
    productId: number | null = null
  ) {
    let filter: Array<any> | null = null;

    if (locationId) {
      filter = ['locationId', '=', locationId];
    }
    if (productId) {
      filter = ['productId', '=', productId];
    }

    return this.getDataSource(
      [
        'id',
        'locationId',
        'locationName',
        'locationBarcode',
        'productId',
        'productName',
        'productNumber',
        'productBarcode',
        'quantity',
        'quantityReserved',
      ],
      'productInLocationView',
      filter
    );
  }

  public odataProductInLocationDetailView() {
    return this.getDataSource(
      [
        'id',
        'productName',
        'productNumber',
        'productBarcode',
        'locationName',
        'locationBarcode',
        'locationId',
        'productId',
        'quantity',
        'quantityReserved',
        'deliveryBatchId',
        'expirationDate',
        'serialNumber',
        'inventoryTransactionNumber',
        'accountingTransactionNumber',
        'accountingTransactionDate',
        'accountingTransactionCustomer',
      ],
      'productInLocationDetailView'
    );
  }

  public odataProductInLocationByLocationView() {
    return this.getDataSource(
      ['id', 'name', 'barcode', 'quantity', 'quantityReserved'],
      'productInLocationByLocationView'
    );
  }

  public odataProductInLocationByProductView() {
    return this.getDataSource(
      ['id', 'name', 'number', 'barcode', 'quantity', 'quantityReserved'],
      'productInLocationByProductView'
    );
  }
}
