<div style="display: flex; height: calc(100% - 0px); flex-direction: column">
  <div style="flex-grow: 4; height: 0; margin-bottom: 15px">
    <xlib-data-grid-wrapper
      [showAddButton]="false"
      [showCopyButton]="false"
      [showDeleteButton]="false"
      [showEditButton]="false"
      [title]="'product in location list by location' | translate"
      storageKeySufix="product-in-location-by-location"
    >
      <dx-data-grid
        (onFocusedRowChanged)="onFocusedRowChanged($event)"
        [dataSource]="dataSource"
      >
        <dxi-column
          caption="{{ 'location name' | translate }}"
          dataField="name"
        ></dxi-column>
        <dxi-column
          caption="{{ 'location barcode' | translate }}"
          dataField="barcode"
        ></dxi-column>
        <dxi-column
          caption="{{ 'quantity' | translate }}"
          dataField="quantity"
        ></dxi-column>
        <dxi-column
          caption="{{ 'quantity reserved' | translate }}"
          dataField="quantityReserved"
        ></dxi-column>
      </dx-data-grid>
    </xlib-data-grid-wrapper>
  </div>

  <div style="flex-grow: 6; height: 0">
    <xlib-data-grid-wrapper
      #gridWrapperDetails
      [showAddButton]="false"
      [showCopyButton]="false"
      [showDeleteButton]="false"
      [showEditButton]="false"
      [title]="'products' | translate"
      storageKeySufix="product-in-location-by-location"
    >
      <dx-data-grid
        [dataSource]="dataSourceDetail"
        noDataText="{{
          'select location from the list to see the products' | translate
        }}"
      >
        <dxo-load-panel [enabled]="false"></dxo-load-panel>

        <dxi-column
          caption="{{ 'product number' | translate }}"
          dataField="productNumber"
        ></dxi-column>
        <dxi-column
          caption="{{ 'product name' | translate }}"
          dataField="productName"
        ></dxi-column>
        <dxi-column
          caption="{{ 'product barcode' | translate }}"
          dataField="productBarcode"
        ></dxi-column>
        <dxi-column
          caption="{{ 'quantity' | translate }}"
          dataField="quantity"
        ></dxi-column>
        <dxi-column
          caption="{{ 'quantity reserved' | translate }}"
          dataField="quantityReserved"
        ></dxi-column>
      </dx-data-grid>
    </xlib-data-grid-wrapper>
  </div>
</div>
