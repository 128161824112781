import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { BaseRepository } from 'projects/xlibs/src/bll/services/base-repository';
import { EnumInfoModel } from 'projects/xlibs/src/enums/enum-info.model';

@Injectable({
  providedIn: 'root',
})
export class EnumsInfoRepositoryService extends BaseRepository {
  constructor(private http: HttpClient, private translate: TranslateService) {
    super('enums');
  }

  getAll(): Observable<Array<EnumInfoModel>> {
    return this.http.get<Array<EnumInfoModel>>(`${this.endpointUrl}`);
  }
}
