import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'rolesDescription',
})
export class RolesDescriptionPipe implements PipeTransform {
  constructor(private translate: TranslateService) {}

  transform(value: string): string {
    const roles = new Array<string>();

    this.prepareFor('Management', value, roles, 'management');
    this.prepareFor('Delivery', value, roles, 'delivery');
    this.prepareFor('Picking', value, roles, 'picking');
    this.prepareFor('Shipping', value, roles, 'shipping');

    return roles.join(', ');
  }

  private prepareFor(
    roleName: string,
    value: string,
    roles: Array<string>,
    translateKey: string
  ) {
    if (value.includes(roleName)) {
      roles.push(this.translate.instant(translateKey));
    }
  }
}
