<xlib-data-grid-wrapper
  #grid
  (buttonsAction)="gridViewAction($event)"
  [exportFileName]="'products' | translate"
  [title]="'product list' | translate"
  entityName="Product"
>
  <dx-data-grid
    (onRowUpdating)="onRowUpdating($event)"
    (onSaving)="onSaving($event)"
    [dataSource]="dataSource"
  >
    <dxo-editing
      [(changes)]="changes"
      [allowAdding]="false"
      [allowDeleting]="false"
      [allowUpdating]="shoppingCartMode"
      [selectTextOnEditStart]="true"
      [startEditAction]="'click'"
      [useIcons]="true"
      mode="cell"
    >
    </dxo-editing>
    ><dxi-column
      [allowEditing]="false"
      caption="{{ 'product number' | translate }}"
      dataField="number"
    ></dxi-column>
    <dxi-column
      [allowEditing]="false"
      caption="{{ 'name' | translate }}"
      dataField="name"
    ></dxi-column>
    <dxi-column
      [allowEditing]="false"
      caption="{{ 'barcode' | translate }}"
      dataField="barcode"
    ></dxi-column>
    <dxi-column
      [showInColumnChooser]="false"
      [visible]="shoppingCartMode"
      caption="{{ 'quantity' | translate }}"
      dataField="quantity"
    ></dxi-column>
  </dx-data-grid>
</xlib-data-grid-wrapper>
