import {Component, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ProductInLocationsService} from 'projects/ucanlo/bll/services/store/product-in-locations.service';
import {PageManagerService} from "projects/xlibs/src/gui/page-manager/page-manager.service";

@Component({
    selector: 'app-product-in-location-list',
    templateUrl: './product-in-location-list.component.html',
    styleUrls: ['./product-in-location-list.component.scss'],
})
export class ProductInLocationListComponent implements OnInit {
    dataSource: any;

    constructor(
        private repo: ProductInLocationsService,
        private pageManagerService: PageManagerService,
        private translate: TranslateService
    ) {
        this.dataSource = repo.odataProductInLocationView();
    }

    ngOnInit(): void {}
}
