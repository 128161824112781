import { Injectable } from '@angular/core';
import { MenuItemModel } from '../core/models/menu-item-model';
import { AccountUserListComponent } from '../pages/accounts/account-user-list/account-user-list.component';
import { ProductListComponent } from '../pages/products/product-list/product-list.component';
import { LayoutTestComponent } from '../pages/_tests/layout-test/layout-test.component';
import { TranslateService } from '@ngx-translate/core';
import { SettingsEditComponent } from '../pages/layout/settings-edit/settings-edit.component';
import { DataImportComponent } from '../pages/data/import/data-import/data-import.component';
import { LocationListComponent } from '../pages/layout/location-list/location-list.component';
import { CustomerListComponent } from '../pages/customers/customer-list/customer-list.component';
import { AccountingTransactionListComponent } from '../pages/accounting-transactions/accounting-transaction-list/accounting-transaction-list.component';
import { ProductLocalizationAddComponent } from '../pages/fallback-operations/product-localization-add/product-localization-add.component';
import { ProductLocalizationRemoveComponent } from '../pages/fallback-operations/product-localization-remove/product-localization-remove.component';
import { ProductInLocationByLocationListComponent } from '../pages/store/product-in-location-by-location-list/product-in-location-by-location-list.component';
import { ProductInLocationByProductListComponent } from '../pages/store/product-in-location-by-product-list/product-in-location-by-product-list.component';
import { ProductListSelectComponent } from '../pages/products/product-list-select/product-list-select.component';
import { DxDataGridTestComponent } from '../pages/_tests/dx-data-grid-test/dx-data-grid-test.component';
import { FallbackDeliveryComponent } from '../pages/fallback-procedures/fallback-delivery/fallback-delivery.component';

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  appSortName = 'UCANLO';
  appLongName = '`UCANLO` system obsługi magazynu';
  // startPage = AccountingTransactionListComponent;
  startPage = AccountingTransactionListComponent;
  startPageTitle = 'Home';

  startFirstTime = true;

  constructor(private translate: TranslateService) {}

  get menu(): Array<MenuItemModel> {
    return [
      {
        text: 'test layout',
        component: LayoutTestComponent,
        icon: 'variable',
      },
      {
        text: 'test dx-data-grid',
        component: DxDataGridTestComponent,
        icon: 'variable',
      },
      // {
      //   text: 'menu',
      //   icon: 'variable',
      //   items: [
      //     { text: '1' },
      //     { text: '2' },
      //     { text: '3' },
      //     { text: '4' },
      //     { text: '5' },
      //     { text: '6' },
      //     { text: '7' },
      //     { text: '8' },
      //   ],
      // },

      //-------------------------------------------------------------------------------------- ACCOUNTING TRANSACTIONS

      {
        text: this.translate.instant('accounting orders'),
        icon: 'doc',
        items: [
          {
            text: this.translate.instant(
              'accounting transaction delivery list short'
            ),
            tabText: this.translate.instant(
              'accounting transaction delivery list long'
            ),
            component: AccountingTransactionListComponent,
            params: {
              mode: 'Delivery',
            },
            icon: 'download',
          },
          {
            text: this.translate.instant(
              'accounting transaction shipping list short'
            ),
            tabText: this.translate.instant(
              'accounting transaction shipping list long'
            ),
            component: AccountingTransactionListComponent,
            params: {
              mode: 'Shipping',
            },
            icon: 'upload',
          },
        ],
      },

      //------------------------------------------------------------------------------------- MANAGEMENT

      {
        text: this.translate.instant('management'),
        icon: 'preferences',
        items: [
          {
            text: this.translate.instant('user list'),
            component: AccountUserListComponent,
          },

          {
            text: this.translate.instant('data import'),
            component: DataImportComponent,
          },
        ],
      },
      //-------------------------------------------------------------------------------------- LAYOUT

      {
        text: this.translate.instant('layout settings'),
        icon: 'contentlayout',
        items: [
          {
            text: this.translate.instant('general'),
            component: SettingsEditComponent,
          },
          {
            text: this.translate.instant('locations'),
            component: LocationListComponent,
          },
        ],
      },

      //-------------------------------------------------------------------------------------- PRODUCTS

      {
        text: this.translate.instant('products'),
        icon: 'fields',
        items: [
          {
            text: this.translate.instant('product list'),
            component: ProductListComponent,
          },
        ],
      },

      //-------------------------------------------------------------------------------------- CUSTOMERS

      {
        text: this.translate.instant('customers'),
        icon: 'user',
        items: [
          {
            text: this.translate.instant('customer list'),
            component: CustomerListComponent,
          },
        ],
      },

      //-------------------------------------------------------------------------------------- OPERACJE AWARYJNE

      {
        text: this.translate.instant('fallback options'),
        icon: 'hidepanel',
        items: [
          {
            text: this.translate.instant('fallback delivery'),
            component: FallbackDeliveryComponent,
          },
          {
            text: this.translate.instant('fallback shipping'),
            component: ProductLocalizationRemoveComponent,
          },
        ],
      },

      //-------------------------------------------------------------------------------------- OPERACJE MAGAZYNOWE

      {
        text: this.translate.instant('store'),
        icon: 'box',
        items: [
          {
            text: this.translate.instant('add product to location'),
            component: ProductLocalizationAddComponent,
            icon: 'spinnext',
          },
          {
            text: this.translate.instant('remove product from location'),
            component: ProductLocalizationRemoveComponent,
            icon: 'spinnext',
          },
          {
            text: this.translate.instant('product in location by product'),
            component: ProductInLocationByProductListComponent,
            icon: 'spinnext',
          },
          {
            text: this.translate.instant('product in location by location'),
            component: ProductInLocationByLocationListComponent,
            icon: 'spinnext',
          },
        ],
      },
    ];
  }
}
