// funkcje pomocnicze do generowania haseł, sprawdzania siły haseł

/**
 * generuje hasło o podanej długości
 * @param passwordLength
 */
export function getRandomPassword(passwordLength: number) {
  var numberChars = '0123456789';
  var upperChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  var lowerChars = 'abcdefghijklmnopqrstuvwxyz';
  var specialChars = '!@#$%^&*()[]{}:;/.,<>?';
  var allChars = numberChars + upperChars + lowerChars + specialChars;
  var randPasswordArray = Array(passwordLength);
  randPasswordArray[0] = numberChars;
  randPasswordArray[1] = upperChars;
  randPasswordArray[2] = lowerChars;
  randPasswordArray[3] = specialChars;

  randPasswordArray = randPasswordArray.fill(allChars, 4);
  return shuffleArray(
    randPasswordArray.map(function (x) {
      return x[Math.floor(Math.random() * x.length)];
    })
  ).join('');
}

/**
 * Weryfikuje siłę hasła wg zasady min 7 liter, hasło musi zawierać co najmneij: duża litera, mała litera, cyfra i znak specjalny
 * @param pass
 */
export function checkPasswordRules(pass: string) {
  const digits = /\d/.test(pass);
  const lower = /[a-z]/.test(pass);
  const upper = /[A-Z]/.test(pass);
  const nonWords = /\W/.test(pass);

  if (!digits || !lower || !upper || !nonWords || pass.length < 7) return false;
  else return true;
}

function shuffleArray(array: any) {
  for (let i = array.length - 1; i > 0; i--) {
    var j = Math.floor(Math.random() * (i + 1));
    var temp = array[i];
    array[i] = array[j];
    array[j] = temp;
  }
  return array;
}
