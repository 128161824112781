<xlib-data-grid-wrapper
  #dataGridWrapper
  [saveLayout]="saveLayout"
  [viewMode]="viewMode"
>
  <dx-data-grid
    [dataSource]="dataSource"
    [showBorders]="true"
    [columnAutoWidth]="true"
    keyExpr="id"
  >
    <dxi-toolbar-item [visible]="false"></dxi-toolbar-item>

    <dxi-column
      dataField="statusInfo"
      caption="{{ 'state' | translate }}"
      [customizeText]="customizeTextTranslate"
    ></dxi-column>

    <dxi-column
      dataField="status"
      caption="{{ 'status' | translate }}"
      [visible]="false"
    ></dxi-column>

    <dxi-column
      dataField="locationName"
      caption="{{ 'location' | translate }}"
      [visible]="false"
    ></dxi-column>

    <dxi-column
      dataField="accountUserName"
      caption="{{ 'user' | translate }}"
      [visible]="false"
    ></dxi-column>

    <dxi-column
      dataField="quantity"
      dataType="number"
      caption="{{ 'quantity' | translate }}"
    ></dxi-column> </dx-data-grid
></xlib-data-grid-wrapper>
