import { Directive, HostBinding } from '@angular/core';

@Directive({
  selector: '[dx-color-inverse]',
})
export class AddClassDxColorInverseDirective {
  @HostBinding('class')
  elementClass =
    'elements-theme-background-color-as-text-color elements-theme-text-color-as-background-color';

  constructor() {}
}
