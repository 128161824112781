import {
  AfterViewInit,
  Component,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { EditActionParameterModel } from 'projects/xlibs/src/gui/edit-form-wrapper/edit-action-parameter-model';
import { DxFormComponent } from 'devextreme-angular';
import { dxElementInFormAndTab100Height100Percent } from '../../../core/helpers/dx/dx-element-size';
import { EditFormWrapperComponent } from 'projects/xlibs/src/gui/edit-form-wrapper/edit-form-wrapper.component';
import { ProductModel } from 'projects/ucanlo/bll/models/products/product.model';
import { ProductBarcodeModel } from 'projects/ucanlo/bll/models/products/product-barcode.model';
import { ProductsService } from 'projects/ucanlo/bll/services/products/products.service';
import { InventoryModelsRepositoryService } from 'projects/ucanlo/bll/services/common/inventory-models-repository.service';

@Component({
  selector: 'app-product-edit',
  templateUrl: './product-edit.component.html',
  styleUrls: ['./product-edit.component.scss'],
})
export class ProductEditComponent implements OnInit, AfterViewInit {
  @ViewChild('editFormWrapper')
  editFormWrapper: EditFormWrapperComponent | null = null;
  @ViewChild('editForm') editForm: DxFormComponent | null = null;

  @Input()
  editActionParams: EditActionParameterModel | null = null;

  idForNewBarcode = -1;

  product: ProductModel | null = null;
  barcodes: Array<ProductBarcodeModel> = new Array<ProductBarcodeModel>();
  inventoryMethodTypes: any;
  dxElementInFormAndTab100Height100Percent: string;

  constructor(
    private repository: ProductsService,
    private inventoryMethodRepository: InventoryModelsRepositoryService
  ) {
    this.dxElementInFormAndTab100Height100Percent =
      dxElementInFormAndTab100Height100Percent();

    this.inventoryMethodTypes =
      this.inventoryMethodRepository.getInventoryMethodTypes(true);
  }

  ngOnInit(): void {
    const model = this.editActionParams?.model;
    this.product = model;
    this.barcodes = model.barcodes;
  }

  ngAfterViewInit() {
    this.editFormWrapper?.init(this.product, this.editActionParams!, () =>
      this.save()
    );
  }

  /**
   * Ustawia domyślne wartości dla nowego rekordu typu Barcode
   * @param $event
   */
  newBarcode($event: any) {
    $event.data.quantity = 1;
    // 'minusowe wartości' - pozwala na identyfikację nowych rekordów po stornie API + elements-data-grid wymaga unikalnego klucza
    $event.data.id = this.idForNewBarcode--;
  }

  private save() {
    this.repository
      .save(this.editActionParams!, this.product as ProductModel)
      .subscribe((res) => {
        this.editFormWrapper?.close();
        this.editActionParams?.dataGrid?.dgRefreshAndFocusRowById(res.id);
      });
  }

  onImgError($event: ErrorEvent) {
    console.log('#####');
  }
}
