<dx-popup #popupGetProduct [showTitle]="false">
  <div *dxTemplate="let data of 'content'">
    <app-product-list-select
      #popupGetProductComponent
      (onSelectProduct)="popupGetProductAfterSelect($event)"
      [withExpirationDate]="true"
    ></app-product-list-select>
  </div>
</dx-popup>

<xlib-edit-form-wrapper
  #editFormWrapper
  (save)="savePrepare()"
  [title]="'add product to location' | translate"
>
  <dx-form #editForm [colCount]="1" [formData]="editObject" labelLocation="top">
    <div *dxTemplate="let data of 'selectLocationItem'">
      <span class="strong">{{ data.name }}</span
      >&nbsp;
      <span class="gray">{{ data.barcode }}</span>
    </div>
    <dxi-item
      [editorOptions]="dxSelectBoxCheckLocationOptions"
      [isRequired]="true"
      [label]="{ text: 'location name' | translate }"
      dataField="locationId"
      editorType="dxSelectBox"
    >
    </dxi-item>

    <dxi-item>
      <xlib-data-grid-wrapper
        #dataGridProductsWrapper
        (buttonsAction)="productListButtonsAction($event)"
        [confirmDelete]="false"
        [showCopyButton]="false"
        [showEditButton]="false"
        [showGroupPanel]="false"
        [showRefreshButton]="false"
        [showSearchPanel]="false"
        [showTitleWithNormalFont]="true"
        [title]="'products to add to location' | translate"
      >
        <dx-data-grid
          #dataGridProducts
          (onCellPrepared)="onCellPrepared($event)"
          (onRowClick)="onRowClick($event)"
          [dataSource]="products"
          [height]="500"
          [repaintChangesOnly]="true"
          [showBorders]="true"
          keyExpr="productId"
        >
          <dxo-editing
            [allowAdding]="false"
            [allowDeleting]="true"
            [allowUpdating]="true"
            [selectTextOnEditStart]="true"
            [startEditAction]="'click'"
            mode="cell"
          >
            <dxo-texts confirmDeleteMessage=""></dxo-texts>
          </dxo-editing>

          <dxo-keyboard-navigation
            [editOnKeyPress]="true"
            enterKeyAction="startEdit"
            enterKeyDirection="column"
          >
          </dxo-keyboard-navigation>

          <dxi-column [visible]="false" type="buttons"></dxi-column>

          <dxi-column
            [allowEditing]="false"
            caption="{{ 'product number' | translate }}"
            dataField="productNumber"
          ></dxi-column>
          <dxi-column
            [allowEditing]="false"
            caption="{{ 'name' | translate }}"
            dataField="productName"
          ></dxi-column>
          <dxi-column
            [editorOptions]="{
              min: 0,
              format: '###0',
              step: 0
            }"
            [fixed]="true"
            caption="{{ 'quantity' | translate }}"
            cssClass="strong"
            dataField="quantity"
            dataType="number"
            fixedPosition="right"
            headerCellTemplate="editableHeaderTemplate"
          ></dxi-column>
          <dxi-column
            [fixed]="true"
            caption="{{ 'expiration date' | translate }}"
            dataField="expirationDate"
            dataType="date"
            fixedPosition="right"
            format="yyyy-MM-dd"
            headerCellTemplate="editableHeaderTemplate"
          ></dxi-column>
          <div *dxTemplate="let info of 'editableHeaderTemplate'">
            {{ info.column.caption }} <i class="dx-icon-edit"></i>
          </div>
        </dx-data-grid>
      </xlib-data-grid-wrapper>
    </dxi-item>
  </dx-form>
</xlib-edit-form-wrapper>
