import { Component, Input, OnInit } from '@angular/core';
import {
  faPauseCircle,
  faPlayCircle,
  faStopCircle,
  faUser,
} from '@fortawesome/free-regular-svg-icons';
import { ActionButtonIconType } from './action-button-large.model';

@Component({
  selector: 'xlib-action-button-large',
  templateUrl: './action-button-large.component.html',
  styleUrls: ['./action-button-large.component.css'],
})
export class ActionButtonLargeComponent implements OnInit {
  constructor() {}

  @Input()
  text: string = 'Action name';

  @Input()
  icon: ActionButtonIconType = ActionButtonIconType.Start;

  @Input()
  description: string | undefined = 'Description';

  @Input()
  action: string | undefined = 'ACTION_TO_RUN';

  @Input()
  disabled: boolean | undefined = false;

  // iconClass = 'image dx-theme-accent-as-text-color ';

  faIcon: any;

  faPauseCircle = faPauseCircle;
  faPlayCircle = faPlayCircle;
  faStopCircle = faStopCircle;
  faUser = faUser;

  ngOnInit(): void {
    //this.iconClass = `${this.iconClass} dx-icon-${this.icon}`;

    switch (this.icon) {
      case ActionButtonIconType.Start:
        this.faIcon = this.faPlayCircle;
        break;
      case ActionButtonIconType.Stop:
        this.faIcon = this.faStopCircle;
        break;
      case ActionButtonIconType.Pause:
        this.faIcon = this.faPauseCircle;
        break;
      case ActionButtonIconType.User:
        this.faIcon = this.faUser;
        break;
    }
  }
}
