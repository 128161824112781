<div class="menu-container">
  <dx-tree-view
    class="menu"
    (keydown)="keydown($event)"
    (onItemClick)="clickItem($event)"
    [height]="'calc(100vh - 100px)'"
    [items]="menuOptions"
    [searchEnabled]="true"
    searchMode="contains"
  ></dx-tree-view>

  <div class="buttons">
    <div class="left">
      <dx-button
        (onClick)="buttonReportsAction()"
        [hint]="'warehouse status' | translate"
        class="button"
        icon="chart"
        stylingMode="text"
      ></dx-button>
    </div>
    <div class="space"></div>
    <div class="right">
      <dx-button
        (onClick)="buttonNewWindowAction()"
        [hint]="'new window' | translate"
        class="button"
        icon="add"
        stylingMode="text"
      ></dx-button>
    </div>
    <div class="right">
      <dx-button
        (onClick)="buttonScreenLockAction()"
        [hint]="'screen lock' | translate"
        class="button"
        icon="key"
        stylingMode="text"
      ></dx-button>
    </div>
  </div>
</div>
