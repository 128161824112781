<div style="width: 500px; height: 500px; background: yellow">
  <as-split direction="vertical">
    <as-split-area [size]="40">
      <dx-data-grid
        [allowColumnReordering]="true"
        [allowColumnResizing]="true"
        [columnAutoWidth]="true"
        [columnMinWidth]="50"
        [columnResizingMode]="'widget'"
        [dataSource]="dataSource"
        [focusedRowEnabled]="true"
        [repaintChangesOnly]="true"
        [showBorders]="true"
      >
        <dxo-column-chooser [enabled]="true"></dxo-column-chooser>
        <dxo-scrolling mode="infinite"></dxo-scrolling>
        <dxo-sorting mode="multiple"></dxo-sorting>
        <dxo-search-panel [visible]="true"></dxo-search-panel>

        <dxi-column
          [allowEditing]="false"
          caption="{{ 'product number' | translate }}"
          dataField="number"
        ></dxi-column>
        <dxi-column
          [allowEditing]="false"
          caption="{{ 'name' | translate }}"
          dataField="name"
        ></dxi-column>
        <dxi-column
          [allowEditing]="false"
          caption="{{ 'barcode' | translate }}"
          dataField="barcode"
        ></dxi-column>
        <dxi-column
          caption="{{ 'quantity' | translate }}"
          dataField="quantity"
        ></dxi-column>
      </dx-data-grid>
    </as-split-area>
    <as-split-area [size]="60">
      <dx-data-grid
        [allowColumnReordering]="true"
        [allowColumnResizing]="true"
        [columnAutoWidth]="true"
        [columnMinWidth]="50"
        [columnResizingMode]="'widget'"
        [dataSource]="dataSource"
        [focusedRowEnabled]="true"
        [height]="'100%'"
        [repaintChangesOnly]="true"
        [showBorders]="true"
        [width]="'100%'"
      >
        <dxo-column-chooser [enabled]="true"></dxo-column-chooser>
        <dxo-scrolling
          columnRenderingMode="virtual"
          mode="infinite"
        ></dxo-scrolling>
        <dxo-sorting mode="multiple"></dxo-sorting>

        <dxi-column
          [allowEditing]="false"
          caption="{{ 'product number' | translate }}"
          dataField="number"
        ></dxi-column>
        <dxi-column
          [allowEditing]="false"
          caption="{{ 'name' | translate }}"
          dataField="name"
        ></dxi-column>
        <dxi-column
          [allowEditing]="false"
          caption="{{ 'barcode' | translate }}"
          dataField="barcode"
        ></dxi-column>
        <dxi-column
          caption="{{ 'quantity' | translate }}"
          dataField="quantity"
        ></dxi-column>
      </dx-data-grid>
    </as-split-area>
  </as-split>
</div>
