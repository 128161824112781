import {
  AfterViewInit,
  Component,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';

import { DataGridWrapperComponent } from 'projects/xlibs/src/gui/data-grid-wrapper/data-grid-wrapper.component';

import { TranslateService } from '@ngx-translate/core';
import {
  ActionType,
  EditActionParameterModel,
} from 'projects/xlibs/src/gui/edit-form-wrapper/edit-action-parameter-model';
import { AccountingTransactionEditComponent } from '../accounting-transaction-edit/accounting-transaction-edit.component';
import { DxDataGridComponent } from 'devextreme-angular';
import { environment } from '../../../../environments/environment';

import { AccountingTransactionsService } from 'projects/ucanlo/bll/services/accounting-transactions/accounting-transactions.service';
import { AccountingTransactionType } from 'projects/ucanlo/bll/models/accounting-transactions/accounting-transaction.model';
import { Debounce } from 'projects/xlibs/src/helpers/debounce-helper';
import { EnumInfoService } from 'projects/xlibs/src/enums/enum-info.service';
import { PageManagerService } from 'projects/xlibs/src/gui/page-manager/page-manager.service';
import { AccountingTransactionsViewService } from '../../../../../../bll/services/accounting-transactions/accounting-transactions-view.service';
import { PageItem } from 'projects/xlibs/src/gui/page-manager/page-item';
import { InventoryActionListComponent } from '../../inventory-transactions/inventory-action-list/inventory-action-list.component';
import { InventoryActionsDeliveryToLocationViewService } from '../../../../../../bll/services/inventory-transactions/inventory-actions-delivery-to-location-view.service';
import { TranslateHelperService } from '../../../shared/services/translate-helper.service';
import { AccountingTransactionStatusHistoryViewService } from '../../../../../../bll/services/accounting-transactions/accounting-transaction-status-history-view.service';
import { InventoryActionStorageInfoListComponent } from '../../inventory-transactions/inventory-action-storage-info-list/inventory-action-storage-info-list.component';
import {
  ActionButtonIconType,
  ActionButtonLargeModel,
} from '../../../../../../../xlibs/src/gui/action-button/action-button-large/action-button-large.model';
import { debounce } from 'lodash';

@Component({
  selector: 'app-accounting-transaction-list',
  templateUrl: './accounting-transaction-list.component.html',
  styleUrls: ['./accounting-transaction-list.component.scss'],
})
export class AccountingTransactionListComponent
  implements OnInit, AfterViewInit
{
  @Input() mode: AccountingTransactionType = AccountingTransactionType.Delivery;

  dataSourceAccountingTransactions: any;
  dataSourceAccountingTransactionsDetailsAndActions: any;
  dataSourceAccountingTransactionsDetailsAndStorageInfo: any;
  dataSourceInventoryActionsDeliveryToLocationView: any;
  dataSourceAccountingTransactionsStatusHistoryView: any;

  deliveryStatusTypeLookup: any;

  statusLookup: any;

  accountingTransactionActions: Array<ActionButtonLargeModel> = [
    {
      text: 'Otwórz dostawę',
      icon: ActionButtonIconType.Start,
      description:
        'Otwiera wybraną dostawę pozwalając na magazynowanie towaru w lokalizacjach przez użytkowmików.',
      actionName: 'DELIVERY_OPEN',
      selectedRowRequired: true,
    },
    {
      text: 'Wstrzymaj dostawę',
      icon: ActionButtonIconType.Pause,
      description:
        'Zatrzymuje dostawę. Użytkownicy nie będą mogli pobrać towaru z tej dostawy.',
      actionName: 'DELIVERY_PAUSE',
      disabled: true,
      selectedRowRequired: true,
    },
    {
      text: 'Zamknij dostawę',
      icon: ActionButtonIconType.Stop,
      description:
        'Zamyka dostawę. Więcej towaru nie będzie już można przyjąć. Nieprzyjęte towary potraktowane zostaną jako towar brakujący w dostawie.',
      actionName: 'DELIVERY_PAUSE',
      selectedRowRequired: false,
    },
    {
      text: 'Przypisz użytkowników',
      icon: ActionButtonIconType.User,
      description:
        'Przypisuje użytkowników do dostawy. Wyłącznie przypisani użytkownicy będą mogli magazynować towar z wybranej dostawy.',
      actionName: 'DELIVERY_PAUSE',
      selectedRowRequired: false,
    },
  ];

  @ViewChild('dataGridWrapperHeader')
  dataGridWrapperHeader: DataGridWrapperComponent | null = null;

  @ViewChild('dataGridWrapperDetailTaskInfo')
  dataGridWrapperDetailTaskInfo: DataGridWrapperComponent | null = null;

  @ViewChild('dataGridWrapperDetailStorageInfo')
  dataGridWrapperDetailStorageInfo: DataGridWrapperComponent | null = null;

  @ViewChild('dataGridHeader')
  dataGridHeader: DxDataGridComponent | null = null;

  constructor(
    private dataService: AccountingTransactionsService,
    private dataViewService: AccountingTransactionsViewService,
    private accountingTransactionStatusHistoryViewService: AccountingTransactionStatusHistoryViewService,
    private inventoryActionsDeliveryToLocationViewService: InventoryActionsDeliveryToLocationViewService,
    private pageManagerService: PageManagerService,
    private translateService: TranslateService,
    private translateHelperService: TranslateHelperService,
    private enumsService: EnumInfoService
  ) {
    this.deliveryStatusTypeLookup = this.enumsService.getDataSource(
      'InventoryActionDeliveryStatusType'
    );
  }

  getHeaderTitle(): string {
    switch (this.mode) {
      case AccountingTransactionType.Delivery:
        return this.translateService.instant(
          'accounting transaction delivery list long'
        );
      case AccountingTransactionType.Shipping:
        return this.translateService.instant(
          'accounting transaction shipping list long'
        );
    }
  }

  getExportFileName(): string {
    switch (this.mode) {
      case AccountingTransactionType.Delivery:
        return this.translateService.instant(
          'accounting orders delivery export file name'
        );
      case AccountingTransactionType.Shipping:
        return this.translateService.instant(
          'accounting orders shipping export file name'
        );
    }
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.dataGridWrapperHeader!.title = this.getHeaderTitle();
      this.dataGridWrapperHeader!.exportFileName = this.getExportFileName();
    });
    this.statusLookup = this.enumsService.getDataSource(
      'AccountingTransactionStatusType'
    );
    this.dataSourceAccountingTransactions = this.dataViewService.odata({
      fields: [
        'id',
        'status',
        'number',
        'date',
        'customerName',
        'customerAddress',
        'customerVatNumber',
        'customerEmail',
        'customerPhone',
        'fallbackTransaction',
        'type',
        'quantity',
        'quantityCompleted',
        'quantityCompletedPercent',
      ],
    });
  }

  get editFormTabTitle() {
    switch (this.mode) {
      case AccountingTransactionType.Delivery:
        return this.translateService.instant(
          'accounting transaction delivery - edit short'
        );
      case AccountingTransactionType.Shipping:
        return this.translateService.instant(
          'accounting transaction shipping - edit short'
        );
    }
  }

  edit(editActionParameterModel: EditActionParameterModel) {
    const tabTile = this.dataService
      .get(editActionParameterModel.id, this.mode)
      .subscribe((model) => {
        editActionParameterModel.mode = this.mode;

        const documentNumberInfo = model.number ? model.number + ' - ' : '';
        const editFormTabTitle = `${documentNumberInfo}${this.editFormTabTitle}`;

        this.pageManagerService.OpenEditFormPage(
          editFormTabTitle,
          AccountingTransactionEditComponent,
          editActionParameterModel,
          model
        );
      });
  }

  delete(id: string) {
    this.dataService.delete(id).subscribe((p) => {
      this.dataGridWrapperHeader?.dgRefresh();
    });
  }

  gridViewAction(action: EditActionParameterModel) {
    switch (action.actionType) {
      case ActionType.Add:
      case ActionType.Copy:
      case ActionType.Edit:
        this.edit(action);
        break;
      case ActionType.Delete:
        this.delete(action.id);
        break;
      case ActionType.CustomAction:
        alert(action.customActionName);
        break;
    }
  }

  headerProgressFormat(value) {
    console.log('d:', value);
    return `Wow`;
  }

  // odświeża dane po zmianie focusa
  onFocusedRowChanged(accountingTransactionId: number) {
    this.detailsRefresh(accountingTransactionId);
  }

  // odświeża dane po zmianie filtrowania
  onContentReady(e: any) {
    this.detailsRefresh(this.dataGridHeader!.focusedRowKey);
  }

  detailsRefresh = (accountingTransactionId: number) => {
    const refreshTime = this.splitterRefresh
      ? 0
      : environment.masterDetailRefreshTimeInMs;

    let dbFn = debounce(
      () => this.detailsRefreshFunction(accountingTransactionId),
      refreshTime
    );

    dbFn();
  };

  detailsRefreshFunction(accountingTransactionId: number) {
    const document = this.dataGridWrapperHeader?.rowData;

    if (this.dataGridHeader?.instance.totalCount() === 0 || !document) {
      this.dataSourceAccountingTransactionsDetailsAndActions = null;
      this.dataSourceInventoryActionsDeliveryToLocationView = null;
      this.dataSourceAccountingTransactionsStatusHistoryView = null;
    } else {
      this.dataSourceAccountingTransactionsDetailsAndActions =
        this.dataViewService.odataDetails({
          fields: [
            'id',
            'productNumber',
            'productName',
            'quantity',
            'quantityCompleted',
            'quantityCompletedPercent',
          ],
          filter: `accountingTransactionId eq ${accountingTransactionId}`,
        });

      this.dataSourceAccountingTransactionsDetailsAndStorageInfo =
        this.dataViewService.odataDetails({
          fields: [
            'id',
            'productNumber',
            'productName',
            'quantity',
            'waiting',
            'inUser',
            'inLocation',
          ],
          filter: `accountingTransactionId eq ${accountingTransactionId}`,
        });

      this.dataSourceInventoryActionsDeliveryToLocationView =
        this.inventoryActionsDeliveryToLocationViewService.odata({
          fields: [
            'accountId',
            'accountingTransactionId',
            'productId',
            'productName',
            'productNumber',
            'productBarCode',
            'actionTo',
            'quantity',
            'status',
            'locationName',
            'accountUserName',

            'id',
          ],
          filter: `accountingTransactionId eq ${accountingTransactionId}`,
        });

      this.dataSourceAccountingTransactionsStatusHistoryView =
        this.accountingTransactionStatusHistoryViewService.odata({
          fields: [
            'statusFrom',
            'statusTo',
            'date',
            'userName',
            'userIp',
            'id',
          ],
          filter: `accountingTransactionId eq ${accountingTransactionId}`,
        });
    }
  }

  accountingTransactionCustomerNameCalculateCellValue = (rowData: any) => {
    if (rowData.fallbackTransaction && rowData.type === 'Delivery') {
      return this.translateService.instant('fallback delivery');
    }
    if (rowData.fallbackTransaction && rowData.type === 'Shipping') {
      return this.translateService.instant('fallback shipping');
    }
    return rowData.customerName;
  };

  ngAfterViewInit(): void {}

  openInventoryMovementInNewWindow(id: number) {
    const accountingDocument = this.dataGridWrapperHeader?.rowData;
    const accountingDocumentItem = this.dataGridWrapperDetailTaskInfo?.rowData;

    const params = {
      accountTransactionDetailId: id,
      accountingDocument: accountingDocument,
      accountingDocumentItem: accountingDocumentItem,
      viewMode: 'standard',
      showSearchPanel: true,
      showGroupPanel: true,
      showHeader: true,
      showFooter: true,
      saveLayout: false,
    };

    const pageName = `${accountingDocument.number}, ${accountingDocumentItem.productNumber}`;

    this.pageManagerService.OpenPage(
      new PageItem(pageName, InventoryActionListComponent, params),
      true
    );
  }

  openInventoryStorageInfoInNewWindow(id: number) {
    const accountingDocument = this.dataGridWrapperHeader?.rowData;
    const accountingDocumentItem =
      this.dataGridWrapperDetailStorageInfo?.rowData;

    const params = {
      accountTransactionDetailId: id,
      accountingDocument: accountingDocument,
      accountingDocumentItem: accountingDocumentItem,
      viewMode: 'standard',
      showSearchPanel: true,
      showGroupPanel: true,
      showHeader: true,
      showFooter: true,
      saveLayout: false,
    };

    const pageName = `${accountingDocument.number}, ${accountingDocumentItem.productNumber}`;

    this.pageManagerService.OpenPage(
      new PageItem(pageName, InventoryActionStorageInfoListComponent, params),
      true
    );
  }
  calculateCellValueForDestinationActionTo = (row: any) => {
    return this.translateHelperService.getTranslate(row.actionTo);
  };

  splitterRefresh = false;
  onSplitterDragEnd($event) {
    this.splitterRefresh = true;
    this.dataGridWrapperHeader?.dgRefresh();
    this.splitterRefresh = false;
  }
}
