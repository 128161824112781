import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { SettingModel } from '../../models/layout/setting.model';
import { BaseRepository } from 'projects/xlibs/src/bll/services/base-repository';
import { BaseRepositoryOdata } from '../../../../xlibs/src/bll/services/base-repository-odata';

@Injectable({
  providedIn: 'root',
})
export class SettingsService extends BaseRepositoryOdata<SettingModel, number> {
  constructor() {
    super('setting');
  }
}
