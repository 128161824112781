<dx-tab-panel
  #dxTabPanel
  (onSelectionChanged)="tabPanelSelectionChanged()"
  [(selectedIndex)]="selectedIndex"
  [animationEnabled]="false"
  [dataSource]="pageManagerService.pages"
  [deferRendering]="false"
  [focusStateEnabled]="false"
  [repaintChangesOnly]="true"
  [showNavButtons]="true"
  class="dx-tab-panel"
  itemTemplate="item"
  itemTitleTemplate="title"
>
  <div
    *dxTemplate="let content of 'title'"
    class="tab-header"
    id="{{ content.id }}"
    title="{{ content.name }}"
  >
    <span class="title">{{ content.name }}</span>
    <i
      (click)="closeButtonHandler(content)"
      class="dx-icon dx-icon-close close-button"
    ></i>
  </div>

  <div (resized)="onResized($event)" *dxTemplate="let page of 'item'">
    <app-tab-page-wrapper-container
      #appTabPageWrapperContainer
      [id]="page.id"
      [myComponent]="page.component"
      [params]="page.params"
      class="container"
    ></app-tab-page-wrapper-container>
  </div>
</dx-tab-panel>

<dx-context-menu
  (onItemClick)="contextMenuClick($event)"
  [dataSource]="contextMenu"
  [width]="200"
  cssClass="tabs-context-menu"
  target=".tab-header"
>
  <div *dxTemplate="let item of 'item'" style="display: flex; padding: 6px">
    <div class="setting-menu-icon" style="width: 20px; text-align: center">
      <fa-icon [icon]="item.faIcon" size="lg"></fa-icon>
    </div>
    <div
      class="setting-menu-text"
      [ngStyle]="{ 'margin-left': item.text ? '10px' : 0 }"
    >
      <span> {{ item.text }}</span>
    </div>
  </div>
</dx-context-menu>
