/**
 * Bazowa klasa dla repozytoriów danych
 * Biblioteki:
 * https://www.npmjs.com/package/odata-query
 */

import ODataStore from 'devextreme/data/odata/store';
import DataSource from 'devextreme/data/data_source';
import { CONFIG } from 'projects/xlibs/src/config';
import { HttpClient } from '@angular/common/http';
import { LocatorService } from 'projects/xlibs/src/injectors/locator.service';
import { map } from 'rxjs/operators';
import buildQuery from 'odata-query';
import { Observable, of } from 'rxjs';
import {
  ActionType,
  EditActionParameterModel,
} from 'projects/xlibs/src/gui/edit-form-wrapper/edit-action-parameter-model';
import { EntityBaseModel } from 'projects/xlibs/src/bll/models/entity-base.model';

export interface DataSourceParameters {
  fields?: Array<string>;
  endPointName?: string;
  filter?: string;
  key?: string;
  top?: number;
  keyType?: string; // keyType, Accepted Values: 'String' | 'Int32' | 'Int64' | 'Guid' | 'Boolean' | 'Single' | 'Decimal'
}

export abstract class BaseRepositoryOdata<T extends EntityBaseModel, K> {
  protected constructor(
    endpointName: string,
    fields: Array<string> | null = null
  ) {
    this.endpointName = endpointName;
    this.fields = fields;
    this.http = LocatorService.injector.get(HttpClient);
  }

  http: HttpClient;
  endpointName: string;
  fields: Array<string> | null;
  odataInfoRemove = '$format=application/json;odata.metadata=none';

  get apiUrl(): string {
    return CONFIG.API_URL;
  }

  get endpointUrl(): string {
    return `${this.apiUrl}/${this.endpointName}`;
  }

  private methodUrl(methodName: string | null = null) {
    if (methodName) {
      return `${this.apiUrl}/${methodName}`;
    } else {
      return this.endpointUrl;
    }
  }

  /**
   * Zwraca pojedynczy rekord na podstawie tablicy query ()
   */
  getSingleByQuery(queryDef: any, endpointName: string | null = null) {
    queryDef.top = 1;
    const q = buildQuery(queryDef);
    return this.http
      .get(this.methodUrl(endpointName) + q)
      .pipe(map((res: any) => res.value[0]));
  }

  /**
   * Zwraca dane na podstawie tablicy query ()
   */
  getByQuery(queryDef: any, endpointName: string | null = null) {
    const q = buildQuery(queryDef);
    return this.http
      .get(this.methodUrl(endpointName) + q)
      .pipe(map((res: any) => res.value));
  }

  /**
   * Zwraca źródło danych typu odata
   */
  odata(params: DataSourceParameters = {}) {
    if (!params.fields && this.fields) params.fields = this.fields;
    if (!params.endPointName) params.endPointName = this.endpointName;
    if (!params.key) params.key = 'id';
    if (!params.keyType) params.keyType = 'Int64';

    const ds = new DataSource({
      store: new ODataStore({
        url: `${this.apiUrl}/${params.endPointName}`,
        version: 4,
        key: params.key,
        keyType: params.keyType,
        filterToLower: false,
        beforeSend: (e) => {
          this.beforeSend(e);
          if (params.filter) {
            const defaultFilter = params.filter.replace(
              /^\s+|\s+$|\s+(?=\s)/g,
              ''
            );
            if (e.params.$filter) {
              e.params.$filter = `(${e.params.$filter}) and (${defaultFilter})`;
            } else {
              e.params.$filter = defaultFilter;
            }
          }
        },
        onLoaded: this.onLoaded,
        onModified: this.onModified,
        onPush: this.onPush,
        onUpdating: this.onUpdating,
      }),
      select: params.fields ?? undefined,
      requireTotalCount: true,
    });
    return ds;
  }

  /**
   * Pobiera rekord wg identyfikatora
   */
  getById(id: K | null): Observable<T> {
    if (id === null) {
      return of({} as T);
    } else {
      return this.http.get<T>(
        `${this.endpointUrl}/${id}?${this.odataInfoRemove}`
      );
    }
  }

  deleteById(id: string | number) {
    return this.http.delete(`${this.endpointUrl}/${id}`);
  }

  save(editMode: EditActionParameterModel, model: T): Observable<T> {
    this.onSave(model);

    let method = 'post';
    let url = this.endpointUrl;

    switch (editMode.actionType) {
      case ActionType.Add:
      case ActionType.Copy:
        delete model.id;
        break;
      case ActionType.Edit:
        method = 'patch';
        url += `/${model.id}`;
        break;
    }
    return this.http.request<T>(method, url, { body: model });
  }

  //region #REGION getDataSource odata methods

  public beforeSend(e: any) {
    // obsługa nazwy technicznej
    // detekcja ')) or ( to sie pojawi gdy korzystamy z elements-data-grid search panel
    // (po warunkiem, że mamy przynajmniej dwie kolumny)

    if (e.params.$filter && e.params.$filter.includes(`')) or (`)) {
      const value = e.params.$filter.match(
        /\)\) or \(contains\(.*?,'(.*?)'\)\)/
      );
      // wyrażenie poszukiwane powinniśmy mieć na drugim elemencie
      if (value[1]) {
        e.params.$filter = `(contains(_TS, '${value[1]}'))  `;
      }
    }

    // obsługa zapytań bez podanych pól do pobrania
    // jeżeli nie ma podanych pól, pytamy o id, wymuszam w ten sposób podawanie pól - tak wolę.

    if (!e.params.$select) {
      e.params.$select = 'id';
      console.warn(
        `Nie podane pola które mam pobierać. Pobieram więc sam identyfikator.(${e.url})`,
        e
      );
    }
  }

  public onLoaded = (result: Array<any>) => {};

  public onModified = () => {};

  public onPush = (changes: Array<any>) => {};

  private onUpdating = (key: any, values: any) => {};

  public onSave(model: T) {}
}
