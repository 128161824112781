import {
  AfterContentInit,
  AfterViewInit,
  Component,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { AccountModel } from '../../../../../../bll/models/accounts/account.model';
import { InventoryActionsViewService } from '../../../../../../bll/services/inventory-transactions/inventory-actions-view.service';
import { AccountService } from '../../../../../../bll/services/accounts/account.service';
import { AccountingTransactionModel } from '../../../../../../bll/models/accounting-transactions/accounting-transaction.model';
import { AccountingTransactionProductModel } from '../../../../../../bll/models/accounting-transactions/accounting-transaction-product.model';
import { DataGridWrapperComponent } from '../../../../../../../xlibs/src/gui/data-grid-wrapper/data-grid-wrapper.component';
import { TranslateService } from '@ngx-translate/core';
import { TranslateHelperService } from '../../../shared/services/translate-helper.service';

@Component({
  selector: 'app-inventory-action-list',
  templateUrl: './inventory-action-list.component.html',
  styleUrls: ['./inventory-action-list.component.scss'],
})
export class InventoryActionListComponent implements AfterViewInit {
  @Input()
  accountTransactionDetailId: number | undefined;
  @Input()
  accountingDocument: AccountingTransactionModel | undefined;
  @Input()
  accountingDocumentItem: AccountingTransactionProductModel | undefined;
  @Input()
  viewMode: 'standard' | 'raw' = 'standard';

  @Input()
  saveLayout = true;

  @ViewChild('dataGridWrapper')
  dataGridWrapper: DataGridWrapperComponent | null = null;

  account: AccountModel | undefined;

  dataSource: any;

  constructor(
    private inventoryActionsViewService: InventoryActionsViewService,
    private accountService: AccountService,
    private translateService: TranslateService,
    private translateHelperService: TranslateHelperService
  ) {
    this.account = accountService.getConfig();
  }

  ngAfterViewInit(): void {
    this.dataGridWrapper!.viewMode = this.viewMode;

    if (this.viewMode == 'standard') {
      this.dataGridWrapper!.title = `${
        this.accountingDocumentItem?.productNumber
      } ${this.accountingDocumentItem?.productName} (${
        this.accountingDocument!.number
      })`;
    }

    this.dataSource = this.inventoryActionsViewService.odata({
      fields: [
        'id',
        'getToProcessDate',
        'finishedDate',
        'actionFrom',
        'actionTo',
        'productQuantity',
        'productSerialNumber',
        'productExpirationDate',
        'taskId',
      ],
      filter: `deliveryTransactionDetailId eq ${this.accountTransactionDetailId}`,
    });
  }

  customizeTextTranslate = (row: any) => {
    return this.translateHelperService.getTranslate(row.value);
  };
}
