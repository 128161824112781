import {
  AfterContentInit,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ContentChild,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MessageBoxService } from './services/message-box.service';
import {
  MessageBoxMessageType,
  MessageBoxModel,
} from './models/message-box.model';
import { DxButtonComponent, DxPopupComponent } from 'devextreme-angular';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'xlib-message-box',
  templateUrl: './message-box.component.html',
  styleUrls: ['./message-box.component.scss'],
})
export class MessageBoxComponent
  implements OnInit, AfterContentInit, AfterViewInit
{
  @ViewChild('buttonYes') buttonYes!: DxButtonComponent;
  @ViewChild('buttonNo') buttonNo!: DxButtonComponent;
  @ViewChild('buttonOk') buttonOk!: DxButtonComponent;
  @ViewChild('buttonCancel') buttonCancel!: DxButtonComponent;
  @ViewChild('popup') popup!: DxPopupComponent;

  isOpen = false;
  buttonOkVisible = false;
  buttonYesVisible = false;
  buttonNoVisible = false;
  buttonCancelVisible = false;
  title = '';
  text = '';

  message: MessageBoxModel | null = null;

  buttonOkOptions: any;
  buttonYesOptions: any;
  buttonNoOptions: any;
  buttonCancelOptions: any;

  @Input() fullScreen = false;
  @Input() disableAnimation = false;
  @Input() showCloseButton = true;

  constructor(
    private messageBoxService: MessageBoxService,
    private translate: TranslateService
  ) {
    this.configureButtons();
  }

  configureButtons() {
    this.buttonOkOptions = {
      onClick: () => {
        this.close();
      },
    };

    this.buttonYesOptions = {
      onClick: () => {
        this.buttonYesClick();
      },
    };

    this.buttonNoOptions = {
      onClick: () => {
        this.buttonNoClick();
      },
    };

    this.buttonCancelOptions = {
      onClick: () => {
        this.close();
      },
    };
  }

  ngOnInit() {
    this.messageBoxService.display.subscribe((p) => this.Show(p));
  }

  ngAfterViewInit() {
    if (this.disableAnimation) {
      this.popup.animation = {};
    }
  }

  ngAfterContentInit() {}

  private Show(message: MessageBoxModel) {
    if (!this.buttonOk) {
      return;
    }

    this.message = message;
    this.isOpen = message.type !== MessageBoxMessageType.None;
    this.title = message.title;
    this.text = message.text;

    this.buttonOk.visible = false;
    this.buttonYes.visible = false;
    this.buttonNo.visible = false;
    this.buttonCancel.visible = false;

    switch (message.type) {
      case MessageBoxMessageType.None: {
        this.buttonOk.visible = true;
        this.buttonYes.visible = true;
        this.buttonNo.visible = true;
        this.buttonCancel.visible = true;
        break;
      }

      case MessageBoxMessageType.Ok: {
        this.buttonOk.visible = true;
        break;
      }
      case MessageBoxMessageType.YesNo: {
        this.buttonYes.visible = true;
        this.buttonNo.visible = true;
        break;
      }
      case MessageBoxMessageType.YesNoCancel: {
        this.buttonYes.visible = true;
        this.buttonNo.visible = true;
        this.buttonCancel.visible = true;
        break;
      }
    }
  }

  buttonYesClick() {
    this.close();
    setTimeout(() => {
      if (this.message != null && this.message.yesAction) {
        this.message.yesAction();
      }
    });
  }

  buttonNoClick() {
    this.close();
    setTimeout(() => {
      if (this.message != null && this.message.noAction) {
        this.message.noAction();
      }
    });
  }

  buttonOkClick() {
    this.close();
  }

  buttonCancelClick() {
    this.close();
  }

  close() {
    this.isOpen = false;
  }
}
