<input
  hidden
  type="file"
  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
  #uploader
  (change)="uploadData($event, exportedObjectType)"
/>

<div class="import-item">
  <div class="import-title dx-theme-accent-as-text-color">
    {{ title }}
  </div>

  <div class="description" [innerHTML]="description"></div>

  <div class="buttons">
    <dx-button
      stylingMode="contained"
      text="{{ buttonImportFileText }}"
      type="default"
      [width]="250"
      (onClick)="uploader.click()"
    >
    </dx-button>
    <dx-button
      stylingMode="contained"
      text="{{ 'get sample file' | translate }}"
      type="normal"
      [width]="250"
      (onClick)="getSample($event, sampleFileName)"
    >
    </dx-button>
  </div>
</div>
