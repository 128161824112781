import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'xlib-html-text-editor',
  templateUrl: './html-text-editor.component.html',
  styleUrls: ['./html-text-editor.component.css'],
})
export class HtmlTextEditorComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
