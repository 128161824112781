import { Component, Input, OnInit, ViewChild } from '@angular/core';

import { EditActionParameterModel } from 'projects/xlibs/src/gui/edit-form-wrapper/edit-action-parameter-model';
import { TranslateService } from '@ngx-translate/core';
import { EditFormWrapperComponent } from 'projects/xlibs/src/gui/edit-form-wrapper/edit-form-wrapper.component';
import { CustomerModel } from 'projects/ucanlo/bll/models/customers/customer.model';
import { CustomersService } from 'projects/ucanlo/bll/services/customers/customers.service';

@Component({
  selector: 'app-customer-edit',
  templateUrl: './customer-edit.component.html',
  styleUrls: ['./customer-edit.component.scss'],
})
export class CustomerEditComponent implements OnInit {
  @ViewChild('editFormWrapper')
  editFormWrapper: EditFormWrapperComponent | null = null;

  @Input()
  editActionParams: EditActionParameterModel | null = null;
  editObject: CustomerModel | null = null;

  buttonCustomerCopyDataOptions = {
    text: this.translate.instant('copy address data from customer'),
    type: 'standard',
    //stylingMode: 'default',
    width: 250,
    onClick: () => {
      this.repository.CopyDataFromCustomerToShipmentAddress(this.editObject!);
    },
  };

  private repository: CustomersService;

  constructor(
    repository: CustomersService,
    private translate: TranslateService
  ) {
    this.repository = repository;
  }

  ngOnInit(): void {
    this.editObject = this.editActionParams?.model;
  }

  ngAfterViewInit() {
    this.editFormWrapper?.init(this.editObject, this.editActionParams!, () =>
      this.save()
    );
  }

  private save() {
    this.repository
      .save(this.editActionParams!, this.editObject as CustomerModel)
      .subscribe((res) => {
        this.editFormWrapper?.close();
        this.editActionParams?.dataGrid?.dgRefreshAndFocusRowById(res.id);
      });
  }

  public get differentShippingAddress(): boolean {
    return this.editObject?.differentShippingAddress ?? false;
  }
}
