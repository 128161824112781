import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { BaseRepositoryOdata } from '../../../../xlibs/src/bll/services/base-repository-odata';
import { AccountUserModel } from '../../models/accounts/account-user.model';
import { AccountUserViewModel } from '../../models/accounts/account-user-view.model';

@Injectable({
  providedIn: 'root',
})
export class AccountUsersViewService extends BaseRepositoryOdata<
  AccountUserViewModel,
  number
> {
  constructor() {
    super('accountUserView');
  }
}
