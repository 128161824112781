import { Injectable } from '@angular/core';
import { SimpleBaseRepository } from '../../../../xlibs/src/bll/services/simple-base-repository';
import { FallbackDocumentModel } from '../../models/fallback-procedures/falback-document.model';
import { EnumInfoModel } from '../../../../xlibs/src/enums/enum-info.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FallbackDeliveryService extends SimpleBaseRepository {
  constructor() {
    super('FallbackDelivery');
  }

  /**
   * Dodaje nową dostawę awaryjną
   * @param document
   * @constructor
   */
  add(document: FallbackDocumentModel): Observable<any> {
    return this.http.post(`${this.endpointUrl}/add`, document);
  }
}
