import {Component, OnInit} from '@angular/core';
import * as Enumerable from "linq";

@Component({
  selector: 'app-home-page',
  templateUrl: './status-panel.component.html',
  styleUrls: ['./status-panel.component.scss']
})
export class StatusPanel implements OnInit {
  transportTypes = ["dpd 2", "dpd 3", "dpd 4", "paczkomat", "poczta"]
  shippingData = [
    {status: "DeliveryToProcess", quantity: 23, units: 29, transportType: "dpd 2"},
    {status: "DeliveryDelayed", quantity: 1, units: 2, transportType: "dpd 3"},
    {status: "DeliveryFinished", quantity: 50, units: 71, transportType: "dpd 4"},
    {status: "DeliveryToProcess", quantity: 5, units: 6, transportType: "paczkomat"},
    {status: "DeliveryDelayed", quantity: 0, units: 3, transportType: "paczkomat"},
    {status: "DeliveryFinished", quantity: 1229, units: 1423, transportType: "paczkomat"},
    {status: "DeliveryInProgress", quantity: 74, units: 102, transportType: "paczkomat"},
  ]
  aggregatedShippingData = new Array<any>();
  aggregatedShippingDataSummary: any;

  accountingTransactionData = [
    {
      type: "Delivery",
      status: "DeliveryToProcess",
      quantity: 12,
      quantityAdHoc: 1,
      units: 23,
      transportType: null
    },
    {
      type: "Shipping",
      status: "ShipingToProcess",
      quantity: 12,
      quantityAdHoc: 1,
      units: 23,
      transportType: "dpd"
    },
  ];

  constructor() {
  }

  ngOnInit() {
    this.calculateAggregatedShippingData();
  }

  calculateAggregatedShippingData() {
    this.shippingData.forEach(data => {

      let row: any = this.aggregatedShippingData.find((p: any) => p.status === data.status);

      if (!row) {
        row = {
          status: data.status,
          quantity: 0,
          units: 0,
        }
        this.transportTypes.forEach(transport => row[transport + '_quantity'] = 0);
        this.transportTypes.forEach(transport => row[transport + '_units'] = 0);

        this.aggregatedShippingData.push(row);
      }
      row.quantity += data.quantity;
      row.units += data.units;

      row[data.transportType + '_quantity'] = data.quantity;
      row[data.transportType + '_units'] = data.units;
    });

    console.log(this.aggregatedShippingData);
    const data = Enumerable.from(this.aggregatedShippingData);

    this.aggregatedShippingDataSummary = {
      quantity: data.sum(p => p.quantity),
      units: data.sum(p => p.units),
    }

    this.transportTypes.forEach(transport => {

      this.aggregatedShippingDataSummary[transport + '_quantity'] = data.sum(p => p[transport + '_quantity'])
      this.aggregatedShippingDataSummary[transport + '_units'] = data.sum(p => p[transport + '_units'])
    });
    console.log(this.aggregatedShippingDataSummary);
  }

}
