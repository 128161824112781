import { Component, OnInit, ViewChild } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { DataGridWrapperComponent } from 'projects/xlibs/src/gui/data-grid-wrapper/data-grid-wrapper.component';
import { ProductInLocationsService } from 'projects/ucanlo/bll/services/store/product-in-locations.service';
import { Debounce } from 'projects/xlibs/src/helpers/debounce-helper';

@Component({
  selector: 'app-product-in-location-by-location-list',
  templateUrl: './product-in-location-by-location-list.component.html',
  styleUrls: ['./product-in-location-by-location-list.component.scss'],
})
export class ProductInLocationByLocationListComponent implements OnInit {
  dataSource: any;
  dataSourceDetail: any;

  @ViewChild('gridWrapperDetails')
  gridWrapperDetails: DataGridWrapperComponent | null = null;

  constructor(private repository: ProductInLocationsService) {
    this.dataSource = repository.odataProductInLocationByLocationView();
  }

  ngOnInit(): void {}

  onFocusedRowChanged(e: any) {
    this.detailsRefresh(e.row.data.id, e.row.data.name);
  }

  @Debounce(environment.masterDetailRefreshTimeInMs)
  detailsRefresh(locationId: number, locationName: string) {
    this.dataSourceDetail =
      this.repository.odataProductInLocationView(locationId);

    this.dataSourceDetail.this.gridWrapperDetails!.title = locationName;
  }
}
