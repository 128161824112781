import { LayoutTestComponent } from '../../../../../ucanlo/desktop/src/app/pages/_tests/layout-test/layout-test.component';

export enum ActionButtonIconType {
  Start,
  Stop,
  Pause,
  User,
}

export interface ActionButtonLargeModel {
  text: string;
  description?: string;
  icon: ActionButtonIconType;
  actionName?: string;
  selectedRowRequired: boolean;
  disabled?: boolean;

  subActions?: Array<ActionButtonLargeModel>;
}
