import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DataImportResultModel } from '../../../models/data/import/data-import-result.model';
import { HttpClient } from '@angular/common/http';
import {
  DataImportInputDataModel,
  DataImportType,
} from '../../../models/data/import/data-import-input-data.model';
import { BaseRepository } from 'projects/xlibs/src/bll/services/base-repository';

@Injectable({
  providedIn: 'root',
})
export class DataImportRepositoryService extends BaseRepository {
  constructor(private http: HttpClient) {
    super('data');
  }

  import(type: DataImportType, data: any): Observable<DataImportResultModel> {
    const body: DataImportInputDataModel = {
      type,
      data,
    };

    return this.http.post<DataImportResultModel>(
      `${this.endpointUrl}/import`,
      body
    );
  }
}
