import {
    AfterContentInit,
    AfterViewInit,
    Component,
    ContentChild,
    Input,
    OnInit,
} from '@angular/core';
import {
    DxFormComponent,
    DxListComponent
} from 'devextreme-angular';

@Component({
    selector: 'xlib-list-wrapper',
    templateUrl: './list-wrapper.component.html',
    styleUrls: ['./list-wrapper.component.css'],
})
export class ListWrapperComponent
    implements OnInit, AfterContentInit, AfterViewInit {
    constructor() {}

    @Input() height: string | number | Function = '100vh';

    @ContentChild(DxListComponent) list?: DxListComponent;

    ngOnInit(): void {

    }

    ngAfterContentInit(): void {
        this.configure();
    }

    private configure() {
        if (!this.list) {
            return;
        }

        this.list.pageLoadMode = 'scrollBottom';
        this.list.height = '100%';
        this.list.searchEnabled = true;
        this.list.searchTimeout = 300;
        this.list.pageLoadMode = 'scrollBottom';
        this.list.searchExpr = '_TS';
        this.list.searchMode = 'equals';
        this.list.selectionMode = 'single';
    }


    ngAfterViewInit(): void {
        // this.configure();
    }

    get isItemSelected() {
        if (
            !this.list ||
            this.list.items.length === 0 ||
            this.list.selectedItems.length === 0
        ) {
            return false;
        } else {
            return true;
        }
    }
}
