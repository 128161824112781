import { Injectable } from '@angular/core';
import { ProductModel } from 'projects/ucanlo/bll/models/products/product.model';
import { BaseRepositoryOdata } from '../../../../xlibs/src/bll/services/base-repository-odata';

@Injectable({
  providedIn: 'root',
})
export class ProductsService extends BaseRepositoryOdata<ProductModel, number> {
  constructor() {
    super('product');
  }
}
