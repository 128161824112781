import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';

import 'devextreme/data/odata/store';

import { TranslateService } from '@ngx-translate/core';
import {
  ActionType,
  EditActionParameterModel,
} from 'projects/xlibs/src/gui/edit-form-wrapper/edit-action-parameter-model';
import { DxDataGridComponent } from 'devextreme-angular';

import { ProductSelectModel } from 'projects/ucanlo/bll/models/products/product-select.model';

import { ProductModel } from 'projects/ucanlo/bll/models/products/product.model';
import { AccountingTransactionProductModel } from 'projects/ucanlo/bll/models/accounting-transactions/accounting-transaction-product.model';
import { setFocusOnColumnByFieldName } from 'projects/xlibs/src/gui/dev-extreme/dx-data-grid-column-helper';
import { PageManagerService } from 'projects/xlibs/src/gui/page-manager/page-manager.service';
import { ProductsViewService } from '../../../../../../bll/services/products/products-view.service';

@Component({
  selector: 'app-product-list-select',
  templateUrl: './product-list-select.component.html',
  styleUrls: ['./product-list-select.component.scss'],
})
export class ProductListSelectComponent implements OnInit {
  dataSource: any;

  @Output()
  onSelectProduct: EventEmitter<Array<ProductSelectModel> | null> = new EventEmitter();

  @ViewChild('dataGrid') dataGrid: DxDataGridComponent | null = null;

  @Input() withExpirationDate = false;

  changes: Array<any> = [];

  popupVisible = false;

  constructor(
    private productsViewService: ProductsViewService,
    private pageManagerService: PageManagerService,
    private translate: TranslateService
  ) {
    this.dataSource = productsViewService.odata({
      fields: ['id', 'name', 'number', 'barcode'],
    });
  }

  quantityEditorOptions = {
    min: 0,
    showClearButton: true,
    format: '#',
  };

  productSelectedList: Array<ProductSelectModel> =
    new Array<ProductSelectModel>();

  ngOnInit(): void {}

  show(productSelectedList: Array<ProductSelectModel>) {
    this.popupVisible = true;
    this.productSelectedList = productSelectedList;
    this.refreshDataGrid();
  }

  updatingData = false;

  beginUpdate() {
    this.updatingData = true;
  }
  endUpdate() {
    this.updatingData = false;
  }

  refreshDataGrid() {
    this.beginUpdate();

    const gridInstance = this.dataGrid!.instance as any;
    gridInstance.deselectAll();

    //uaktualnienie ilości dla wybranych rekordów
    const selectedProductIds = this.productSelectedList.map((p) => p.id);
    gridInstance.selectRows(selectedProductIds, false);

    gridInstance.repaint();

    this.endUpdate();
  }

  hide() {
    this.dataGrid?.instance.cancelEditData();
    this.popupVisible = false;
  }

  onSelectCancel() {
    this.hide();
    this.onSelectProduct.emit(null);
  }

  onSelectProducts(selected: any) {
    this.hide();
    const result = this.productSelectedList.filter((p) => p.quantity);
    this.onSelectProduct.emit(result);
  }

  gridViewAction(action: EditActionParameterModel) {
    switch (action.actionType) {
      case ActionType.Select:
        this.onSelectProducts(action.selected);
        break;
      case ActionType.Cancel:
        this.onSelectCancel();
        break;
    }
  }

  onRowClick(e: any) {
    setFocusOnColumnByFieldName(this.dataGrid!, e.rowIndex, 'quantity');
  }

  onToolbarPreparing(e: any) {
    const toolbarItems = e.toolbarOptions.items;
    toolbarItems.forEach((p: any) => {
      if (p.name == 'saveButton' || p.name == 'revertButton') {
        p.visible = false;
      }
    });
  }

  onCellPrepared(e: any) {
    if (e.rowType === 'data') {
      if (e.column.dataField === 'expirationDate') {
        if (e.data.hasExpirationDate) {
          e.cellElement.classList.add('cell-with-required-value');
        }
      }
    }
  }

  onEditorPreparing(e) {
    if (e.parentType == 'dataRow') {
      if (e.type == 'selection') {
        const dataRow = e.row.data;
        e.editorOptions.onValueChanged = (param) => {
          e.setValue(param.value);
          this.onSelectionStateChanged(dataRow, param.value);
        };
      }
      if (e.dataField == 'quantity') {
        const rowData = e.row.data;
        e.editorOptions.onValueChanged = (param) => {
          e.setValue(param.value);
          this.onQuantityChange(rowData, param.value);
        };
        //e.editorOptions.valueChangeEvent = 'input';
      }
    }
  }

  onQuantityChange = (data: any, quantity: number) => {
    if (quantity) {
      this.dataGrid!.instance.selectRows([data.id], true);
    } else {
      this.dataGrid!.instance.deselectRows([data.id]);
    }
    this.quantityChange(data, quantity);
  };

  onSelectionStateChanged = (rowData: any, value: boolean) => {
    const rowIndex: number = this.dataGrid!.instance.getRowIndexByKey(
      rowData.id
    );

    if (value) {
      if (!this.dataGrid!.instance.cellValue(rowIndex, 'quantity')) {
        this.quantityChange(rowData, 1);
        this.dataGrid!.instance.cellValue(rowIndex, 'quantity', 1);
      }
    } else {
      this.quantityChange(rowData, null);
      this.dataGrid!.instance.cellValue(rowIndex, 'quantity', null);
    }
  };

  quantityChange(rowData, quantity) {
    if (this.updatingData) {
      return;
    }

    const product = this.productSelectedList.find((p) => p.id == rowData.id);

    if (product) {
      product.quantity = quantity;
    } else {
      this.productSelectedList.push({
        id: rowData.id,
        number: rowData.number,
        name: rowData.name,
        barcode: rowData.barcode,
        quantity: quantity,
      });
    }
  }

  quantityCalculateCellValue = (rowData) => {
    const product = this.productSelectedList.find((p) => p.id == rowData.id);
    if (product) {
      return product.quantity;
    } else {
      return undefined;
    }
  };

  dataGridOnCellClick(e) {
    if (e.rowType == 'header' && e.column.command == 'select') {
      this.productSelectedList = [];
    }
  }
}
