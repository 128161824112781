<app-customer-list-select
  #appCustomerListSelect
  (onSelectCustomer)="onSelectCustomer($event)"
></app-customer-list-select>

<app-product-list-select
  #appProductListSelect
  (onSelectProduct)="onSelectProduct($event)"
></app-product-list-select>

<xlib-edit-form-wrapper
  #editFormWrapperAccountingTransaction
  [title]="title"
  id="popupCustomerGet2"
>
  <dx-form #editForm [formData]="transaction">
    <dxi-item itemType="tabbed">
      <!-- TAB DANE PODSTAWOWE ---------------------------------------------------------------------------------------->
      <dxo-tab-panel-options [deferRendering]="false"></dxo-tab-panel-options>
      <dxi-tab title="{{ 'data' | translate }}">
        <!-- region GROUP Dane podstawowe ------------------------------------------------------------------------------------>
        <dxi-item
          [colCount]="2"
          caption="{{ 'basic info' | translate }}"
          itemType="group"
        >
          <dxi-item
            [label]="{ text: 'accounting transaction number' | translate }"
            dataField="number"
          >
          </dxi-item>
          <dxi-item
            [editorOptions]="{
              displayFormat: 'yyyy-MM-dd'
            }"
            [label]="{ text: 'date' | translate }"
            dataField="date"
            editorType="dxDateBox"
          >
          </dxi-item>
          <dxi-item
            [label]="{ text: 'transport type' | translate }"
            dataField="transportType"
          >
          </dxi-item>
          <dxi-item
            [label]="{ text: 'document type' | translate }"
            dataField="documentType"
          >
          </dxi-item>
          <dxi-item
            [label]="{ text: 'document ad hoc' | translate }"
            dataField="adHoc"
            editorType="dxCheckBox"
          >
          </dxi-item>
        </dxi-item>
        <!-- GROUP Dane kupującego ------------------------------------------------------------------------------------>
        <dxi-item
          [colCount]="2"
          caption="{{ 'purchaser' | translate }}"
          itemType="group"
        >
          <dxi-item
            [buttonOptions]="buttonCustomerGetDataOptions"
            [colSpan]="2"
            itemType="button"
            location="after"
          >
          </dxi-item>

          <dxi-item
            [label]="{ text: 'company name' | translate }"
            dataField="customerCompanyName"
          >
          </dxi-item>
          <dxi-item
            [label]="{ text: 'vat number' | translate }"
            dataField="customerVatNumber"
          >
          </dxi-item>
          <dxi-item
            [label]="{ text: 'first name' | translate }"
            dataField="customerFirstName"
          >
          </dxi-item>
          <dxi-item
            [label]="{ text: 'last name' | translate }"
            dataField="customerLastName"
          >
          </dxi-item>
          <dxi-item
            [label]="{ text: 'address line 1' | translate }"
            dataField="customerAddressLine1"
          >
          </dxi-item>
          <dxi-item
            [label]="{ text: 'address line 2' | translate }"
            dataField="customerAddressLine2"
          >
          </dxi-item>
          <dxi-item
            [label]="{ text: 'postcode' | translate }"
            dataField="customerPostCode"
          >
          </dxi-item>

          <dxi-item
            [label]="{ text: 'city' | translate }"
            dataField="customerCity"
          >
          </dxi-item>
          <dxi-item
            [label]="{ text: 'phone' | translate }"
            dataField="customerPhone"
          ></dxi-item>
          <dxi-item
            [label]="{ text: 'email' | translate }"
            dataField="customerEmail"
          ></dxi-item>

          <dxi-item
            [label]="{ text: 'country' | translate }"
            dataField="customerCountry"
          >
          </dxi-item>
          <dxi-item itemType="empty"></dxi-item>
          <dxi-item
            [label]="{ text: 'different shipping address' | translate }"
            dataField="differentShippingAddress"
            editorType="dxCheckBox"
          >
          </dxi-item>
        </dxi-item>
        <!-- GROUP Dane dostawy --------------------------------------------------------------------------------------->
        <dxi-item
          [colCount]="2"
          [visible]="differentShippingAddress"
          caption="{{ 'shipping address' | translate }}"
          itemType="group"
        >
          <dxi-item
            [buttonOptions]="buttonCustomerCopyDataOptions"
            [colSpan]="2"
            itemType="button"
            location="after"
          >
          </dxi-item>
          <dxi-item
            [colSpan]="2"
            [label]="{ text: 'company name' | translate }"
            dataField="shippingCompanyName"
          >
          </dxi-item>

          <dxi-item
            [label]="{ text: 'first name' | translate }"
            dataField="shippingFirstName"
          >
          </dxi-item>
          <dxi-item
            [label]="{ text: 'last name' | translate }"
            dataField="shippingLastName"
          >
          </dxi-item>
          <dxi-item
            [label]="{ text: 'address line 1' | translate }"
            dataField="shippingAddressLine1"
          >
          </dxi-item>
          <dxi-item
            [label]="{ text: 'address line 2' | translate }"
            dataField="shippingAddressLine2"
          >
          </dxi-item>
          <dxi-item
            [label]="{ text: 'postcode' | translate }"
            dataField="shippingPostCode"
          >
          </dxi-item>
          <dxi-item
            [label]="{ text: 'city' | translate }"
            dataField="shippingCity"
          >
          </dxi-item>

          <dxi-item
            [label]="{ text: 'phone' | translate }"
            dataField="shippingPhone"
          ></dxi-item>
          <dxi-item
            [label]="{ text: 'email' | translate }"
            dataField="shippingEmail"
          ></dxi-item>

          <dxi-item
            [label]="{ text: 'country' | translate }"
            dataField="shippingCountry"
          >
          </dxi-item>
        </dxi-item>
      </dxi-tab>

      <!-- TAB PRODUKTY -------------------------------------------------------------------------------------------------->
      <dxi-tab title="{{ 'products' | translate }}">
        <dxi-item>
          <xlib-data-grid-wrapper
            (buttonsAction)="productListButtonsAction($event)"
            [confirmDelete]="false"
            [showCopyButton]="false"
            [showEditButton]="false"
            [showGroupPanel]="false"
            [showRefreshButton]="false"
            [showSearchPanel]="false"
            [title]="'accounting transaction products' | translate"
          >
            <dx-data-grid
              #dataGridProducts
              (onFocusedCellChanging)="
                dataGridProductsOnFocusedCellChanging($event)
              "
              (onRowClick)="dataGridProductsOnRowClick($event)"
              [dataSource]="products"
              [height]="500"
              [repaintChangesOnly]="true"
              [showBorders]="true"
              keyExpr="productId"
            >
              <dxo-editing
                [allowAdding]="false"
                [allowDeleting]="true"
                [allowUpdating]="true"
                [selectTextOnEditStart]="true"
                [startEditAction]="'click'"
                mode="cell"
              >
                <dxo-texts confirmDeleteMessage=""></dxo-texts>
              </dxo-editing>

              <dxo-keyboard-navigation
                [editOnKeyPress]="true"
                enterKeyAction="startEdit"
                enterKeyDirection="column"
              >
              </dxo-keyboard-navigation>

              <dxi-column [visible]="false" type="buttons"></dxi-column>

              <dxi-column
                [allowEditing]="false"
                caption="{{ 'product number' | translate }}"
                dataField="productNumber"
              ></dxi-column>
              <dxi-column
                [allowEditing]="false"
                caption="{{ 'name' | translate }}"
                dataField="productName"
              ></dxi-column>
              <dxi-column
                [editorOptions]="{
                  min: 0,
                  format: '###0',
                  step: 0
                }"
                [fixed]="true"
                caption="{{ 'quantity' | translate }}"
                cssClass="strong"
                dataField="quantity"
                dataType="number"
                fixedPosition="right"
                headerCellTemplate="quantityHeaderTemplate"
              ></dxi-column>
              <div *dxTemplate="let info of 'quantityHeaderTemplate'">
                {{ info.column.caption }} <i class="dx-icon-edit"></i>
              </div>
            </dx-data-grid>
          </xlib-data-grid-wrapper>
        </dxi-item>
      </dxi-tab>
      <!-- TAB NOTES -------------------------------------------------------------------------------------------------->
      <dxi-tab title="{{ 'notes' | translate }}">
        <dxi-item
          cssClass="dxi-item-notes"
          dataField="notes"
          editorType="dxTextArea"
        >
          <dxo-label [visible]="false"></dxo-label>
        </dxi-item>
      </dxi-tab>
      <!-- NOTES END-->
    </dxi-item>
  </dx-form>
</xlib-edit-form-wrapper>
