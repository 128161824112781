<xlib-data-grid-wrapper
  [showAddButton]="false"
  [showCopyButton]="false"
  [showDeleteButton]="false"
  [showEditButton]="false"
  [title]="'product in location list' | translate"
>
  <dx-data-grid [dataSource]="dataSource">
    <dxi-column
      caption="{{ 'location name' | translate }}"
      dataField="locationName"
    ></dxi-column>
    <dxi-column
      caption="{{ 'product number' | translate }}"
      dataField="productNumber"
    ></dxi-column>
    <dxi-column
      caption="{{ 'product name' | translate }}"
      dataField="productName"
    ></dxi-column>
    <dxi-column
      caption="{{ 'quantity' | translate }}"
      dataField="quantity"
    ></dxi-column>
    <dxi-column
      caption="{{ 'quantity reserved' | translate }}"
      dataField="quantityReserved"
    ></dxi-column>
  </dx-data-grid>
</xlib-data-grid-wrapper>
