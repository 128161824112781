import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class TranslateHelperService {
  constructor(private translateService: TranslateService) {}

  getTranslate(e: string): string {
    const match = e.match(/{(.*?)}/g);

    if (!match) {
      return e;
    }

    match.forEach((m) => {
      const regex = new RegExp(`\\b${m}|\\b`, 'gi');
      const translateKey = m.replace(/[{}]+/g, '');

      e = e.replace(m, this.translateService.instant(translateKey));
    });

    return e;
  }
}
