import { AfterViewInit, Component, Input, ViewChild } from '@angular/core';
import { AccountingTransactionModel } from '../../../../../../bll/models/accounting-transactions/accounting-transaction.model';
import { AccountingTransactionProductModel } from '../../../../../../bll/models/accounting-transactions/accounting-transaction-product.model';
import { DataGridWrapperComponent } from '../../../../../../../xlibs/src/gui/data-grid-wrapper/data-grid-wrapper.component';
import { AccountModel } from '../../../../../../bll/models/accounts/account.model';
import { AccountService } from '../../../../../../bll/services/accounts/account.service';
import { TranslateService } from '@ngx-translate/core';
import { TranslateHelperService } from '../../../shared/services/translate-helper.service';
import { InventoryActionsDeliveryToLocationViewService } from '../../../../../../bll/services/inventory-transactions/inventory-actions-delivery-to-location-view.service';

@Component({
  selector: 'app-inventory-action-storage-info-list',
  templateUrl: './inventory-action-storage-info-list.component.html',
  styleUrls: ['./inventory-action-storage-info-list.component.scss'],
})
export class InventoryActionStorageInfoListComponent implements AfterViewInit {
  @Input()
  accountTransactionDetailId: number | undefined;
  @Input()
  accountingDocument: AccountingTransactionModel | undefined;
  @Input()
  accountingDocumentItem: AccountingTransactionProductModel | undefined;
  @Input()
  viewMode: 'standard' | 'raw' = 'standard';

  @Input()
  saveLayout = false;

  @ViewChild('dataGridWrapper')
  dataGridWrapper: DataGridWrapperComponent | null = null;

  account: AccountModel | undefined;

  dataSource: any;

  constructor(
    private inventoryActionsDeliveryToLocationViewService: InventoryActionsDeliveryToLocationViewService,
    private accountService: AccountService,
    private translateService: TranslateService,
    private translateHelperService: TranslateHelperService
  ) {
    this.account = accountService.getConfig();
  }

  ngAfterViewInit(): void {
    if (this.viewMode == 'standard') {
      this.dataGridWrapper!.title = `${
        this.accountingDocumentItem?.productNumber
      } ${this.accountingDocumentItem?.productName} (${
        this.accountingDocument!.number
      })`;
    }

    this.dataSource = this.inventoryActionsDeliveryToLocationViewService.odata({
      fields: [
        'id',
        'statusInfo',
        'quantity',
        'status',
        'locationName',
        'accountUserName',
      ],
      filter: `accountingTransactionDetailId eq ${this.accountTransactionDetailId}`,
    });
  }

  customizeTextTranslate = (row: any) => {
    return this.translateHelperService.getTranslate(row.value);
  };
}
