import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { DataGridWrapperComponent } from 'projects/xlibs/src/gui/data-grid-wrapper/data-grid-wrapper.component';
import { TranslateService } from '@ngx-translate/core';
import {
  ActionType,
  EditActionParameterModel,
} from 'projects/xlibs/src/gui/edit-form-wrapper/edit-action-parameter-model';
import { CustomerEditComponent } from '../customer-edit/customer-edit.component';
import { CustomerModel } from 'projects/ucanlo/bll/models/customers/customer.model';
import { CustomersService } from 'projects/ucanlo/bll/services/customers/customers.service';
import { PageManagerService } from 'projects/xlibs/src/gui/page-manager/page-manager.service';
import { CustomersViewService } from '../../../../../../bll/services/customers/customers-view.service';

@Component({
  selector: 'app-customer-list',
  templateUrl: './customer-list.component.html',
  styleUrls: ['./customer-list.component.scss'],
})
export class CustomerListComponent implements OnInit, AfterViewInit {
  dataSource: any;

  @Input() selectMode: 'none' | 'single' | 'multiple' = 'none';
  @ViewChild('grid') grid: DataGridWrapperComponent | null = null;

  @Output() selectItemAction: EventEmitter<number | null> = new EventEmitter();

  constructor(
    private repo: CustomersService,
    private repoView: CustomersViewService,

    private pageManagerService: PageManagerService,
    private translate: TranslateService
  ) {
    this.dataSource = repoView.odata({
      fields: [
        'id',
        'number',
        'supplier',
        'purchaser',
        'name',
        'address',
        'city',
        'country',
        'phone',
        'email',
        'vatNumber',
        'notes',
      ],
    });
  }

  ngOnInit(): void {}

  ngAfterViewInit(): void {}

  edit(editActionParameterModel: EditActionParameterModel) {
    this.repo.getById(editActionParameterModel.id).subscribe((model) => {
      this.pageManagerService.OpenEditFormPage(
        this.translate.instant('customer - edit'),
        CustomerEditComponent,
        editActionParameterModel,
        model
      );
    });
  }

  delete(id: string) {
    this.repo.deleteById(id).subscribe((p) => {
      this.grid?.dgRefresh();
    });
  }

  select(customerId: number) {
    this.selectItemAction.emit(customerId);
  }

  cancel() {
    console.log('cancel');
    this.selectItemAction.emit(null);
  }

  gridViewAction(action: EditActionParameterModel) {
    switch (action.actionType) {
      case ActionType.Add:
      case ActionType.Copy:
      case ActionType.Edit:
        this.edit(action);
        break;
      case ActionType.Delete:
        this.delete(action.id);
        break;
      case ActionType.Select:
        this.select(action.selected);
        break;
      case ActionType.Cancel:
        this.cancel();
        break;
    }
  }
}
