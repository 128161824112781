import { EventEmitter, Injectable } from '@angular/core';
import { ScreenService } from '../../../../../../../../xlibs/src/gui/screen-service/screen.service';

@Injectable({
  providedIn: 'root',
})
export class MenuService {
  isMenuOpenLocateStorageKey = 'menu';

  menuClose$ = new EventEmitter();
  menuOpen$ = new EventEmitter();
  menuChangeState$ = new EventEmitter<boolean>();

  constructor(private screenService: ScreenService) {
    this.initMenuState();
  }

  initMenuState() {
    if (!this.screenService.isMobile) {
      this.menuOpen();
    }

    // if (localStorage.getItem(this.isMenuOpenLocateStorageKey) === 'true') {
    //   this.menuOpen();
    // }
  }

  menuClose() {
    this.menuClose$.emit();
    this.menuChangeState$.emit(false);
    this.isMenuOpen = false;
    this.updateMenuState();
  }
  menuOpen() {
    this.menuOpen$.emit();
    this.menuChangeState$.emit(true);
    this.isMenuOpen = true;
    this.updateMenuState();
  }

  menuToggle() {
    if (this.isMenuOpen) {
      this.menuClose();
    } else {
      this.menuOpen();
    }
  }

  isMenuOpen: boolean = false;

  updateMenuState() {
    localStorage.setItem(
      this.isMenuOpenLocateStorageKey,
      this.isMenuOpen.toString()
    );
  }
}
