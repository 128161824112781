<dx-data-grid [dataSource]="dataSource" keyExpr="id" [focusedRowEnabled]="true">
  <dxo-filter-row [visible]="true"></dxo-filter-row>
  <dxi-column caption="{{ 'id' | translate }}" dataField="id"></dxi-column>
  <dxi-column
    caption="{{ 'product number' | translate }}"
    dataField="number"
  ></dxi-column>
  <dxi-column caption="{{ 'name' | translate }}" dataField="name"></dxi-column>
  <dxi-column
    caption="{{ 'barcode' | translate }}"
    dataField="barcode"
  ></dxi-column>
</dx-data-grid>
