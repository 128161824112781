import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';

import 'devextreme/data/odata/store';

import { ProductEditComponent } from '../product-edit/product-edit.component';
import { TranslateService } from '@ngx-translate/core';
import {
  ActionType,
  EditActionParameterModel,
} from 'projects/xlibs/src/gui/edit-form-wrapper/edit-action-parameter-model';
import applyChanges from 'devextreme/data/apply_changes';
import { DataGridWrapperComponent } from 'projects/xlibs/src/gui/data-grid-wrapper/data-grid-wrapper.component';
import { ProductModel } from 'projects/ucanlo/bll/models/products/product.model';
import { ProductsService } from 'projects/ucanlo/bll/services/products/products.service';
import { PageManagerService } from 'projects/xlibs/src/gui/page-manager/page-manager.service';
import { ProductsViewService } from '../../../../../../bll/services/products/products-view.service';

@Component({
  selector: 'app-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.scss'],
})
export class ProductListComponent implements OnInit {
  dataSource: any;

  // Tryb ten pozwala na wprowadzanie ilości produktów, wyświetla dodatkową kolumnę z ilością produktów zamawianych
  @Input() shoppingCartMode = false;

  @Output() selectItemAction: EventEmitter<Array<ProductModel> | null> =
    new EventEmitter();

  @ViewChild('grid') grid: DataGridWrapperComponent | null = null;

  changes: Array<any> = [];

  constructor(
    private repo: ProductsService,
    private repoView: ProductsViewService,
    private pageManagerService: PageManagerService,
    private translate: TranslateService
  ) {
    this.dataSource = repoView.odata({
      fields: ['id', 'number', 'name', 'barCode', 'status', 'notes'],
    });
  }

  ngOnInit(): void {}

  edit(editActionParameterModel: EditActionParameterModel) {
    this.repo.getById(editActionParameterModel.id).subscribe((model) => {
      this.pageManagerService.OpenEditFormPage(
        this.translate.instant('product - edit'),
        ProductEditComponent,
        editActionParameterModel,
        model
      );
    });
  }

  delete(id: string) {
    console.log('delete');
    this.repo.deleteById(id).subscribe((p) => {
      console.log('delete2');
      this.grid?.dgRefresh();
    });
  }

  select(selected: any) {
    this.selectItemAction.emit(selected);
  }

  cancel() {
    console.log('cancel');
    this.selectItemAction.emit(null);
  }

  gridViewAction(action: EditActionParameterModel) {
    switch (action.actionType) {
      case ActionType.Add:
      case ActionType.Copy:
      case ActionType.Edit:
        this.edit(action);
        break;
      case ActionType.Delete:
        console.log('delete0');
        this.delete(action.id);
        break;
      case ActionType.Select:
        this.select(action.selected);
        break;
      case ActionType.Cancel:
        this.cancel();
        break;
    }
  }

  onSaving(e: any) {
    console.log('onSaving:', e);
    console.log('dataSource:', this.dataSource);
    applyChanges(this.dataSource._items, this.changes, {
      keyExpr: 'id',
      immutable: false,
    });

    // this.editRowKey = null;
    // this.editColumnName = null;
    this.changes = [];
    e.cancel = true;
  }

  onRowUpdating(e: any) {
    console.log('onRowUpdating:', e);
    e.cancel = false;
  }
}
