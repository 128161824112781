import { Component, OnInit } from '@angular/core';
import { ProductsViewService } from '../../../../../../bll/services/products/products-view.service';

@Component({
  selector: 'app-dx-data-grid-test',
  templateUrl: './dx-data-grid-test.component.html',
  styleUrls: ['./dx-data-grid-test.component.scss'],
})
export class DxDataGridTestComponent implements OnInit {
  constructor(private repoView: ProductsViewService) {
    this.dataSource = {
      store: {
        type: 'odata',
        url: 'https://localhost:44309/a0/productView',
        key: 'id',
        version: 4,
      },
      select: ['name', 'barcode', 'number', 'id'],
    };
  }

  dataSource: any;

  ngOnInit(): void {}
}
