<dx-popup
        class="xlib-message-box"
        #popup
        [width]="500"
        [height]="250"
        [showTitle]="true"
        title="{{ title }}"
        [dragEnabled]="true"
        [closeOnOutsideClick]="true"
        [showCloseButton]="showCloseButton"
        container="body"
        [(visible)]="isOpen"
        [deferRendering]="false"
        [shading]="false"
        [fullScreen]="fullScreen"
>
    <div *dxTemplate="let data of 'content'">
        <div class="xlib-message-box-container">
            <div class="content"
                 [innerHTML]="text"></div>
            <div class="message-box-buttons">
                <dx-button
                        class="button yes"
                        #buttonYes
                        (onClick)="buttonYesClick()"
                        text="{{ 'yes' | translate }}"
                        [visible]="false"
                ></dx-button>
                <dx-button
                    #buttonNo
                    class="button no"
                    (onClick)="buttonNoClick()"
                    text="{{ 'no' | translate }}"
                    [visible]="false"
                ></dx-button>
                <dx-button
                    #buttonOk
                    class="button ok"
                    (onClick)="buttonOkClick()"
                    text="{{ 'ok' | translate }}"
                    [visible]="false"
                ></dx-button>
                <dx-button
                    #buttonCancel
                    class="button cancel"
                    (onClick)="buttonCancelClick()"
                    text="{{ 'cancel' | translate }}"
                    [visible]="false"
                ></dx-button>
            </div>
        </div>
    </div>
</dx-popup>
