<dx-popup
  #popupImport
  [(visible)]="isPopupDataImportVisible"
  [title]="'import data' | translate"
>
  <div class="container">
    <div class="import-buttons">
      <div class="import-box">
        <div class="button">
          <dx-button
            text="{{ 'import from file' | translate }}"
            width="200"
            icon="save"
          ></dx-button>
        </div>
        <div class="description">
          {{ importDataButtonFromFileDescriptionTranslateKey | translate }}
          <div class="button-help">
            <dx-button
              icon="xlsxfile"
              text="{{ 'example file' | translate }}"
              stylingMode="text"
              type="default"
            ></dx-button>
          </div>
        </div>
      </div>
      <div class="import-box">
        <div class="button">
          <dx-button
            text="{{ 'import from clipboard' | translate }}"
            width="200"
            icon="copy"
            (click)="importDataFromClipboard()"
          ></dx-button>
        </div>
        <div class="description">
          {{ importDataButtonFromClipBoardDescriptionTranslateKey | translate }}
          <div class="button-help">
            <dx-button
              icon="video"
              text="{{ 'video help' | translate }}"
              stylingMode="text"
              type="default"
            ></dx-button>
          </div>
        </div>
      </div>
    </div>

    <div class="clipboard-text-edit">
      <dx-text-area
        #textarea
        [(value)]="clipboardText"
        valueChangeEvent="keyup"
        height="100%"
        placeholder="{{ 'paste data from clipboard into field' | translate }}"
      >
      </dx-text-area>
    </div>

    <div class="grid-data">
      <dx-data-grid
        id="gridImport"
        [dataSource]="dataSource"
        keyExpr="id"
        [showBorders]="true"
        gro
      >
        <dxo-search-panel [visible]="false"></dxo-search-panel>
        <dxo-group-panel [visible]="false"></dxo-group-panel>
        <dxo-column-chooser [enabled]="false"></dxo-column-chooser>
      </dx-data-grid>
    </div>

    <div class="action-buttons">
      <dx-button
        class="button"
        text="{{ 'import data' | translate }}"
        width="200"
        [disabled]="importButtonDisabled"
      ></dx-button>
      <dx-button
        class="button"
        text="{{ 'cancel' | translate }}"
        width="200"
        (onClick)="hide()"
      ></dx-button>
    </div>
  </div>
</dx-popup>
