<div style="display: flex; height: calc(100% - 0px); flex-direction: column">
  <div style="flex-grow: 4; height: 0; margin-bottom: 15px">
    <xlib-data-grid-wrapper
      [showAddButton]="false"
      [showCopyButton]="false"
      [showDeleteButton]="false"
      [showEditButton]="false"
      [title]="'product in location list by product' | translate"
      storageKeySufix="product-in-location-by-product"
    >
      <dx-data-grid
        (onFocusedRowChanged)="onFocusedRowChanged($event)"
        [dataSource]="dataSource"
      >
        <dxi-column
          caption="{{ 'product number' | translate }}"
          dataField="number"
        ></dxi-column>
        <dxi-column
          caption="{{ 'product name' | translate }}"
          dataField="name"
        ></dxi-column>
        <dxi-column
          caption="{{ 'product barcode' | translate }}"
          dataField="barcode"
        ></dxi-column>
        <dxi-column
          caption="{{ 'quantity' | translate }}"
          dataField="quantity"
        ></dxi-column>
        <dxi-column
          caption="{{ 'quantity reserved' | translate }}"
          dataField="quantityReserved"
        ></dxi-column>
      </dx-data-grid>
    </xlib-data-grid-wrapper>
  </div>

  <div style="flex-grow: 6; height: 0">
    <xlib-data-grid-wrapper
      #gridWrapperDetails
      [showAddButton]="false"
      [showCopyButton]="false"
      [showDeleteButton]="false"
      [showEditButton]="false"
      [title]="'locations' | translate"
      storageKeySufix="product-in-location-by-product"
    >
      <dx-data-grid
        [dataSource]="dataSourceDetail"
        noDataText="{{
          'select product from the list to see the locations' | translate
        }}"
      >
        <dxo-load-panel [enabled]="false"></dxo-load-panel>
        <dxi-column
          caption="{{ 'location name' | translate }}"
          dataField="locationName"
        ></dxi-column>
        <dxi-column
          caption="{{ 'location barcode' | translate }}"
          dataField="locationBarcode"
        ></dxi-column>
        <dxi-column
          caption="{{ 'quantity' | translate }}"
          dataField="quantity"
        ></dxi-column>
        <dxi-column
          caption="{{ 'quantity reserved' | translate }}"
          dataField="quantityReserved"
        ></dxi-column>
      </dx-data-grid>
    </xlib-data-grid-wrapper>
  </div>
</div>
