import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { EnumInfoService } from '../../../../../../xlibs/src/enums/enum-info.service';

@Pipe({
  name: 'enumTranslate',
})
export class EnumTranslatePipe implements PipeTransform {
  constructor(private enumInfoService: EnumInfoService) {}

  transform(value: string, enumType: string): string {
    return this.enumInfoService.getTranslatedValue(enumType, value);
  }
}
